<template>
  <div>
    <div class="after-sales-btns" v-if="showBtns">
      <template v-for="(item,index) in btnsArr">
        <div class="btn-item" :key="index" @click.stop="handleClick(item)" v-if="orderInfo[item.key]">{{item.title}}</div>
      </template>
      <!-- <div class="btn-right-icon" @click="handleClick('more')">
        <svg-icon svg-name="greater-right" width="10px" height="22px"></svg-icon>
        <svg-icon svg-name="greater-right" width="10px" height="22px"></svg-icon>
      </div> -->
    </div>
    <!-- <a-spin :spinning="spinLoading" size="large" class="track-spin">
      <div class="btn-mask-spin" v-if="spinLoading"></div>
    </a-spin> -->
  </div>
  
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
// import { debounce2 } from '@/utils/helper'
import moment from 'moment'
export default {
  name: 'AfterSalesBtn',
  props: {
    orderInfo:{
      type:Object,
      default(){
        return {}
      }
    }
  },
  data () {
    return {
      btnsArr:[
        {
          key:'cancelable',
          title:'Cancel',
          action:'cancel'
        },
        {
          key:'returnable',
          title:'Return',
          action:'return'
        },
        // {
        //   key:'reportissue',
        //   title:'Report issue'
        // }
      ],
      spinLoading:false
    }
  },
  computed:{
    ...mapGetters({
      getVerifyEmail: 'order/getVerifyEmail',
      getShopsetting: 'setting/getShopsetting'
    }),
    chatState () {
      return Boolean(Number(this.getShopsetting.enableLiveChat))
    },
    showBtns(){
      let flag = false
      flag = this.btnsArr.some(item => this.orderInfo[item.key])
      return flag
    }
  },
  methods: {
    ...mapMutations({
      setPage:'setPage',
      setCurrOrderAction:'order/setCurrOrderAction',
      setSpinLoading:'common/setSpinLoading',
      setAutomationChat: 'automation/setAutomationChat'
    }),
    handleClick(item){
      this.setAutomationChat({
        userName: 'Ai-Bot',
        content:{
          orderNumber:this.orderInfo.orderNumber,
          financialStatus:this.orderInfo.financialStatus,
          img_url:this.orderInfo.img_url,
          totalNumber:this.orderInfo.totalNumber,
          totalAmount:this.orderInfo.totalAmount
        },
        type: 'Customer',
        messageType: 'order',
        profile: ''
      })
      this.setPage('chat')
      // this.spinLoading = true
      // 调用操作接口
        // 1.有效可操作售后流程 --> 跳转chat页面发送order消息 --> 触发售后flow --> 等待消息交互
        // 2.无效不能操作售后流程 --> 保存订单消息，保存操作 --> 跳转邮箱验证页面置灰验证邮箱进行验证 --> 验证成功后跳转chat页面发送order消息 --> 触发售后flow --> 等待消息交互
        
    },
    // 跳转至聊天页面
    saveOrderInfo () {
      // if (this.chatState) {
        this.$store.commit('setIsFromOrder', true)
        const info = this.orderInfo
        console.log('info:', info)
        this.$store.commit('setOrderInfo', info)
        // this.setPage('login')
      // } else if (this.leaveMessageState) {
      //   this.setPage('leave-message')
      // }
    },
  },
  mounted(){
  }
}
</script>

<style scoped lang="less">
.after-sales-btns{
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  .btn-item{
    color: #4C4B63;
    font-size: 12px;
    margin-right: 12px;
    line-height: 20px;
    cursor: pointer;
    font-weight: 500;
    padding: 2px 8px;
    border-radius: 6px;
    background-color: #E5E5EB;
  }
  .btn-right-icon{
    cursor: pointer;
  }
}
.btn-mask-spin{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.02);
}
</style>

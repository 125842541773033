<template>
  <simplebar class="scroll-container" data-simplebar-auto-hide="true" data-simplebar-direction="ltl" :style="containerStyle">
    <slot />
    <go-top ref="go_top" v-if="goTop" @goTop="goTopFunc" :isShow="isGoTopShow"></go-top>
  </simplebar>
</template>

<script>
// 导入simplebar滚动条
import simplebar from 'simplebar-vue'
import 'simplebar/dist/simplebar.min.css'
import GoTop from './GoTop.vue'

export default {
  name: 'ScrollContainer',
  props: {
    // 方便更改盒子宽高
    styleText: String,
    // 滚动到指定高度后触发钩子
    scrollHook: {
      type: Number,
      default: null
    },
    // 返回顶部的组件, 传离顶距离
    goTop: {
      type: Number,
      default: null
    },
    // 监听是否在顶部
    topHook: {
      type: Boolean,
      default: false
    }
  },
  components: {
    simplebar,
    GoTop
  },
  data () {
    return {
      // 当前是否小于指定滚动高度
      lessThanHookHeight: true,
      // 是否显示返回顶部按钮
      isGoTopShow: false,
      // 是否在顶部
      isTop: true
    }
  },
  mounted () {
    // 只在传了scrollHookd的情况下才建立监听
    if (this.scrollHook !== null || this.goTop !== null || this.topHook) {
      this.$el.querySelector('.simplebar-content-wrapper').addEventListener('scroll', this.scrollCallback)
    }
  },
  beforeDestroy () {
    this.$el.querySelector('.simplebar-content-wrapper').removeEventListener('scroll', this.scrollCallback)
  },
  methods: {
    // 监听滚动的回调
    scrollCallback (eve) {
      const e = eve || window.event
      // 滚动到指定高度后触发钩子
      if (this.scrollHook && ((e.target.scrollTop >= this.scrollHook && this.lessThanHookHeight) || (e.target.scrollTop < this.scrollHook && !this.lessThanHookHeight))) {
        this.lessThanHookHeight = !this.lessThanHookHeight
        this.$emit('scrollHook', this.lessThanHookHeight)
      }

      // 返回顶部按钮显示的高度
      if (this.goTop && e.target.scrollTop >= this.goTop) {
        this.$refs.go_top.isFirstLoad = false
        this.isGoTopShow = true
      } else {
        this.isGoTopShow = false
      }

      if (this.topHook) {
        if ((e.target.scrollTop !== 0 && this.isTop) || (e.target.scrollTop === 0 && !this.isTop)) {
          this.isTop = !this.isTop
          this.$emit('topHook', this.isTop)
        }
      }
    },

    // 返回顶部的方法
    goTopFunc () {
      this.$el.querySelector('.simplebar-content-wrapper').scrollTo({ top: 0, behavior: 'smooth' })
    }
  },
  computed: {
    containerStyle () {
      return this.styleText ? this.styleText : ''
    }
  }
}
</script>

<style>
  .scroll-container {
    width: 100%;
    height: calc(100vh - 74px);
    position: fixed;
    top: 74px;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .simplebar-scrollbar::before {
    background: #B9B9B9;
  }

  /* 修复部分浏览器下聚焦异常外边框 */
  .simplebar-content-wrapper:focus {
    outline: none;
  }
</style>

<template>
  <div class="logo-bar" :style="styleBar">
    <div class="logo-bar-icon">
      <a href="https://apps.shopify.com/self-faq?from=willdesk_app&locale=zh-CN&utm_campaign=logo" target="_blank" :style="{...appsFont}">
        <span :style="styleSpan">Powered by</span>
        <svg-icon :svg-name="iconName" width="66.52px" height="10px" :style="styleLogo"></svg-icon>
      </a>
    </div>
    <!-- <div class="logo-bar-title">
      WILLDESK
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LogoBar',
  props: {
    styleBar: String,
    styleSpan: String,
    styleLogo: String,
    iconName: {
      type: String,
      default: 'logobar'
    }
  },
  computed: {
    ...mapGetters({
      appsFont: 'appsFont'
    })
  }
}
</script>

<style>
  .logo-bar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    box-shadow: 0 -1px 0 0 #dadada;
    height: 32px;
  }

  .logo-bar-icon {
    cursor: pointer;
  }

  .logo-bar-icon a {
    display: flex;
    align-items: center;
    color: #6C6B80;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-decoration: none;
  }

  .logo-bar-icon a span {
    margin-right: 8px;
  }

  .logo-bar-icon a span em {
    font-style: normal;
    color: #2C23E5;
  }

  .logo-bar-title {
    font-size: 12px;
    font-weight: 500;
    color: #d0d0d0;
    line-height: 36px;
    margin-left: 6px;
  }
</style>

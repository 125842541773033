<template>
  <div :class="$store.state.isMobile?'search_mobile':''">
    <!-- nav_bar -->
    <nav-bar class="nav_bar" :class="isTop?'':'not_top'" back-page="home">
      <template #left>
        <svg width="8" height="15.33" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path class="theme-fill-color" fill-rule="evenodd" clip-rule="evenodd" d="M7.59054 0.55242C8.061 0.897429 8.13728 1.52672 7.76091 1.95798L2.48792 7.99994L7.76091 14.0419C8.13728 14.4732 8.061 15.1025 7.59054 15.4475C7.12007 15.7925 6.43357 15.7226 6.0572 15.2913L0.239025 8.62464C-0.0797106 8.25942 -0.0797106 7.74047 0.239025 7.37525L6.0572 0.708593C6.43357 0.277332 7.12007 0.207411 7.59054 0.55242Z" fill="#2C23E5"/>
        </svg>
      </template>
      <template #center>
        <div class="nav_title_container">
          <div class="nav_title" v-if="!$store.state.isMobile">{{textSetting.help_center_title}}</div>
          <search-input class="search_input" v-else @search="search"></search-input>
        </div>
      </template>
    </nav-bar>
    <!-- search_page -->
    <scroll-container class="search_page" :goTop="goTop" topHook @topHook="(flag)=>{ this.isTop = flag }">
      <search-input class="search_input" @search="search" v-if="!$store.state.isMobile"></search-input>
      <component class="component" :is="currentType" :class="index===0?'firstChild':''" v-for="(item,index) in currentType==='help-center-list'?CategoryList:1" :key="index" :category="currentType==='help-center-list'?item.Category[0]:''" :searchList="searchList" :counts="Counts" :searchContent="searchContent"></component>
    </scroll-container>
  </div>
</template>

<script>
import NavBar from 'components/contents/NavBar'
import ScrollContainer from 'components/contents/ScrollContainer'
import SearchInput from './components/SearchInput'

import HelpCenterList from './components/HelpCenterList'

export default {
  name: 'SearchPage',
  components: { NavBar, SearchInput, ScrollContainer, HelpCenterList },
  data () {
    return {
      CategoryList: [{
        Category: [{
          CategoryName: 'Order & Shipping',
          CategorySort: '3',
          Article: [{
            ArticleId: '14457',
            ArticleTitle: 'Do you ship internationally?',
            ArticleSort: '3',
            ArticleContent: '<p>We sure do! We can ship products to most countries around the world.</p><p>Please be aware that different countries have unique customs rules and regulations. Unfortunately, if your package gets held at customs you will be responsible for communication and any additional costs required to release your item(s).</p>'
          }]
        }]
      }],
      // 搜索到的条数
      Counts: 0,
      // 搜索到的结果
      searchList: [],
      // 当前显示的组件  help-center-list / search-result
      currentType: 'help-center-list',
      // 搜索的内容
      searchContent: '',
      // 是否在顶部
      isTop: true,
      debounceResizeFunc: null,
      // 显示返回顶部组件的距离
      goTop: null,
      title: 'help Center'
      // this.$store.state.shopSelfText.helpcenter // 'Help center'
    }
  },
  created () {
    // this.getHelpCenter()
    this.debounceResizeFunc = this.debounce(this.resizeCallback)
    window.addEventListener('resize', this.debounceResizeFunc)
    this.goTop = window.innerHeight * 2
  },
  computed: {
    textSetting () {
      return JSON.parse(this.$store.state.shopSetting.liveChat['0'])['text-settings']
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.debounceResizeFunc)
  },
  methods: {
    // 请求
    // 将获取的数据进行排序处理
    compare (key) {
      return function (a, b) {
        const value1 = a.Category[0][key]
        const value2 = b.Category[0][key]
        return value2 - value1
      }
    },
    compare1 (key) {
      return function (a, b) {
        const value1 = a[key]
        const value2 = b[key]
        return value2 - value1
      }
    },
    // 事件
    // 搜索的回调
    search (content) {
      if (content === '') {
        this.currentType = 'help-center-list'
      } else {
        this.searchList = []
        this.Counts = -1
        this.searchContent = content
        this.searchFaq(content)
        this.currentType = 'search-result'
      }
    },

    resizeCallback () {
      this.$bus.$emit('searchPageResize')
    },

    // 防抖
    debounce (callback, delay = 500) {
      let timer
      return function () {
        clearTimeout(timer)
        timer = setTimeout(() => {
          callback()
        }, delay)
      }
    }
  }
}
</script>

<style scoped>
  .nav_bar {
    background: #fff !important;
    color: #000;
  }

  .nav_bar /deep/ .nav-bar-right {
    width: unset;
  }

  .nav_bar {
    height: 74px;
    line-height: 74px;
    transition: 0.3s box-shadow ease;
  }

  .search_page {
    padding-top: 16px;
    text-align: left;
  }

  .search_input {
    margin: 0 26px;
  }

  .nav_title_container {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    height: 100%;
  }

  .nav_title {
    height: 100%;
    color: #000;
  }

  .search_page {
    height: calc(100vh - 74px);
    top: 74px;
  }

  .search_mobile .search_input {
    width: calc(100% - 42px);
    margin: 0;
  }

  .search_mobile .search_page {
    height: calc(100vh - 60px);
    top: 60px;
    padding: 0;
  }

  .search_mobile .nav_bar /deep/ .nav-bar-right {
    width: unset;
  }

  .not_top {
    box-shadow: 0px 2px 6px -2px rgba(0, 0, 0, 0.28);
  }

  .search_mobile {
    /* font-family: PingFangSC-Regular, PingFang SC; */
  }

  .search_mobile .firstChild /deep/ .category_title {
    margin-top: 21px;
  }
</style>

<template>
  <div class="customer-service-typing" :style="styleText" v-if="state">
    <div class="customer-avatar">
      <img src="~assets/img/avatar2.png" />
    </div>
    <div class="customer-service-content">
      <p class="customer-service-name">zuoxi</p>
      <div class="customer-service-message" v-html="message"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomerServiceTyping',
  data () {
    return {
      // 销毁状态 false销毁 / true存在 state可以考虑由外部传入
      state: true,
      message: 'Typing&nbsp;&nbsp;&nbsp;',
      // 销毁时间
      endTime: 2000,
      // 动画时间
      doTime: 500
    }
  },
  computed: {
    styleText () {
      const right = Number(this.$store.state.boxPadding.replace('px', '')) * 2
      return 'margin:26px ' + right + 'px 26px ' + this.$store.state.boxPadding + ';'
    }
  },
  mounted () {
    // 从挂载开始计算 定时器让后面的点动起来
    const timer = setInterval(
      function () {
        const words = this.message.substr(0, 6)
        let symbol = this.message.substr(6)
        const index = symbol.lastIndexOf('&nbsp;')
        // 如果发现空格并且空格没有替换完
        symbol = index !== -1 && index !== 3 ? symbol.replace('&nbsp;', '.') : '&nbsp;&nbsp;&nbsp;'

        this.message = words + symbol
      }.bind(this), this.doTime)

    // 从挂载开始计算 定时器销毁
    const destory = setTimeout(
      function () {
        clearInterval(timer)
        this.state = false
        clearTimeout(destory)
      }.bind(this),
      this.endTime
    )
  }
}
</script>

<style scoped>
.customer-service-typing {
  display: flex;
  flex-direction: row;
}
.customer-avatar {
  text-align: left;
  width: 30px;
  height: 30px;
}
.customer-service-content {
  margin-left: 6px;
  max-width: calc(100% - 6px - 30px);
}
.customer-service-name {
  font-size: 14px;
  font-weight: 400;
  color: #b4b4b4;
  line-height: 14px;
  text-align: left;
}
.customer-service-message {
  max-width: 100%;
  margin-top: 6px;
  border-radius: 0 12px 12px 12px;
  padding: 16px;
  text-align: left;
  /* 打断单词 */
  /* word-break: break-all; */
  line-height: 22px;
  font-size: 16px;
  font-weight: 400;
  color: #b4b4b4;
}
</style>

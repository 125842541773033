<template>
  <div class="avatar">
    <div class="img-avatar">
      <!-- 默认url ~assets/img/avatar2.png -->
      <img :src="createAvatarImg" />
    </div>
    <!-- 弃用 旧版本 css文字头像
    <div class="div-avatar" v-if="url.length === 0" :style="{background: this.faceColor}">
      {{userNameAbbreviation.name}}
    </div> -->
  </div>
</template>

<script>
import { textToImg } from '@/utils/helper'
// 头像组件
export default {
  name: 'Avatar',
  props: {
    // 头像类型 默认为false 表示自动生成 true表示图片类型
    userInfo: {
      type: Object
    }
  },
  created () {
    // console.log(this.faceColor)
    // console.log(this.userInfo)
  },
  computed: {
    // 根据名称缩写生成对应头像
    createAvatarImg () {
      // 如果存在头像 则渲染 否则根据名称缩写生成对应头像
      return typeof this.userInfo.profile !== 'undefined' && this.userInfo.profile.length === 0 ? textToImg(this.userInfo.userName) : this.userInfo.profile
    }
  }
}
</script>

<style scoped>
   .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: transparent;
  }

  .img-avatar,
  .img-avatar img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .div-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #fff;
    line-height: 30px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
</style>

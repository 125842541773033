<template>
  <div class="work-welcome" v-if="show" :style="styleText">
    <!-- 欢迎语文本 -->
    <p class="work-welcome-text" v-if="workWelcomeText.length > 0">
      <!-- Welcome to Live chat.we will usually reply you here or via email. -->
      <!-- {{workWelcomeText}} -->
      {{workWelcomeText}}
    </p>
    <p class="work-welcome-text" v-if="workContentText.length > 0">
      <!-- Our usual reply time -->
      <!-- {{workContentText}} -->
      {{workContentText}}
    </p>
    <p class="work-response-time" v-if="workReplyTime.length > 0">
      <svg-icon svg-name="clock" width="16px" height="16px"></svg-icon>
      &nbsp;{{workReplyTime}}
    </p>
  </div>
</template>

<script>
// 上班时间欢迎语
export default {
  name: 'WorkWelcome',
  props: {
    // 展示时间
    /* showTime: {
          type: Number,
          default: 20000
        } */
  },
  data () {
    return {
      show: true
    }
  },
  computed: {
    textSetting () {
      return JSON.parse(this.$store.state.shopSetting.liveChat['0'])['text-settings']
    },
    styleText () {
      return this.$store.state.boxPadding === '16px' ? 'margin: 26px 16px 26px 16px;' : ''
    },
    workWelcomeText () {
      return JSON.parse(this.textSetting.chat_welcome_msg) && JSON.parse(this.textSetting.chat_welcome_msg).one
    },
    workContentText () {
      return JSON.parse(this.textSetting.chat_welcome_msg) && JSON.parse(this.textSetting.chat_welcome_msg).two
    },
    workReplyTime () {
      return JSON.parse(this.textSetting.chat_welcome_msg) && JSON.parse(this.textSetting.chat_welcome_msg).three
    }
  },
  mounted () {
    // 定时器销毁当前组件
    /* let timer = setTimeout(() => {
          this.show = false;
          clearTimeout(timer);
          },this.showTime); */
  }
}
</script>

<style>
  .work-welcome {
    background: #f5f5f5;
    border-radius: 12px;
    padding: 16px;
    margin: 26px;
  }

  .work-welcome-text {
    font-size: 14px;
    font-weight: 400;
    color: #353535;
    margin-bottom: 12px;
    text-align: left;
    line-height: 20px;
    word-break: break-word;
  }

  .work-response-time {
    font-weight: 550;
    color: #292929;
    font-size: 14px;
    text-align: left;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>

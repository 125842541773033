import moment from 'moment-timezone'
// 时区转换及计算上班时间函数
const weekMinutes = 7 * 24 * 60
const dayMinutes = 24 * 60
const hourMinutes = 60

const days = {
  Monday: 0,
  Tuesday: 1,
  Wednesday: 2,
  Thursday: 3,
  Friday: 4,
  Saturday: 5,
  Sunday: 6
}

// let arr = [
//   { Timestart:'16:00', Timeend:'16:20', Timerange:'Thursday' },
//   { Timestart:'9:00', Timeend:'18:30', Timerange:'Every day' },
//   { Timestart:'21:00', Timeend:'23:30', Timerange:'Weekdays' },
//   { Timestart:'00:00',Timeend:'06:00',Timerange:'Every day' },
// ]

// getWorkTime(arr, 8)

export default function getWorkTime (arr, shopTimezone, targetbusinessTimeZone) {
  let timeArr = createTimeArr(arr)
  timeArr = getMinutes(timeArr)
  timeArr = transformTimezone(timeArr, shopTimezone, '', targetbusinessTimeZone)
  timeArr = mergeTime(timeArr)
  return getCloseWorkTime(timeArr)
}

// 生成时间数组
function createTimeArr (arr) {
  const timeArr = []

  arr.forEach(item => {
    if (item.Timerange === 'Every day') {
      for (let i = 0; i < 7; i++) {
        const obj = {
          Timestart: item.Timestart,
          Timeend: item.Timeend,
          Timerange: i
        }
        timeArr.push(obj)
      }
    } else if (item.Timerange === 'Weekdays') {
      for (let i = 0; i < 5; i++) {
        const obj = {
          Timestart: item.Timestart,
          Timeend: item.Timeend,
          Timerange: i
        }
        timeArr.push(obj)
      }
    } else if (item.Timerange === 'Weekends') {
      for (let i = 5; i < 7; i++) {
        const obj = {
          Timestart: item.Timestart,
          Timeend: item.Timeend,
          Timerange: i
        }
        timeArr.push(obj)
      }
    } else {
      const obj = {
        Timestart: item.Timestart,
        Timeend: item.Timeend,
        Timerange: days[item.Timerange]
      }
      timeArr.push(obj)
    }
  })

  return timeArr
}

// 将时间转换为分钟
function getMinutes (timeArr) {
  function transformTime (time, Timerange) {
    const arr = time.split(':')
    return (Timerange * dayMinutes) + (arr[0] * hourMinutes) + (arr[1] * 1)
  }

  const arr = []
  timeArr.forEach((item) => {
    const obj = {}
    obj.Timestart = transformTime(item.Timestart, item.Timerange)
    obj.Timeend = transformTime(item.Timeend, item.Timerange)
    arr.push(obj)
  })

  return arr
}
function calculateTimezoneDifference (shopTimezone) {
  // 获取当前本地时间
  const localTimezone = moment.tz.guess()
  const localTime = moment.tz(localTimezone)
  const localIsDST = localTime.isDST()

  // 获取目标时区的时间
  const targetTimeZone = shopTimezone;
  const targetTime = moment.tz(targetTimeZone)
  const targetIsDST = targetTime.isDST()

  // 计算时间差（以小时为单位）
  const timeDifference = targetTime.utcOffset() / 60 - localTime.utcOffset() / 60;

  console.log(`本地时区${localTimezone}(夏令时:${localIsDST})与${targetTimeZone}(夏令时:${targetIsDST})时区的时间差是 ${timeDifference} 小时`, shopTimezone);

  return timeDifference
}
// 将时间转为顾客的时区
function transformTimezone (timeArr, shopTimezone, cusTimezone, targetbusinessTimeZone) {
  cusTimezone = cusTimezone || -new Date().getTimezoneOffset() / hourMinutes

  // 两个时区的时间差（分钟）
  let timeSub = 0
  try {
    timeSub = calculateTimezoneDifference(targetbusinessTimeZone) * hourMinutes
  } catch (e) {
    timeSub = (shopTimezone - cusTimezone) * hourMinutes
  }
  // 转换时间
  function transformTime (time) {
    let temp = time - timeSub
    if (temp > weekMinutes) {
      temp = temp - weekMinutes
    } else if (temp < 0) {
      temp = temp + weekMinutes
    }
    return temp
  }

  const arr = []
  timeArr.forEach((item) => {
    const Timestart = transformTime(item.Timestart)
    const Timeend = transformTime(item.Timeend)
    // 如果开始时间大于结束时间，就说明两个时间不在一个星期，拆分成两个时间段
    if (Timestart > Timeend) {
      arr.push({
        Timestart,
        Timeend: weekMinutes
      })
      arr.push({
        Timestart: 0,
        Timeend
      })
    } else {
      arr.push({
        Timestart,
        Timeend
      })
    }
  })

  return arr
}

// 合并时间算法
function mergeTime (timeArr) {
  for (let i = 0; i < timeArr.length; i++) {
    for (let j = 0; j < timeArr.length; j++) {
      if (i === timeArr.length) break
      let isMerge = false

      if (timeArr[i].Timestart > timeArr[j].Timeend || timeArr[i].Timeend < timeArr[j].Timestart || i === j) continue

      if (timeArr[i].Timestart === timeArr[j].Timestart && timeArr[i].Timeend === timeArr[j].Timeend) {
        timeArr.splice(j, 1)
        j -= 1
      }

      if (timeArr[i].Timestart > timeArr[j].Timestart) {
        timeArr[i].Timestart = timeArr[j].Timestart
        isMerge = true
      }

      if (timeArr[i].Timeend < timeArr[j].Timeend) {
        timeArr[i].Timeend = timeArr[j].Timeend
        isMerge = true
      }

      if (isMerge) {
        timeArr.splice(j, 1)
        j = 0
      }
    }
  }

  return timeArr
}

// 获取当前时间并转换为分钟
function getCurrentMinutes () {
  // 0为周日
  const date = new Date()
  const day = date.getDay() === 0 ? 7 : date.getDay()
  const hour = date.getHours()
  const minus = date.getMinutes() + 1

  return ((day - 1) * dayMinutes) + (hour * hourMinutes) + minus
}

// 判断是否是上班时间，若不是，则获取最短上班时间
function getCloseWorkTime (timeArr) {
  const currentTime = getCurrentMinutes()

  // 最短上班时间
  let leastTime = null

  for (let i = 0; i < timeArr.length; i++) {
    // 判断是否处在上班时间 上班时间返回0
    if (currentTime >= timeArr[i].Timestart && currentTime <= timeArr[i].Timeend) return 0

    // 获取最短上班时间
    let temp = timeArr[i].Timestart - currentTime

    temp = temp < 0 ? weekMinutes + temp : temp

    if (!leastTime || leastTime > temp) leastTime = temp
  }

  return leastTime
}

<template>
  <div class="welcome-header">
    <div class="welcome-title-wrapper">
      <h3 class="welcome-title theme-text-color" :class="[helloImg?'overText':'']">
        {{ welcomeTitle }}
      </h3>
      <p class="welcome-text theme-text-color" :class="[helloImg?'overText':'']" style="margin-bottom: 0px">{{ welcomeText }}</p>
    </div>
    <img :src="helloImg" class="website-logo" v-if="helloImg"/>
  </div>
</template>

<script>
export default {
  name: 'WelcomeHeader',
  props: {
    // 标题
    welcomeTitle: String,
    // 文本
    welcomeText: String,
    // logo
    helloImg: String
  }
}
</script>

<style>
.welcome-header {
    padding: 0 16px;
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
  }
  .welcome-title-wrapper{
    flex: 1;
  }
  .welcome-title {
    text-align: left;
    margin-bottom: 4px;
    line-height: 38px;
    font-size: 30px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 320px;
    word-break: break-word;
  }
  .overText {
    max-width: 235px;
  }
  .welcone-title-span{
    -webkit-box-orient: vertical;
    /* 设置行数为3行*/
    flex:1;
    text-align:left;
    -webkit-line-clamp: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .website-logo{
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
  }

  .welcome-text {
    position: relative;
    text-align: left;
    margin-top: 4px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.72);
    word-break: break-word;
    width: 320px;
  }
</style>

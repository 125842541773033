<template>
  <scroll-box class="textarea-container" data-simplebar-auto-hide="false" ref="scrollBox" @click.native="focusFunc">
    <!-- 文本框 -->
    <div :contenteditable="true" @keydown="textareaKeydown" @keyup="textareaKeyup" @input="inputing" @paste="pasteContent" @click.stop="getLastRange" @focus="focusTextarea" @blur="blurTextarea" :data-placeholder="dataPlaceholder" :class="[placeholderShow ? 'show-placeholder' : '']" class="input-text-area"></div>
    <!-- <call-box ref="callbox" :name.sync="callName" :closingBox="closingCallBox" @selectCurrentService="insertCallTag" :position="cursorPosition"></call-box> -->
  </scroll-box>
</template>

<script>
import ScrollBox from '@/components/contents/ScrollBox'
// import { readAndUploadFile } from '@/utils/uploadFile'
import { getImgAlt, transformTag, getEmojiByAlt } from '@/utils/helper'
// import CallBox from './CallBox.vue'
// 用于记录光标事件
let selection = getSelection()
// 光标最后的位置
let lastEditRange = null
// DOM元素 (DOM需要在mounted中获取赋值)
let textarea = null
// timer定时器, 用于打开输入框后textarea框也跟着撑大
// let timer

export default {
  name: 'DivTextarea',
  components: { ScrollBox },
  props: {
    // 输入内容
    inputContent: {
      type: String,
      default: ''
    },
    // 输入框的打开状态
    inputState: {
      type: Boolean,
      default: false
    },
    // 行高
    lineHeight: {
      type: Number,
      default: 20
    },
    // 最大高度
    maxHeight: {
      type: Number,
      default: 80
    },
    // 最小高度
    minHeight: {
      type: Number,
      default: 40
    },
    // 文本框展开的延迟时间  ms (和本项目业务相关)
    /* textareaDelay: {
          type: Number,
          default: 300
        }, */
    // 顾客信息
    userInfo: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      // 是否显示placeholder
      placeholderShow: true
      // @的名称
      // callName: 'false',
      // 当前光标的位置
      // cursorPosition: { x: 0, y: 0 }
      // closingCallBox: false
    }
  },
  computed: {
    textSetting () {
      return JSON.parse(this.$store.state.shopSetting.liveChat['0'])['text-settings']
    },
    dataPlaceholder () {
      return this.textSetting.chatPlaceholder || 'Message us'
    }
  },
  mounted () {
    // 用于记录光标事件
    selection = getSelection()
    // 光标最后的位置
    lastEditRange = null
    textarea = document.querySelector('.input-text-area')
  },
  methods: {
    // 外部调用的api
    // 插入表情的方法
    insertEmoji (index) {
      if (!lastEditRange) {
        textarea.focus()
      }
      // 还原光标位置
      this.reInsertRange(lastEditRange)

      // 创建img标签并放入表情
      const emojiTag = document.createElement('img')
      emojiTag.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUAAACnej3aAAAAAXRSTlMAQObYZgAAAApJREFUCNdjYAAAAAIAAeIhvDMAAAAASUVORK5CYII='
      emojiTag.alt = `[&${index}]`
      emojiTag.style = `background:url(${require('@/assets/emoji/emoji.png')}) 0 0 no-repeat; width:22px; height:22px; background-position:0 ${-22 * index}px; margin:-1px 1px 0px; vertical-align: middle;`

      this.insertFunc(emojiTag)
    },

    // 聚焦输入框的方法
    focusFunc () {
      textarea.focus()
      if (lastEditRange) {
        // 如果有上一次光标的位置，还原光标位置
        this.reInsertRange(lastEditRange)
      } else {
        // 如果没有上一次光标的位置，则记录当前光标的位置
        this.getLastRange()
      }
    },

    // 清空内容的方法
    clearTextarea (getLastRange = true) {
      textarea.innerHTML = ''
      this.completeInput(getLastRange)
    },

    // 事件监听钩子
    // 输入框高度发生变化执行的回调
    textareaHeightChange (entries) {
      // 拿到监听的高度变化的对象
      const target = entries[0].target

      // 通知父组件修改样式 (与本组件无关)
      this.$emit('setTextareaStyle', target)
    },

    // 监听输入事件
    inputing (eve) {
      const e = eve || window.event

      // 如果输入内容只剩 \n了  则直接清空
      if (e.currentTarget.innerHTML === '\n') {
        e.currentTarget.innerHTML = ''
      }

      // 输入完成执行的回调
      this.completeInput()
    },

    // 监听按键操作
    textareaKeydown (eve) {
      const e = eve || window.event
      // ctrl + enter
      if (
        (e.ctrlKey && e.keyCode === 13) ||
          (e.shiftKey && e.ctrlKey && e.keyCode === 13)
      ) {
        e.preventDefault() // 阻止浏览器默认换行操作
        // 换行的回调
        this.changeLine()
      } else if (e.keyCode === 13) {
        // enter
        e.preventDefault() // 阻止浏览器默认换行操作
        // FIXME 根据pc和移动进行处理 与B端不同之处
        if (!this.$store.isMobile) {
          // 执行发送消息逻辑
          this.$emit('sendMessage')
        } else {
          this.changeLine()
        }

        /* if (this.callName === 'false') {
              this.$emit('pressEnter')
            } */
      }
    },

    // 监听输入框keyup
    textareaKeyup (eve) {
      const e = eve || window.event
      const kc = e.keyCode
      // 判断方向键 并记录光标位置
      if (kc >= 37 && kc <= 40) {
        // 记录鼠标位置
        this.getLastRange()
      }
    },

    // 聚焦输入框后需要执行的操作
    focusTextarea () {
      // 如果输入框已经打开 直接return
      if (this.inputState) return

      // 修改父组件的inputState状态 (与本组件无关)
      this.$emit('update:inputState', true)

      // 父元素min-height变化会导致 子组件的click事件丢失
      // 通过将click的回调放入宏任务解决
      setTimeout(() => {
        if (!lastEditRange) {
          this.getLastRange()
        }
      })
    },

    // 粘贴事件的回调
    pasteContent (e) {
      // 阻止默认事件
      e.preventDefault()
      // 获取粘贴的文件
      // const file = e.clipboardData.files && e.clipboardData.files[0]
      // file && readAndUploadFile(file, this.userInfo)
      // 去粘贴文本的样式
      this.textFormat(e)
    },

    // 输入完成后的回调
    completeInput (getLastRange = true) {
      // 将数据保存至inputContent中
      // 这样传输具有一定延迟，所以下面的输入内容长度获取用textarea.innerHTML 而不是this.inputContent
      // 在将文本框的内容传给父组件前先将图片标签转为指定格式
      this.$emit('update:inputContent', getImgAlt(textarea.innerHTML))

      // 判断是否显示输入框的 placeholder
      this.getPlaceholderShowState()

      if (getLastRange) {
        // 记录光标的位置
        this.getLastRange()
      }

      this.keeptail(textarea)
      this.removeBr()
    },

    // 换行的回调
    changeLine () {
      // 内容为空时禁止换行
      if (textarea.innerHTML.length === 0) return

      // 保持尾部有一个 \n 以能够正常换行
      this.keeptail(textarea)
      // 插入换行符并重新定位光标位置
      const node = document.createTextNode('\n')
      this.insertFunc(node)

      this.completeInput()

      // 判断输入框高度，如果大于160px，每次换行就自动滚动20px
      if (textarea.scrollHeight > this.maxHeight) {
        // 回车时不会自动滚动输入框 这里需要调用scrollTextarea滚动输入框
        const scrollBox = this.$refs.scrollBox

        scrollBox.scrollToFunc('ScrollBox', 'auto', this.lineHeight)
      }
    },

    // 输入框失焦的回调
    blurTextarea () {
      // 正在关闭callbox的flag改为true
      // this.closingCallBox = true
      // 直接关闭callbox会导致点击里面的item后 里面的消息无法在关闭前传出，所以给个300ms延迟，并加个渐进动画，看起来更加流畅
      /* const timer = setTimeout(() => {
            this.$refs.callbox.closeBoxCallback()
            this.closingCallBox = false
            this.callName = 'false'
            clearTimeout(timer)
          }, 300) */
    },

    // 方法
    // 判断是否显示输入框中的placeholder
    getPlaceholderShowState () {
      // 如果内容不为空且当前展示状态为true 内容为空且当前展示状态为false
      this.placeholderShow = textarea.innerHTML.length === 0
    },

    // 重新插入光标
    reInsertRange (range) {
      if (!lastEditRange) return
      // 光标开始和光标结束重叠
      range.collapse(false)
      // 清除选定对象的所有光标对象
      selection.removeAllRanges()
      // 插入新的光标对象
      selection.addRange(range)
    },

    // 记录光标的位置
    getLastRange (range) {
      if (range) range.collapse && range.collapse(false)
      lastEditRange = selection.getRangeAt(0)

      // 获取callName
      // this.callName = this.getCallName(lastEditRange) + ''
      // 获取当前@框的位置
      /* this.$nextTick(() => {
            if (this.callName !== false && lastEditRange.getClientRects()[0]) {
              const postion = {
                x: lastEditRange.getClientRects()[0].x,
                y: lastEditRange.getClientRects()[0].y
              }

              this.cursorPosition = postion
            }
          }) */
    },

    // 保持输入框以 \n结尾
    keeptail (dom) {
      if (dom.innerHTML === '') return
      if (!/\n$/.test(dom.innerHTML)) {
        if (dom.lastChild.nodeName === '#text') {
          dom.lastChild.appendData('\n')
        } else {
          const node = document.createTextNode('\n')
          dom.appendChild(node)
        }
      }
    },

    // 如果存在br就去除br 只会在末尾产生 br, 所以在删除br后 再将光标移至末尾
    removeBr () {
      if (/<br>/g.test(textarea.innerHTML)) {
        textarea.innerHTML = textarea.innerHTML.replace(/<br>/g, '\n')
        var range = window.getSelection() // 创建range
        range.selectAllChildren(textarea) // range 选择obj下所有子内容
        range.collapseToEnd() // 光标移至最后
      }
    },

    // 去除内容样式
    textFormat (e) {
      var text
      var clp = (e.originalEvent || e).clipboardData
      if (clp === undefined || clp === null) {
        text = window.clipboardData.getData('text') || ''
        if (text !== '') {
          if (window.getSelection) {
            var newNode = document.createElement('span')
            newNode.innerHTML = text.slice
            window.getSelection().getRangeAt(0).insertNode(newNode)
          } else {
            document.selection.createRange().pasteHTML(text)
          }
        }
      } else {
        text = clp.getData('text/plain') || ''
        // 将标签的< >转为&lt &gt, 防止用户自己插入标签后发送出去
        text = transformTag(text)
        // 识别并插入表情
        text = getEmojiByAlt(text)

        if (text !== '') {
          document.execCommand('insertHTML', false, text)
        }
      }
    },

    // 插入至节点方法
    insertFunc (node) {
      const range = lastEditRange
      // 删除range里面的内容
      range.deleteContents()
      // 插入节点
      range.insertNode(node)
      // 插入光标
      this.reInsertRange(lastEditRange)
      // 完成输入后的回调
      this.completeInput()
    }

    // 判断当前光标所在的节点是否存在@, 如果存在则返回@的内容
    /* getCallName (range) {
          // 如果不是文本节点则直接返回
          if (!range.startContainer.data || !range.startContainer.nodeName === '#text' || range.commonAncestorContainer.parentNode.nodeName === 'I') return false

          const index = range.startContainer.data.lastIndexOf('@')
          if (index === -1) return false

          // 这个是光标和@之间的字符串
          const content = range.startContainer.data.substring(index + 1, range.startOffset)

          // 如果光标和@之间存在空格，或者长度过长，则要返回false关闭@框
          if (content.indexOf(' ') !== -1 || content.length > 15) return false

          return content
        }, */

    /* // 插入@的标签
        insertCallTag (serviceInfo) {
          // 创建i标签
          const i = document.createElement('i')
          i.innerText = `@${serviceInfo.name}`
          i.contentEditable = false
          i.style = 'padding:2px 8px; margin:2px 4px; font-style: normal; background-color:#ffd16b; color:#292929; display:inline-block; border-radius:8px;'
          i.setAttribute('data-cid', serviceInfo.id)

          const range = lastEditRange
          const index = range.startContainer.data.lastIndexOf('@')
          range.setStart(range.startContainer, index)

          this.insertFunc(i)
        } */
  }
  /* watch: {
        // 监听inputState 并展开文本框
        inputState (current) {
          if (current) {
            // 在0.3s后展开文本框
            timer = setTimeout(() => {
              textarea.style.minHeight = this.minHeight + 'px'
            }, this.textareaDelay)
          } else {
            clearTimeout(timer)
            textarea.style = ''
          }
        }
      } */
}
</script>

<style scoped>
  .textarea-container {
    width: 100%;
    /* max-height: 102px; */
    height: 56px;
    text-align: left;
  }

  /* /deep/ .simplebar-mask {
    overflow: unset !important;
  } */

  .input-text-area {
    min-height: 20px;
    padding: 0;
    margin: 17px 0 17px 26px;
    resize: none;
    border: none;
    font-weight: 400;
    color: #292929;
    line-height: 20px;
    word-break: break-all;
    white-space: pre-wrap;
    -webkit-user-select: text;
    font-size: 14px;
    /* overflow: hidden; */
  }

  .show-placeholder::before {
    /* content: "Message us"; */
    content:attr(data-placeholder);
    color: #b4b4b4;
    font-weight: 400;
    cursor: text;
    font-size: 14px;
  }

  .input-text-area:focus {
    outline: none;
  }
</style>

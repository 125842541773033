<template>
  <div id="app" :style="{ ...appsFont }">
    <span v-html="styleText"></span>
    <component :is="$store.state.currentPage" v-if="$store.state.siteState" :worktype="worktype"></component>
    <loading v-if="loading" />
    <toast v-if="toastState" />
  </div>
</template>

<script>
import Home from '@/views/home/Home'
import LeaveMessage from '@/views/leaveMessage/LeaveMessage'
import Login from '@/views/login/Login'
import Chat from '@/views/chat/Chat'
import Loading from 'components/common/Loading/Loading'
import Toast from 'components/common/Toast/Toast'
import OrderSearch from '@/views/trackOrder/OrderSearch'
import OrderList from '@/views/trackOrder/OrderList'
import OrderDetails from '@/views/trackOrder/OrderDetails'
import OrderEmailLogin from '@/views/trackOrder/OrderEmailLogin'
import SearchPage from '@/views/helpCenter/SearchPage'
import FaqPages from '@/views/helpCenter/FaqPages'
import { isMobile, rgbToHex, getDarkColor, getcolorRgba } from '@/utils/helper'
import getWorkTime from '@/utils/timezone'
// 翻译
import { postTranslateText,clientFixedTranslate,clientCustomTransalte } from '@/api/translate'
import english from '@/assets/language/English'
import { mergeObjArr, arrToObj } from '@/utils/translate'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'App',
  data: function () {
    return {
      translated: '',
      // page: 'home'
      // state: true
      // 全局loading
      loading: true,
      // 全局toast
      toastState: false,
      // 全局颜色样式
      styleText: '',
      worktype: '',
      lanArr: ['English', 'French', 'German', 'Italian', 'Spanish', 'Chinese (Traditional)', 'Chinese (Simplified)', 'Afrikaans', 'Albanian', 'Arabic', 'Armenian', 'Azerbaijani', 'Belarusian', 'Bengali', 'Bosnian', 'Bulgarian', 'Catalan', 'Croatian', 'Czech', 'Danish', 'Dutch', 'Estonian', 'Finnish', 'Georgian', 'Greek', 'Hebrew', 'Hindi', 'Hungarian', 'Icelandic', 'Indonesian', 'Korean', 'Latvian', 'Lithuanian', 'Malay', 'Mongolian', 'Norwegian', 'Persian', 'Polish', 'Romanian', 'Serbian', 'Swedish', 'Slovak', 'Thai', 'Turkish', 'Ukrainian', 'Vietnamese', 'Japanese', 'Portuguese', 'Russian', 'Slovenian']
    }
  },
  created () {
    window.addEventListener('message', this.handleMessage, false)
    this.$store.commit('setPage', 'home')
    // 根据是否为移动端调整左右边距
    this.$store.commit('setPadding', isMobile() ? '16px' : '16px')
  },
  beforeDestroy () {
    window.removeEventListener('message', this.handleMessage, false)
  },
  computed: {
    ...mapGetters({
      appsFont: 'appsFont',
      automationStatus: 'automation/automationStatus'
    })
  },
  methods: {
    ...mapMutations({
      set_automationRules: 'automation/SET_AUTOMATION_RULES',
      setAutomationChat: 'automation/setAutomationChat',
      setThemeColor: 'setThemeColor',
      setAutomationStatus: 'automation/setAutomationStatus',
      setCurrLang:'translate/setCurrLang',
      setCurrLangFixedTranslate:'translate/setCurrLangFixedTranslate',
      setCurrLangCustomTranslate:'translate/setCurrLangCustomTranslate',
    }),
    ...mapActions({
      setAutomationActionDemo: 'automation/setAutomationActionDemo',
      setAutomationAction: 'automation/setAutomationAction'
    }),
    openLoading () {
      this.loading = true
    },
    closeLoading () {
      this.loading = false
    },
    openToast () {
      this.toastState = true
    },
    closeToast () {
      this.toastState = false
    },
    settingDataHandler (res) {
      if (!res.data.Appearance) return
      if (res.data.type === 'webpackClose') {
        return
      }
      this.loading = false
      this.$store.commit('setShopSetting', res.data)
      const color = [res.data.Appearance[0].PrimaryColor, res.data.Appearance[0].SecondaryColor]
      // 文本颜色
      const textColor = res.data.Appearance[0].TextColor.toLowerCase() === 'light' ? '#fff' : '#000'
      let temp
      // 转换颜色
      if (color[0].indexOf('rgb') !== -1) {
        temp = color[0].split(',')
        color[0] = rgbToHex(temp[0].replace(/[^0-9]+/ig, ''), temp[1].replace(/[^0-9]+/ig, ''), temp[2].replace(/[^0-9]+/ig, ''))
      }
      // #000 #333 #f33
      if (color[0].length === 4) {
        temp = color[0].replace('#', '').split('')
        color[0] = '#' + temp[0] + temp[0] + temp[1] + temp[1] + temp[2] + temp[2]
      }
      // console.log(temp)
      color[0] = color[0].toUpperCase()

      this.$store.commit('setTheme', color)

      const hoverColor = getDarkColor(color[0], 0.06)
      // const bgColor = color.length > 1 ? 'linear-gradient(180deg, ' + color[0] + ', ' + color[1] + ')' : color[0]
      let bgColor = ''
      if (color[0] && !color[1]) {
        bgColor = `linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.16) 100%), ${color[0]}`
      } else if (color[0] && color[1]) {
        bgColor = 'linear-gradient(180deg, ' + color[0] + ', ' + color[1] + ')'
      } else {
        bgColor = color[0]
      }
      let primaryColor = res.data.Appearance[0].PrimaryColor
      this.setThemeColor(primaryColor)
      // 假装插入后端返回样式 主题设置
      this.styleText = '<style>.theme-bg-color{background:' + bgColor + ' !important}' +
          '.theme-border-colors{border-color:' + color[0] + '}' +
          '.theme-text-colors{color:' + color[0] + '}' +
          // 纯色背景
          '.theme-solid-bg-color{background:' + color[0] + ' !important}' +
          '.theme-solid-btn-color{background:' + color[0] + ' !important}' +
          // hover态
          '.theme-solid-btn-color:hover{background:' + hoverColor + ' !important}' +
          '.theme-hollow-btn-color:hover{border: 1px solid ' + color[0] + ' !important;color:' + color[0] + ' !important;}' +
          '.theme-solid-text-color{color:' + color[0] + ' !important;}' +
          // link
          '.theme-link-text-color{color:' + color[0] + ' !important;}' +
          '.theme-link-text-color:hover{color:' + hoverColor + ' !important;}' +
          '.theme-text-color{color:' + textColor + ' !important}' +
          // svg填充色,给svg中的内容添加生效，给svg添加不生效
          '.theme-fill-color{fill: ' + color[0] + ' !important;}' +
          // 输入框hover 暂时考虑登录
          '.theme-input-color:hover, .theme-input-color:focus{border: 1px solid ' + color[0] + ' !important;box-shadow:0px 0px 0px 2px ' + this.colorTransform(color[0], 0.2) + ' !important;}' +
          // 点赞按钮 hover态 主色
          '.theme-comment-yes-color:hover{color:' + color[0] + ' !important;}' +
          // Home页faqhover
          ':root{--backgroundHover:' + getcolorRgba(color[0], '.09') + ';--action-color:' + res.data.pagesetting.actionColor + ';--action-bg-color:' + res.data.pagesetting.actionBgcolor + ';--category-icon-color:' + res.data.pagesetting.cateIconcolor + ';--primary-color:' + primaryColor + '}' +
          // 边框颜色
          '.theme-border-color{box-shadow: 0px 4px 10px ' + this.colorTransform(color[0], 0.1) + ', inset 0px 2px 0px ' + this.colorTransform(color[0], 0.6) + ' !important;}</style>'
      // const style = document.createElement('style')
      // style.innerHTML = styleText
      // document.head.appendChild(style)

      // 计算工作时间
      let Timezone = res.data.BusinessHours[0].TimeZone
      Timezone = Timezone.replace(/[^\d:+-]/ig, '').split(':')

      Timezone = Number(Timezone[0]) + (Number(Timezone[1]) ? (Number(Timezone[1]) / 60) : 0)
      // 组织代码格式
      const timeArr = []
      const TimeStart = res.data.BusinessHours[0].TimeStart.split(',')
      const TimeRange = res.data.BusinessHours[0].TimeRange.split(',')
      const TimeEnd = res.data.BusinessHours[0].TimeEnd.split(',')
      const len = TimeRange.length
      for (let i = 0; i < len; i++) {
        const obj = {}
        obj.Timestart = TimeStart[i]
        obj.Timerange = TimeRange[i]
        obj.Timeend = TimeEnd[i]
        timeArr.push(obj)
      }
      const newTimearr = this.removeDuplicateObj(timeArr)
      // 计算
      const timeRange = res.data.BusinessHours[0].TimeRange
      if (timeRange.length > 0) {
        const targetbusinessTimeZone = res.data.BusinessHours[0].TimeZone.split(' ')[1]
        this.$store.dispatch('setWorkTime', getWorkTime(newTimearr, Timezone, targetbusinessTimeZone))
      } else {
        this.$store.dispatch('setWorkTime', 0)
      }
    },
    handleLanTo (VALUE) {
      if(VALUE.data.lanTo == 'English'){
        let arr = []
        arr = mergeObjArr(english)
        const go = JSON.parse(JSON.stringify(english))
        this.$store.dispatch('setLanguage', arrToObj(go, arr))
        return
      }
      // 翻译大礼包
      let arr = []
      arr = mergeObjArr(english)
      // 翻译语言
      postTranslateText({
        // Domain: res.data.domain,
        targetLang: VALUE.data.lanTo,
        text: arr
      }).then(res => {
        if (res.data.code === 0 && res.data.data && res.data.data.translated.length) {
          var translated = res.data.data.translated
          this.translated = VALUE.data.lanTo
          const go = JSON.parse(JSON.stringify(english))
          // 存入vuex 方便调用
          this.$store.dispatch('setLanguage', arrToObj(go, translated))
          // 更新状态
          this.$store.dispatch('setLanguageState', true)
        }
      })
    },
    handleMessage (res) {
      if (res.data.actions === 'PageChange') {
        if (res.data.pageName === 'post-card') {
          return
        }
        // 页面操作
        this.worktype = res.data.typeName
        // 页面跳转
        // console.log('res:', res, this.$store.state.currentPage)
        if (this.$store.state.currentPage !== res.data.pageName) {
          this.$store.dispatch('setPage', res.data.pageName)
        }
      } else {
        // 页面初始化
        this.settingDataHandler(res)
        try {
          const fontStyle = JSON.parse(res.data.liveChat[0])['text-settings'].fontStyle
          this.$store.commit('setAppsFont', fontStyle)
        } catch (err) {}
      }
      const findIndex = this.lanArr.findIndex(item => item === res.data.lanTo)
      // console.log(findIndex)
      if (res.data.lanTo && findIndex !== -1 && this.translated !== res.data.lanTo) {
        this.handleLanTo(res)
      } else {
        // 更新状态
        this.$store.dispatch('setLanguageState', false)
      }
      /* 自动应答预览 */
      if (res.data.actions === 'automation') {
        /* 默认执行运行规则，拿到运行规则的数据，判断入场条件，然后按照场景执行下一步逻辑内容 */
        if (res.data.rule) {
          this.setAutomationActionDemo(res.data)
        } else {
          if (res.data.action) {
            const { type, notChat, data } = res.data.action
            this.setAutomationChat({
              userName: '',
              message: data.label,
              messageState: 2,
              type: 'Customer',
              messageType: 'text',
              profile: ''
            })
            if (type === 'redirect') {
              if (data === 'tracking') { /* 跳转订单查询 */
                this.$store.commit('setPage', 'OrderSearch')
              } else if (data === 'emailUs') { /* 跳转联系我们 */
                this.$store.commit('setPage', 'leave-message')
              } else if (data === 'faq') { /* 跳转FAQ */
                this.$store.commit('setPage', 'search-page')
              } else {

              }
            } else if (type === 'nextRule') {
              this.setAutomationAction(data.cid)
              const getName = Boolean(Number(this.$store.state.shopSetting.VisitorCollection[0]?.Name))
              const getEmail = Boolean(Number(this.$store.state.shopSetting.VisitorCollection[0]?.Email))
              const getPhone = Boolean(Number(this.$store.state.shopSetting.VisitorCollection[0]?.Phone))
              if (!getName && !getEmail && !getPhone) {
                this.setAutomationStatus({ login: true })
              }
              if (!notChat) {
                if (this.automationStatus.login) {
                  this.$store.commit('setPage', 'chat')
                } else {
                  this.$store.commit('setPage', 'login')
                }
              }
            }
          }
        }
      }
      // if (res.data.lanTo) {
      //   this.handleLanTo(res)
      // }
    },
    // 数组对象去重
    removeDuplicateObj (arr) {
      // 缓存用于记录
      const newArr = []
      for (const t of arr) {
        // 检查缓存中是否已经存在
        if (
          newArr.find(
            (c) =>
              c.Timeend === t.Timeend && c.Timerange === t.Timerange && c.Timestart === t.Timestart
          )
        ) {
          // 已经存在说明以前记录过，现在这个就是多余的，直接忽略
          continue
        }
        // 不存在就说明以前没遇到过，把它记录下来
        newArr.push(t)
      }
      // 记录结果就是过滤后的结果
      return newArr
    },
    // 16进制转换rgb
    colorTransform (sHex, alpha) {
      const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
      /* 16进制颜色转为RGB格式 */
      let sColor = sHex.toLowerCase()
      if (sColor && reg.test(sColor)) {
        if (sColor.length === 4) {
          let sColorNew = '#'
          for (let i = 1; i < 4; i += 1) {
            sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
          }
          sColor = sColorNew
        }
        //  处理六位的颜色值
        const sColorChange = []
        for (let i = 1; i < 7; i += 2) {
          // eslint-disable-next-line radix
          sColorChange.push(parseInt(`0x${sColor.slice(i, i + 2)}`))
        }
        return `rgba(${sColorChange.join(',')},${alpha})`
      }
      return sColor
    },
    initFaqData(){
      let arr = [
        {
          categoryName: "Multi-Store Integration",
          categorySort: 4,
          articleList: [
            {
              id: 201479,
              title: "How to integrate multi Shopify stores into one Willdesk account?",
              sort: 14,
              faqUrl: "https://cj0c5jtdb74759mh51c0.willdesk.help/wquery/how-to-integrate-multi-shopify-stores-into-one-willdesk-account/cat_id-pjcxbwcs-73751/article_id-pjcxbwcs-201479"
            },
            {
              id: 201480,
              title: "How to integrate non-Shopify stores into my Willdesk account?",
              sort: 19,
              faqUrl: "https://cj0c5jtdb74759mh51c0.willdesk.help/wquery/how-to-integrate-non-shopify-stores-into-my-willdesk-account/cat_id-pjcxbwcs-73751/article_id-pjcxbwcs-201480"
            },
            {
              id: 201481,
              title: "How to integrate Willdesk widget to my WordPress?",
              sort: 15,
              faqUrl: "https://cj0c5jtdb74759mh51c0.willdesk.help/wquery/how-to-integrate-willdesk-widget-to-my-wordpress/cat_id-pjcxbwcs-73751/article_id-pjcxbwcs-201481"
            },
            {
              id: 201482,
              title: "How to identify the customers in one inbox from multi websites？",
              sort: 18,
              faqUrl: "https://cj0c5jtdb74759mh51c0.willdesk.help/wquery/how-to-identify-the-customers-in-one-inbox-from-multi-websites？/cat_id-pjcxbwcs-73751/article_id-pjcxbwcs-201482"
            }
          ],
          id: 73751,
          categoryIcon: "tools_diamond"
        },
        {
          categoryName: "Helpdesk",
          categorySort: 7,
          articleList: [
            {
              id: 201489,
              title: "How to create a FAQ page on my store?",
              sort: 14,
              faqUrl: "https://cj0c5jtdb74759mh51c0.willdesk.help/wquery/how-to-create-a-faq-page-on-my-store/cat_id-pjcxbwcs-73752/article_id-pjcxbwcs-201489"
            },
            {
              id: 256187,
              title: "How to add links or videos to the article body?",
              sort: 12,
              faqUrl: "https://cj0c5jtdb74759mh51c0.willdesk.help/wquery/how-to-add-links-or-videos-to-the-article-body/cat_id-pjcxbwcs-73752/article_id-pjcxbwcs-256187"
            }
          ],
          id: 73752,
          categoryIcon: "tools_apps"
        },
        {
          categoryName: "Widget Settings",
          categorySort: 8,
          articleList: [
            {
              id: 201493,
              title: "How to set up greetings?",
              sort: 6,
              faqUrl: "https://cj0c5jtdb74759mh51c0.willdesk.help/wquery/how-to-set-up-greetings/cat_id-pjcxbwcs-73753/article_id-pjcxbwcs-201493"
            },
            {
              id: 201497,
              title: "How to set up welcome message post?",
              sort: 7,
              faqUrl: "https://cj0c5jtdb74759mh51c0.willdesk.help/wquery/how-to-set-up-welcome-message-post/cat_id-pjcxbwcs-73753/article_id-pjcxbwcs-201497"
            },
            {
              id: 201498,
              title: "How to style your chat widget?",
              sort: 8,
              faqUrl: "https://cj0c5jtdb74759mh51c0.willdesk.help/wquery/how-to-style-your-chat-widget/cat_id-pjcxbwcs-73753/article_id-pjcxbwcs-201498"
            },
            {
              id: 201499,
              title: "How to set up business hours?",
              sort: 9,
              faqUrl: "https://cj0c5jtdb74759mh51c0.willdesk.help/wquery/how-to-set-up-business-hours/cat_id-pjcxbwcs-73753/article_id-pjcxbwcs-201499"
            },
            {
              id: 201500,
              title: "How to collect the visitor's name and information?",
              sort: 10,
              faqUrl: "https://cj0c5jtdb74759mh51c0.willdesk.help/wquery/how-to-collect-the-visitor-s-name-and-information/cat_id-pjcxbwcs-73753/article_id-pjcxbwcs-201500"
            },
            {
              id: 201501,
              title: "How to set up chat widget language ?",
              sort: 3,
              faqUrl: "https://cj0c5jtdb74759mh51c0.willdesk.help/wquery/how-to-set-up-chat-widget-language-/cat_id-pjcxbwcs-73753/article_id-pjcxbwcs-201501"
            }
          ],
          id: 73753,
          categoryIcon: "communications_chat"
        },
        {
          categoryName: "General",
          categorySort: 10,
          articleList: [
            {
              id: 269312,
              title: "How to set up Shopify flow to create a ticket for Review apps and refunds?",
              sort: 34,
              faqUrl: "https://cj0c5jtdb74759mh51c0.willdesk.help/wquery/how-to-set-up-shopify-flow-to-create-a-ticket-for-review-apps-and-refunds/cat_id-pjcxbwcs-73754/article_id-pjcxbwcs-269312"
            },
            {
              id: 201511,
              title: "How to download Willdesk app on my mobile phone?",
              sort: 21,
              faqUrl: "https://cj0c5jtdb74759mh51c0.willdesk.help/wquery/how-to-download-willdesk-app-on-my-mobile-phone/cat_id-pjcxbwcs-73754/article_id-pjcxbwcs-201511"
            }
          ],
          id: 73754,
          categoryIcon: "communications_send"
        }
      ]
    },
    // 获取固定文案和可编辑项的翻译结果
    getFixedAndCustomTranslate(lang){
      // this.loading = true;
      // postFrameMessage({
      //   type: 'translate-loading',
      //   data: true
      // }, document.referrer.substr(0, document.referrer.length))
      
      let fixedLang = new Promise((resolve, reject) => {
        let params1 = {
          dstLangWithISO6393:lang,
          hax:'da39a3ee5e6b4b0d3255bfef95601890afd80709'
        }
        clientFixedTranslate(params1).then(res => {
          if(res && res.code == 0){
            this.setCurrLangFixedTranslate(res.data.translated)
            resolve()
          }
        }).catch(() => {
          reject()
        })
      });
      let customLang = new Promise((resolve, reject) => {
        // let shopDomain = getUrlParam('shop')
        let shopDomain = ''
        let params2 = {
          dstLangWithISO6393: lang,
          shopDomain
        }
        clientCustomTransalte(params2).then(res => {
          if(res && res.code == 0){
            this.setCurrLangCustomTranslate(res.data.translated)
            resolve()
          }
        }).catch(() =>{
          reject()
        })
      });
      Promise.all([fixedLang,customLang]).then(res => {
        console.log('都成功-----');
        
        // this.loading = false
        // postFrameMessage({
        //   type: 'translate-loading',
        //   data: false
        // }, document.referrer.substr(0, document.referrer.length))
      }).catch(() => {
        // this.lodaing = false
        // postFrameMessage({
        //   type: 'translate-loading',
        //   data: false
        // }, document.referrer.substr(0, document.referrer.length))
      })
    }
  },
  components: {
    Home,
    LeaveMessage,
    Login,
    Chat,
    Loading,
    Toast,
    OrderSearch,
    OrderList,
    SearchPage,
    OrderEmailLogin,
    OrderDetails,
    FaqPages
  }
}
</script>

<style>
  /* 初始化样式 */
  @import "assets/css/normalize.css";
  @import "assets/font/inter.css";

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    user-select: none;
    background: #FFF;
    max-height: 100vh;
    height: 100vh;
    overflow: hidden;
  }

  #message-audio {
    display: none;
  }

  input:focus {
    outline: none;
  }
  .text-ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>

<template>
  <div class="adaptive-button" :style="boxStyle">
    <button :class="btnClass" @click="btnClick" :style="btnStyle">
      <svg-icon :svg-name="iconName" :width="iconWidth" :height="iconHeight" :style="{'margin-right': iconMarginR}" v-if="iconName.length !== 0"></svg-icon>
      <span class="adaptive-text" :style="textStyle">{{ btnText }}</span>
    </button>
  </div>
</template>

<script>
// 自适应按钮
export default {
  name: 'AdaptiveButton',
  props: {
    // 按钮颜色
    btnTheme: {
      type: String,
      // 默认蓝色按钮
      default: 'blue'
    },
    // 按钮文本
    btnText: {
      type: String,
      // 不允许为空
      required: true
    },
    // 按钮高度
    btnHeight: {
      type: String,
      default: '42px'
    },
    // 字体大小
    fontSize: {
      type: String,
      default: '16px'
    },
    // 图标名称
    iconName: {
      type: String,
      default: ''
    },
    // 按钮padding样式
    btnPadding: {
      type: String
    },
    // 图标右外边距
    iconMarginR: {
      type: String,
      default: '3px'
    },
    // icon宽高
    iconWidth: {
      type: String,
      default: '16px'
    },
    iconHeight: {
      type: String,
      default: '16px'
    },
    textMaxWidth: {
      type: String
    },
    // 按钮发生点击时调用的父方法名称
    clickMethods: String,
    // 点击时的调用父方法传入的参数 可能出现的类型
    clickArg: [String, Number, Array, Object]
  },
  computed: {
    btnClass () {
      // 跟随主题色
      return this.btnTheme + '-btn' + ' ' + (this.btnTheme === 'blue' ? 'theme-solid-btn-color theme-text-color' : 'theme-hollow-btn-color')
    },
    btnStyle () {
      const flag = this.btnHeight === '42px'
      const style = {
        fontSize: this.fontSize === '16px' ? '' : this.fontSize
      }
      style.height = flag ? '' : this.btnHeight
      style.lineHeight = flag ? '' : this.btnHeight
      style.padding = this.btnPadding ? this.btnPadding : ''
      return style
    },
    boxStyle () {
      return this.btnHeight === '42px' ? '' : { height: this.btnHeight }
    },
    textStyle () {
      return 'max-width:' + this.textMaxWidth
    }
  },
  methods: {
    btnClick () {
      // console.log(this.clickMethods);
      // TAG 发送自定义事件调用父组件的方法 让按钮点击执行事件更加灵活

      this.$emit('btnClick', this.clickMethods, this.clickArg)
    }
  }
}
</script>

<style>
  .adaptive-button button {
    width: 100%;
    border-radius: 4px;
    height: 42px;
    line-height: 42px;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    border: none;
    cursor: pointer;
    overflow: hidden;
    border-radius: 40px;
    white-space: nowrap;
    /*文字超出宽度则显示ellipsis省略号*/
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:8px 24px;
    /* transition: all .3s; */
  }

  /* 蓝色按钮 */
  .adaptive-button .blue-btn {
    background: #3b59fe;
    color: #fff;
  }

  .adaptive-button .blue-btn:hover {
    background: #304ad9;
  }

  /* 白色按钮 */
  .adaptive-button .white-btn {
    background: #fff;
    color: #353535;
    border: 1px solid #353535;
  }

  .adaptive-button .white-btn:hover {
    border: 1px solid #3b59fe;
    color: #3b59fe;
  }
  .adaptive-text {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
</style>

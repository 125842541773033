// 默认导入所有src文件夹下的icons文件(自动导入，不需手动一个个导入)
import Vue from 'vue'
// 导入组件
import SvgIcon from './SvgIcon'

// 注册组件
Vue.component('svg-icon', SvgIcon)

const requireAll = requireContext => requireContext.keys().map(requireContext)
// 导入指定文件夹下的svg图标
const req = require.context('@/assets/icons', false, /.svg$/)
requireAll(req)

<template>
  <div class="finished" :style="styleText">
    <!-- 完成语文本 -->
    <p class="finished-message">
      <!-- Chatting finished. If you have any questions, please feel free to chat with us again. -->
      {{finishtext}}
    </p>
  </div>
</template>

<script>
export default {
  name: 'Finished',
  props: {
    chatData: {
      type: Object,
      required: true
    }
  },
  computed: {
    textSetting () {
      return JSON.parse(this.$store.state.shopSetting.liveChat['0'])['text-settings']
    },
    styleText () {
      return this.$store.state.boxPadding === '16px' ? 'margin: 26px 16px 26px 16px;' : ''
    },
    finishtext () {
      return this.textSetting.chat_cloed_msg
    }
  }
}
</script>

<style scoped>
  .finished {
    background: #f5f5f5;
    border-radius: 12px;
    padding: 12px 16px;
    margin: 26px;
  }

  .finished-message {
    font-size: 14px;
    font-weight: 400;
    color: #353535;
    /* margin-bottom: 12px; */
    text-align: left;
    /* 强制英文单词内断行 */
    /* word-break: break-all; */
    line-height: 22px;
  }
</style>

<template>
  <svg :class="svgClass" aria-hidden="true" :style="svgSize">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script>

// <svg-icon svg-name="test" width="20px" height="20px"></svg-icon> 使用示例  在组件直接使用即可 svg-name必传 width和height可选传

export default {
  name: 'SvgIcon',
  props: {
    // 图标名称（文件名）
    svgName: {
      type: String,
      required: true
    },
    // 图标类名称 如果传入则拼接 不传入则为svg-icon
    className: {
      type: String,
      default: ''
    },
    width: String,
    height: String
  },
  computed: {
    // 拼接图标名称
    iconName () {
      return `#icon-${this.svgName}`
    },
    // 图标类
    svgClass () {
      if (this.className) {
        return 'svg-icon' + this.className
      } else {
        return 'svg-icon'
      }
    },
    // 内联样式改变图标大小
    svgSize () {
      return { width: this.width, height: this.height }
    }
  }
}
</script>

<style scoped>
/* 默认20x20大小 */
.svg-icon {
  width: 20px;
  height: 20px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>

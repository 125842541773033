<template>
  <div class="article-list-page">
    <div class="page-title">{{ currCategoryInfo.categoryName }}</div>
    <div class="article-list">
      <div class="article-item" v-for="(item,index) in currCategoryInfo.articleList" :key="index" @click="clickActicle(item)">
        <div class="item-wrapper">{{ item.title }}</div>
        <div class="article-border"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: '',
  data () {
    return {
      currCategoryInfo: {}
    }
  },
  computed: {
    ...mapGetters({
      getShopsetting: 'setting/getShopsetting',
      getFaqPageInfo: 'faq/getFaqPageInfo',
      getAllCategoryArticle: 'faq/getAllCategoryArticle'
    })
  },
  watch: {
    getAllCategoryArticle (newVal) {
      if (newVal) {
        console.log('page信息waatch', this.getFaqPageInfo)
        console.log('分类watch', this.getAllCategoryArticle)
        const arr = newVal.filter(item => item.id == this.getFaqPageInfo.categoryId)
        if (arr.length > 0) {
          this.currCategoryInfo = arr[0]
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      setFaqPageInfo: 'faq/setFaqPageInfo'
    }),
    clickActicle (item) {
      console.log(item)
      this.setFaqPageInfo({
        faqPage: 'articleDetail',
        articleId: item.id,
        faqUrl: item.faqUrl,
        articleTitle: item.title,
        categoryId: this.currCategoryInfo.id
      })
    }
  },
  mounted () {
    if (this.getFaqPageInfo.faqPage == 'ArticleListPage' && this.getAllCategoryArticle) {
      console.log('page信息', this.getFaqPageInfo)
      console.log('分类', this.getAllCategoryArticle)
      const arr = this.getAllCategoryArticle.filter(item => item.id == this.getFaqPageInfo.categoryId)
      if (arr.length > 0) {
        this.currCategoryInfo = arr[0]
      }
    }
  }
}
</script>
<style scoped>
.article-list-page{
  padding: 16px;
  word-break:break-word;
}
.article-list-page .page-title{
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 12px;
}
.article-list-page .article-item{
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border-bottom: 1px solid #E5E5EB;
}
.article-list-page .item-wrapper{
  padding:16px;
  margin: 0 -16px;
}
.article-list-page .item-wrapper:hover{
  color: var(--primary-color);
  background-color: var(--backgroundHover)
}
</style>

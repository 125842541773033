<template>
  <div class="chat">
    <nav-bar back-page="home">
      <template #left>
        <svg-icon svg-name="left-arrow" :width="iconWidth" height="20px"></svg-icon>
      </template>
      <template #center>

        <!-- 根据聊天记录区分新老会话 展示不同的头像 -->
        <div class="session-multiple-avatar" ref="sessionMultipleAvatar">
          <multiple-avatar :small="true" :style="avatarStyle"></multiple-avatar>
        </div>

        <!-- <div class="session-one-avatar">
          <img src="~assets/img/avatar3.png" alt="avatar" />
        </div> -->

        <!-- 聊天会话文本 -->
        <div class="session-header-container">
          <p class="session-header-title" v-if="$store.state.shopSetting.Brand">{{$store.state.shopSetting.Brand}}</p>
          <p class="session-header-text">{{$store.state.shopSetting.WelcomeMessage[0].Introduction}}</p>
        </div>
      </template>
    </nav-bar>

    <!-- 消息盒子 -->
    <!-- <div class="message-box"> -->
    <scroll-box :style="styleText" trigger-mode="top" ref="scrollBox">
      <!-- <Customer-service :chat-data="chatRecord[0]"></Customer-service>
      <Customer :chat-data="chatRecord[1]" />
      <Customer :chat-data="chatRecord[1]" />
      <finished :chat-data="chatRecord[2]" />
      <time-remind :chat-data="chatRecord[3]"></time-remind> -->

      <!-- 聊天记录加载loading -->
      <history-loading v-if="historyLoading" />
      <work-welcome v-if="work"></work-welcome>
      <break-welcome v-if="!work"></break-welcome>

      <!-- 遍历消息组件 渲染聊天记录 -->
      <component v-for="(item, index) in chatRecord" :is="automationChatList[index].type" :lastChild="index == automationChatList.length - 1" :chat-data.sync="automationChatList[index]" :index="index" :key="automationChatList[index].timeStamp"></component>
      <!-- <customer-service-typing></customer-service-typing> -->
    </scroll-box>
    <!-- </div> -->

    <!-- pc端emoji容器 -->
    <div class="chat-emoji-container" v-if="!isMobile" v-show="showEmoji">
      <emoji @clickEmoji="pcClickEmoji" />
    </div>

    <!-- <div> -->
    <logo-bar style-text="position:static;background: #F6F6F6;box-shadow: none;" v-if="logoBarState"></logo-bar>
    <!-- 输入框组件 -->
    <chat-input @pushMessage="pushMessage" @emojiChange="emojiChange" @hideEmoji="hideEmoji" :emoji="showEmoji" ref="chatInput"></chat-input>
    <!-- </div> -->

    <!-- 手机端emoji容器 -->
    <div class="chat-mobile-emoji-container" v-if="isMobile" v-show="showEmoji">
      <emoji @clickEmoji="mobileClickEmoji" />
    </div>

    <loading v-if="loading" />
    <toast v-if="toastState" @changeState="setToastState" />
    <reconnecting v-if="reconnecting" />
  </div>
</template>

<script>
// 聊天页面
import NavBar from 'components/contents/NavBar'
import LogoBar from 'components/contents/LogoBar'
import BreakWelcome from './children/BreakWelcome'
import WorkWelcome from './children/WorkWelcome'
// import TimeRemind from './children/TimeRemind'
import CustomerService from './children/CustomerService'
import CustomerServiceTyping from './children/CustomerServiceTyping'
import Customer from './children/Customer'
import ChatInput from './children/ChatInput'
import ScrollBox from 'components/contents/ScrollBox'
import Finished from './children/Finished'
import Emoji from 'components/contents/Emoji'
// import { appendChat, getChatList } from '@/api/chat'
// import { getCustomerService } from '@/api/customerService'
import Loading from 'components/common/Loading/Loading'
import HistoryLoading from './children/HistoryLoading'
import Toast from 'components/common/Toast/Toast'
import Reconnecting from './children/Reconnecting'
import MultipleAvatar from 'components/contents/MultipleAvatar'
import { mapGetters, mapMutations } from 'vuex'
// import { postFrameMessage, listenFrameMessage, removeListenFrame } from '@/utils/iframeMessage'

export default {
  name: 'Chat',
  components: {
    NavBar,
    BreakWelcome,
    WorkWelcome,
    // TimeRemind,
    LogoBar,
    CustomerService,
    Customer,
    ChatInput,
    ScrollBox,
    CustomerServiceTyping,
    Finished,
    Emoji,
    Loading,
    Toast,
    MultipleAvatar,
    Reconnecting,
    HistoryLoading
  },
  data () {
    return {
      work: true,
      // timestamp: 1635406891,
      // timestamp2: 1635000000,
      logoBarState: false,
      showEmoji: false,
      isMobile: false,
      loading: false,
      // 聊天记录
      chatRecord: [
        {
          userName: 'Ai-Bot',
          message: 'Hi, how can i help you?',
          type: 'CustomerService',
          messageType: 'text',
          profile: ''
        },
        {
          userName: 'Jack',
          message: 'How do i win the jackpot?',
          messageState: 2,
          type: 'Customer',
          messageType: 'text',
          profile: ''
        }
        // {
        //   type: 'TimeRemind',
        //   timestamp: 1635000000
        // }
      ],
      toastState: false,
      // 新消息条数 默认0条
      newsNum: 0,
      // 是否在当前浏览器标签页
      isActiveTab: true,
      // iframe面板是否展示
      panelShow: true,
      isHour: true,
      // 重连ing
      reconnecting: false,
      // 是否已经为重连
      reconnected: false,
      historyLoading: false
    }
  },
  created () {
    console.log('automationChatList----',this.automationChatList);
    this.chatRecord = this.automationChatList
    // console.log(this.loading)
    // const workTime = this.$store.state.workTime
    // console.log('工作时间', workTime)
    // 如果没有登录 则跳转游客登录界面
    // if (!this.$store.state.login) this.$store.commit('setPage', 'login')

    // 根据设备判断是否需要显示logobar
    const logoBar = this.$store.state.shopSetting?.WidgetStatus[0]?.LogoBar || {}
    const time = parseInt(new Date().getTime() / 1000)
    this.logoBarState = (logoBar !== '-1' && time > logoBar)
    // this.isMobile = this.$store.state.isMobile
    // this.loading = true
    // this.getCustomerServiceInfo(5)
    // const that = this
    // 监听父页面发送过来的信息
    // listenFrameMessage(this.receiveMessage)

    // 监听发送文件事件
    // this.$bus.$on('sendFile', this.sendFile)
    // 监听文件上传失败事件
    // this.$bus.$on('uploadFileFail', this.uploadFileFail)
    // 监听图片加载结束滚动事件
    // this.$bus.$on('imgLoad', function () {
    // 下滑到底部
    // that.$refs.scrollBox && that.$refs.scrollBox.scrollToFunc('ScrollBox', 'auto')
    // })
    // const timestamp = new Date().getTime()
    // console.log(this.$store.state.userInfo)
    // this.getFirstChatHistory(timestamp)

    // 如果存在 则先关闭原先的websocket连接
    // if (window._ws) window._ws._ws.close()

    // 挂载websocket实例
    // window._ws = new WSClient(process.env.VUE_APP_WS_URL + 'hd?shop=' + this.$store.state.shop + '&uid=' + this.$store.state.userInfo.uid, {
    //   // 打印日志
    //   debug: true,
    //   // 开启客户端心跳
    //   heartbeat: true,
    //   // 心跳间隔
    //   heartbeatInterval: 10000,
    //   heartbeatText: {
    //     format: 'heartBeat',
    //     type: 'ping',
    //     data: 'null'
    //   },
    //   // 当连接成功时
    //   onOpened: that.onOpened,
    //   onReconnect: function () {
    //     that.reconnecting = true
    //   },
    //   onClosed: function () {
    //     console.log('on closed------------------------------------------')
    //   },
    //   // 是否需要在实例化之后立刻尝试连接
    //   automaticOpen: true,
    //   onMessage: function (res) {
    //     // console.log(res)
    //     res = JSON.parse(res.data)
    //     console.log(res)
    //     let index
    //     // console.log(data.content);
    //     // 如果类型为text 就是客服消息（暂时确定） 组织json 渲染到界面
    //     if (res.type === 'text') {
    //       // 假设拿到的数据
    //       /* {
    //                "customerServiceId" : 1, //客服Id
    //                "timeStamp" : 123456//唯一标识:毫秒级时间戳
    //                "data" : "msg"
    //                } */
    //       // 获取用户头像
    //       //  that.getCustomerServiceInfo(res.customerServiceId);
    //       // console.log(res)
    //       console.log('text id ---->' + res.cid)
    //       // 组织插入渲染数组的元素
    //       const messageData = {
    //         // 坐席名称 如果本地没有坐席名称 可能需要去请求 包含头像颜色等
    //         userName: 'helpdesk',
    //         customerServiceId: res.cid,
    //         message: res.data,
    //         messageState: 2,
    //         messageType: 'text',
    //         timeStamp: res.TimeStamp,
    //         type: 'CustomerService'
    //       }
    //       console.log(res.data)
    //       console.log('time-->' + res.TimeStamp)
    //       // 添加最后聊天显示时间
    //       that.addLastRecordTime(res.TimeStamp)

    //       that.chatRecord.push(messageData)

    //       // 如果面板关闭 或者浏览器标签页处于非活跃状态 则提醒新消息
    //       if (!that.panelShow || !that.isActiveTab) {
    //         // 新消息条数+1
    //         that.newsNum++

    //         // 测试新消息提醒
    //         postFrameMessage({
    //           type: 'news',
    //           // 大于99则显示99+
    //           data: String(that.newsNum <= 99 ? that.newsNum : '99+')
    //         }, document.referrer.substr(0, document.referrer.length - 1))
    //       } else {
    //         // 滚动到底部
    //         that.scrollBottom()
    //       }

    //       // 消息发送成功返回服务端响应
    //     } else if (res.type === 'Reply from server') {
    //       // 格式形如{"timeStamp" : 123456 //唯一标识:毫秒级时间戳 "type":"Reply from server"}
    //       // 拿到时间戳之后 用时间戳去聊天记录数组（遍历）寻找对应消息 然后更新对应消息状态
    //       // console.log(res)
    //       const len = that.chatRecord.length
    //       // 逆向遍历 查找时间戳对应消息
    //       for (let i = len - 1; i >= 0; i--) {
    //         // console.log(that.chatRecord[i]);
    //         // 如果找到对应消息
    //         if (typeof that.chatRecord[i].timeStamp !== 'undefined' && String(that.chatRecord[i].timeStamp) === res.TimeStamp) {
    //           // console.log(that.chatRecord[i]);
    //           // 更新消息状态
    //           that.chatRecord[i].messageState = 2
    //           index = i
    //           break
    //         }
    //       }
    //       // console.log(that.$store.state.uid);
    //       // console.log(that.chatRecord[index].message);
    //       // console.log(that.chatRecord[index].timeStamp);
    //       // BUG 用户账号在多处登录发消息 如果没有找到index 说明不是此处发送 需要同步到最后
    //       if (index === undefined) {
    //         // 将消息同步插入到数据
    //         // 组织要同步到列表的数据
    //         const tempData = {
    //           // userName: "Hisen",
    //           // customerServiceId: res.customerServiceId,
    //           message: res.data,
    //           messageState: 2,
    //           // messageType: res.,
    //           timeStamp: res.timeStamp,
    //           type: 'Customer'
    //         }

    //         // 添加最后聊天显示时间
    //         that.addLastRecordTime(res.timeStamp)

    //         // 将返回的消息插入最后
    //         that.chatRecord.push(tempData)

    //         // 滚动到底部
    //         // that.scrollBottom()
    //         // 下滑到最后
    //         that.$refs.scrollBox.scrollToFunc('ScrollBox', 'auto')
    //       } else {
    //         // 找到索引 说明是此处发送 只需要将聊天记录存入数据库
    //         appendChat({
    //           Shop: that.$store.state.shop,
    //           // 将类型替换 存入数据库
    //           Type: res.contentType.replace('text', 'news'), // normal message   img  tips
    //           UserId: String(that.$store.state.userInfo.uid),
    //           CustomerServiceId: '0', // 可选  claimSession 完善
    //           User: that.$store.state.userInfo.userName,
    //           CustomerServiceName: '', // 可选
    //           ChatContent: that.chatRecord[index].message,
    //           Status: '0',
    //           Read: '0',
    //           ChatTimeStamp: String(that.chatRecord[index].timeStamp),
    //           Src: res.src ? res.src : ''
    //         })
    //           .then(res => {
    //             console.log(res)
    //             if (res.data.code === 200) console.log('存入消息成功')
    //           })
    //           .catch(res => {
    //             console.log('存入消息失败')
    //           })
    //       }
    //     } else if (res.type === 'operation' && res.data === 'closed') {
    //       console.log('收到关闭消息了')
    //       // 如果是消息关闭状态 则渲染关闭状态
    //       const closed = {
    //         type: 'Finished',
    //         timeStamp: res.TimeStamp
    //       }

    //       // 添加最后聊天显示时间
    //       that.addLastRecordTime(res.TimeStamp)

    //       that.chatRecord.push(closed)

    //       // 滚动到底部
    //       that.scrollBottom()
    //     } else if (res.type === 'img' || res.type === 'attachment') {
    //       // 如果是图片消息 组织格式进行渲染
    //       // 组织插入渲染数组的元素
    //       const mess = {
    //         // 坐席名称 如果本地没有坐席名称 可能需要去请求 包含头像颜色等
    //         userName: 'helpdesk',
    //         customerServiceId: res.cid,
    //         message: res.data,
    //         messageState: 2,
    //         messageType: res.type,
    //         timeStamp: res.TimeStamp,
    //         type: 'CustomerService',
    //         src: res.src
    //       }
    //       // 添加最后聊天显示时间
    //       that.addLastRecordTime(res.TimeStamp)
    //       that.chatRecord.push(mess)
    //       // 如果面板关闭 或者浏览器标签页处于非活跃状态 则提醒新消息
    //       if (!that.panelShow || !that.isActiveTab) {
    //         // 新消息条数+1
    //         that.newsNum++

    //         // 测试新消息提醒
    //         postFrameMessage({
    //           type: 'news',
    //           // 大于99则显示99+
    //           data: String(that.newsNum <= 99 ? that.newsNum : '99+')
    //         }, document.referrer.substr(0, document.referrer.length - 1))
    //       } else {
    //         // 滚动到底部
    //         that.scrollBottom()
    //       }
    //     }
    //   }
    // })
    // _ws.close();
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.scrollBox && this.$refs.scrollBox.scrollToFunc('ScrollBox', 'auto')
    })
  },
  props: {
    worktype: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      automationChatList: 'automation/automationChatList'
    }),
    iconWidth () {
      return this.$store.state.isMobile ? '10px' : '20px'
    },
    styleText () {
      const bottom = this.logoBarState ? '93px' : '57px'
      return this.$store.state.isMobile ? 'flex: auto;max-height: calc(100vh - 60px - ' + bottom + ');height: 100px;' : 'flex: auto;max-height: calc(100vh - 73px - ' + bottom + ');height: 100px;'
    },
    avatarStyle () {
      return { lineHeight: '0', color: this.$store.state.theme[0], display: 'block' }
    }
  },
  methods: {
    ...mapMutations({
      setAutomationChat: 'automation/setAutomationChat'
    }),
    // 聊天框到达顶部触发的回调
    // touchTop () {
    //   console.log('触顶了.....')
    //   // 如果当前聊天记录列表小于30 直接return
    //   if (this.chatRecord.length < 30) return

    //   // 大于或等于30条 则获取第一条消息时间戳 继续请求之前的聊天记录
    //   const timestamp = Number(this.chatRecord[0].timeStamp)
    //   // 执行ajax请求 渲染聊天记录 -1是因为防止查询到重复的该条消息
    //   this.getChatHistory(timestamp - 1)
    // },
    // emoji表情显示/隐藏
    emojiChange () {
      // console.log('...');
      const that = this
      // 延迟移动端表情显示 避免输入法关闭后太突兀
      if (this.isMobile && !this.showEmoji) {
        const timer = setTimeout(function () {
          that.showEmoji = true
          clearTimeout(timer)
        }, 200)
      } else {
        // console.log('emoji-change')
        this.showEmoji = !this.showEmoji
      }
    },
    // 隐藏表情框
    hideEmoji () {
      console.log('hide-emoji')
      // 如果是移动端 则隐藏表情框
      if (this.isMobile) this.showEmoji = false
    },
    // pc端点击表情
    pcClickEmoji (index) {
      // console.log(index)
      // BUG 首次插入表情需要聚集
      this.$refs.chatInput.$refs.divTextarea.focusFunc()
      // 调用输入框插入表情方法
      this.$refs.chatInput.$refs.divTextarea.insertEmoji(index)
      this.showEmoji = !this.showEmoji
    },
    handleWorktype (type) {
      switch (type) {
        case 'welcome':
          this.work = true
          break
        case 'nowork':
          console.log('触发nowork')
          this.work = false
          break
        case 'close':
          console.log('触发close')
          this.chatRecord = [{
            userName: 'Ai-Bot',
            message: 'Hi, how can i help you?',
            type: 'CustomerService',
            messageType: 'text',
            profile: ''
          },
          {
            userName: 'Jack',
            message: 'How do i win the jackpot?',
            messageState: 2,
            type: 'Customer',
            messageType: 'text'
          }]
          break
        default:
          break
      }
    },
    // 手机端点击表情
    // mobileClickEmoji (index) {
    //   console.log(index)
    //   const that = this
    //   // BUG 首次插入表情需要聚集
    //   this.$refs.chatInput.$refs.divTextarea.focusFunc()
    //   this.$refs.chatInput.$refs.divTextarea.insertEmoji(index)
    //   // this.showEmoji = !this.showEmoji
    //   const timer = setTimeout(function () {
    //     that.showEmoji = false
    //     clearTimeout(timer)
    //   }, 200)
    // },
    // 更新消息状态
    // changeMessageState (index, state) {
    //   this.chatData[index].messageState = state
    // },
    // 推入消息
    pushMessage (message) {
      // 添加最后聊天显示时间
      // this.chatRecord.push(message)
      this.setAutomationChat(message)
    }
    // 首次获取历史聊天记录
    // getFirstChatHistory: function (timestamp) {
    //   this.chatRecord = []
    // const that = this
    // 获取历史聊天记录
    // getChatList({
    //   Shop: that.$store.state.shop,
    //   UserId: that.$store.state.userInfo.uid,
    //   CustomerServiceId: 0, // 可选
    //   ChatTimeStamp: timestamp, // TimeStamp
    //   IsUser: true
    // }).then(res => {
    // console.log(res.data)
    // if (res.data.Code === 200 && res.data.Chat.length !== 0) {
    //   // 获取到历史聊天记录 则渲染历史聊天记录
    //   // console.log('聊天记录......')
    //   // console.log(that.chatRecord)
    //   // that.chatRecord.shift() // 弹出第一条Ai-bot的消息
    //   const len = res.data.Chat.length
    //   // 开始组织消息
    //   for (let i = 0; i < len; i++) {
    //     // 如果是@ 则不展示
    //     if (res.data.Chat[i].Type === 'call') continue
    //     // 组织存入消息格式
    //     let item = {
    //       userName: res.data.Chat[i].CustomerServiceId === '0' ? that.$store.state.userInfo.userName : res.data.Chat[i].CustomerServiceName,
    //       message: res.data.Chat[i].ChatContent,
    //       messageType: res.data.Chat[i].Type === 'news' ? 'text' : res.data.Chat[i].Type,
    //       // 判断类型
    //       type: res.data.Chat[i].CustomerServiceId === '0' ? 'Customer' : 'CustomerService',
    //       customerServiceId: res.data.Chat[i].CustomerServiceId,
    //       timeStamp: res.data.Chat[i].latest,
    //       src: (res.data.Chat[i].Type === 'img' || res.data.Chat[i].Type === 'attachment') ? res.data.Chat[i].Src : ''
    //     }

    // 如果不是关闭状态 直接跳出本轮循环
    // if (res.data.Chat[i].Type === 'tips' && res.data.Chat[i].ChatContent !== 'closed') continue
    // if (res.data.Chat[i].Type === 'tips' && res.data.Chat[i].ChatContent === 'closed') {
    // 关闭消息
    // item = {
    // 判断类型
    // type: 'Finished',
    // timeStamp: res.data.Chat[i].latest
    // }
    // }
    // that.chatRecord.unshift(item)
    // }
    // }

    // 补齐初始化会话功能

    // 聊天时间显示
    // that.showChatRecordTime()

    // 首次获取聊天记录 在最后加入上班欢迎语或者离线欢迎语

    // 获取店铺设置信息
    // const workTime = that.$store.state.workTime
    // 如果是上班时间
    // if (workTime === 0) {
    // const workTime = {
    // type: 'WorkWelcome',
    // timeStamp: String(new Date().getTime())
    // }
    // that.chatRecord.push(workTime)
    // } else {
    // 如果不是上班时间 则计算还有多久回来上班
    // const breakTime = {
    // type: 'BreakWelcome',
    // timeStamp: String(new Date().getTime())
    // }
    // that.chatRecord.push(breakTime)
    // }

    // that.loading = false
    // 首次获取历史聊天记录后下滑
    //     that.$refs.scrollBox.scrollToFunc('ScrollBox', 'auto', 99999999)
    //   })
    //     .catch(res => {
    //       console.log(res)
    //       // that.loading = false
    //       // 开启异常气泡框
    //       that.toastState = true
    //     })
    // },
    // 获取历史聊天记录
    // getChatHistory (timestamp) {
    //   this.historyLoading = true
    // const that = this
    // 获取历史聊天记录
    // getChatList({
    //   Shop: that.$store.state.shop,
    //   UserId: that.$store.state.userInfo.uid,
    //   CustomerServiceId: 0, // 可选
    //   ChatTimeStamp: timestamp, // TimeStamp
    //   IsUser: true
    // }).then(res => {
    //   console.log(res.data)
    //   if (res.data.Code === 200 && res.data.Chat.length !== 0) {
    //     // 获取到历史聊天记录 则渲染历史聊天记录
    //     // that.chatRecord.shift(); // 弹出第一条ai-bot的消息
    //     const len = res.data.Chat.length
    //     const arr = []
    //     // 开始组织消息
    //     for (let i = 0; i < len; i++) {
    //       // 如果是@ 则不展示
    //       if (res.data.Chat[i].Type === 'call') continue
    //       // 组织存入消息格式
    //       let item = {
    //         userName: res.data.Chat[i].CustomerServiceId === '0' ? that.$store.state.userInfo.userName : res.data.Chat[i].CustomerServiceName,
    //         message: res.data.Chat[i].ChatContent,
    //         messageType: res.data.Chat[i].Type === 'news' ? 'text' : res.data.Chat[i].Type,
    //         // 判断类型
    //         type: res.data.Chat[i].CustomerServiceId === '0' ? 'Customer' : 'CustomerService',
    //         customerServiceId: res.data.Chat[i].CustomerServiceId,
    //         timeStamp: res.data.Chat[i].latest,
    //         src: (res.data.Chat[i].Type === 'img' || res.data.Chat[i].Type === 'attachment') ? res.data.Chat[i].Src : ''
    //       }

    //       // 如果不是关闭状态 直接跳出本轮循环
    //       if (res.data.Chat[i].Type === 'tips' && res.data.Chat[i].ChatContent !== 'closed') continue

    //       if (res.data.Chat[i].Type === 'tips' && res.data.Chat[i].ChatContent === 'closed') {
    //         // 关闭消息
    //         item = {
    //           // 判断类型
    //           type: 'Finished',
    //           timeStamp: res.TimeStamp
    //         }
    //       }

    //       arr.unshift(item)
    //     }
    //     that.$refs.scrollBox.keepOriginHeight(() => {
    //       // 在里面执行数据合并，只能执行同步任务
    //       that.chatRecord.unshift(...arr)
    //       // 聊天时间显示
    //       that.showChatRecordTime()
    //     })
    //   }
    //   that.loading = false
    //   this.historyLoading = false
    // })
    //   .catch(res => {
    //     console.log(res)
    //     that.loading = false
    //     // 开启异常气泡框
    //     that.toastState = true
    //   })
    // },
    // 获取坐席信息 本地vuex获取
    // getCustomerServiceInfoIndex (id) {
    //   const len = this.$store.state.customerServiceInfo.length
    //   // 遍历寻找是否存在坐席信息
    //   for (let i = 0; i < len; i++) {
    //     // 如果找到坐席信息 返回对象客服信息数组索引
    //     if (this.$store.state.customerServiceInfo[i].id === id) return i
    //   }
    //   // 未找到则返回-1
    //   return -1
    // },
    // // 获取坐席信息 axios
    // getCustomerServiceInfo (id) {
    //   const that = this
    //   getCustomerService({
    //     Shop: that.$store.state.shop,
    //     CustomerServiceId: id
    //   }).then(res => {
    //     console.log(res.data)
    //     if (res.data.Code === 200) {
    //       // that.customerServiceInfo.push();
    //       // console.log(that.customerServiceInfo);
    //       // 将坐席信息存入到vuex
    //       that.$store.dispatch('addCustomerServiceInfo', {
    //         id: res.data.CustomerService[0].CustomerServiceId,
    //         name: res.data.CustomerService[0].CustomerServiceName,
    //         profile: res.data.CustomerService[0].Profile
    //       })
    //       if (that.getCustomerServiceInfoIndex(id) !== -1) {
    //         that.$set(
    //           that.chatRecord[this.getCustomerServiceInfoIndex(id)],
    //           'userName',
    //           res.data.CustomerService[0].CustomerServiceName
    //         )
    //         that.$set(
    //           that.chatRecord[this.getCustomerServiceInfoIndex(id)],
    //           'profile',
    //           res.data.CustomerService[0].Profile
    //         )
    //       }
    //     }
    //   }).catch(res => {
    //     that.loading = false
    //     // 开启异常气泡框
    //     that.toastState = true
    //     console.log(res)
    //   })
    // },
    // 添加聊天时间显示（累计超过3分钟显示一次）
    // showChatRecordTime () {
    //   console.log('---- 计算聊天时间 ----')
    //   // 首先过滤原有聊天时间
    //   const chatArr = this.chatRecord.filter(val => val.type !== 'TimeRemind')
    //   console.log(chatArr)
    //   const len = chatArr.length
    //   // 过滤结束之后 开始遍历插入
    //   const chatTimeArr = []
    //   // 用来存放基准索引的临时变量 默认为1
    //   let index = 0
    //   for (let i = 0; i < len; i++) {
    //     // 三分钟 等于180000秒 如果两条消息间隔大于等于3分钟 则将index后移 180000
    //     if (Number(chatArr[i].timeStamp) - Number(chatArr[index].timeStamp) >= 180000) {
    //       // index 移位
    //       index = i
    //       // 组织时间组件格式
    //       const temp = {
    //         type: 'TimeRemind',
    //         // 拼接一个后缀标识_t 否则key会重复 导致报错
    //         timeStamp: chatArr[i].timeStamp + '_t'
    //       }
    //       chatTimeArr.push(temp)
    //     }
    //     chatTimeArr.push(chatArr[i])
    //   }
    //   console.log(chatTimeArr)
    //   // 重新赋值
    //   this.chatRecord = chatTimeArr
    // },
    // // 新增最新一条聊天时间显示
    // addLastRecordTime (timestamp) {
    //   console.log('---- 计算最后一条聊天时间 ----')
    //   const len = this.chatRecord.length
    //   if (len < 1) return
    //   let last
    //   // 获取最后一个时间显示控件
    //   for (let i = len - 1; i >= 0; i--) {
    //     if (this.chatRecord[i].type === 'TimeRemind') {
    //       // 如果找到最后一条时间显示控件 先将处理格式
    //       last = Number(this.chatRecord[i].timeStamp.replace('_t', ''))
    //       break
    //     }
    //   }
    //   // 如果没有找到最后一条时间显示控件 则以第一条消息为准（包含欢迎语）
    //   if (!last) last = Number(this.chatRecord[0].timeStamp)
    //   // 根据最后的时间计算间隔大于3分钟 则在最后的消息前插入时间戳 180000
    //   if (Number(timestamp) - last >= 180000) {
    //     this.chatRecord.push({
    //       type: 'TimeRemind',
    //       // 拼接一个后缀标识_t 否则key会重复 导致报错
    //       timeStamp: timestamp + '_t'
    //     })
    //   }
    // },
    // // 滚动到底部方法 如果距离底部200px以上
    // scrollBottom () {
    //   // 如果该ref存在 且触底 下滑
    //   if (this.$refs.scrollBox && this.$refs.scrollBox.touchBottom()) {
    //     // 下滑到最后
    //     this.$refs.scrollBox.scrollToFunc('ScrollBox', 'auto')
    //     // 取消可能存在的消息提醒
    //     this.newsNum = 0

    //     // 取消新消息提醒
    //     postFrameMessage({
    //       type: 'news',
    //       // 传入0表示取消
    //       data: '0'
    //     }, document.referrer.substr(0, document.referrer.length - 1))
    //   } else {
    //     // 未触底 则不滚动到底部 需要提醒新消息
    //     this.newsNum++
    //     // 新消息提醒
    //     postFrameMessage({
    //       type: 'news',
    //       // 大于99则显示99+
    //       data: String(this.newsNum <= 99 ? this.newsNum : '99+')
    //     }, document.referrer.substr(0, document.referrer.length - 1))
    //   }
    // },
    // // 到达底部的回调方法
    // toBottom () {
    //   console.log('toBottom')
    //   // 取消可能存在的消息提醒
    //   this.newsNum = 0

    //   // 取消新消息提醒
    //   postFrameMessage({
    //     type: 'news',
    //     // 大于99则显示99+
    //     data: '0'
    //   }, document.referrer.substr(0, document.referrer.length - 1))
    // },
    // // 设置toast气泡框样式
    // setToastState (flag) {
    //   this.toastState = flag
    // },
    // 弱网环境如果在连接成功前退出聊天Chat界面，close不能关闭opening状态的websocket，就会导致在登录页面建立了websocket
    // 所以在连接成功后做个判断，如果不是聊天Chat页面则直接关闭websocket

    // onOpened (self) {
    //   const that = this
    //   // 如果不是在聊天界面连接成功 直接关闭
    //   // FIXME 应该还有更好的解决方案 先减少
    //   if (this.$store.state.currentPage !== 'chat') {
    //     self.close()
    //   } else {
    //     // 取消重连和loading态
    //     that.reconnecting = false
    //     that.loading = false
    //     // 如果已经不是首次连接 则重新获取聊天记录
    //     if (that.reconnected) that.getFirstChatHistory(new Date().getTime())
    //   }
    //   // console.log('onOpened');
    // },
    // 接收iframe消息
    // receiveMessage (e) {
    //   const that = this
    //   // 校验消息的来源是否合法 避免造成安全隐患
    //   if (e.origin !== document.referrer.substr(0, document.referrer.length - 1)) return
    //   // 接收到消息之后的处理逻辑
    //   // .......
    //   console.log('home收到消息-->' + e.data)
    //   // 回调函数
    //   console.log('chat收到消息了')
    //   const data = e.data
    //   console.log(data)
    //   // 浏览器标签页切换操作
    //   if (data.type === 'tab') {
    //     // 更新浏览器标签页活动状态
    //     that.isActiveTab = data.data
    //     // 面板显示隐藏操作
    //   } else if (data.type === 'panel') {
    //     that.panelShow = data.data
    //     // 如果已经重新显示 则将新消息数清0
    //     if (data.data) that.newsNum = 0
    //   } else {
    //     // 未知消息 先打印再说
    //     console.log(data)
    //   }
    // },
    // 发送图片或文件的回显
    // sendFile ({ src, timestamp, file, type }) {
    //   // 如果src不为空则是图片类型,为空则是file类型
    //   const fileItem = {
    //     type: 'Customer',
    //     userName: this.$store.state.userInfo.userName,
    //     message: file.name,
    //     // currentState: 'loading',
    //     messageState: 1,
    //     messageType: type,
    //     timeStamp: String(timestamp),
    //     file,
    //     src
    //   }

    //   this.chatRecord.push(fileItem)

    //   // 下滑到最后
    //   this.$refs.scrollBox.scrollToFunc('ScrollBox', 'auto')

    //   // 去除可能存在的消息提醒
    //   this.toBottom()
    // },
    // 上传文件失败的回调
    // uploadFileFail (timestamp) {
    //   // 根据传回的时间戳获取文件数组元素 倒序寻找
    //   const len = this.chatRecord.length
    //   console.log(timestamp)
    //   // 逆向遍历 查找时间戳对应消息
    //   for (let i = len - 1; i >= 0; i--) {
    //     // 如果找到对应消息
    //     if (this.chatRecord[i].timeStamp === String(timestamp)) {
    //       // console.log(that.chatRecord[i]);
    //       // 更新消息状态 为失败
    //       this.chatRecord[i].messageState = 0
    //       break
    //     }
    //   }
    // }
  },
  watch: {
    automationChatList: {
      handler (newVal) {
        this.$nextTick(() => {
          this.$refs.scrollBox && this.$refs.scrollBox.scrollToFunc('ScrollBox', 'smooth')
        })
      },
      deep: true
    },
    // 监听重连
    // reconnecting (newValue, oldValue) {
    //   // 如果已经重连过 说明已经不是首次连接
    //   if (newValue) this.reconnected = true
    // }
    worktype: {
      handler (newVal, oldVal) {
        this.handleWorktype(newVal)
      },
      immediate: true
    }
  },
  // 比较时间大小
  // 销毁组件之前
  beforeDestroy () {
    console.log('chat destory')
    // 假装销毁实例
    // if (_ws) _ws.destroy()
    // 销毁websocket实例
    // window._ws = null
    // 取消新消息提醒
    // postFrameMessage({
    //   type: 'news',
    //   // 传入0表示取消
    //   data: '0'
    // }, document.referrer.substr(0, document.referrer.length - 1))
    // 取消消息监听 避免事件重复绑定和执行
    // removeListenFrame(this.receiveMessage)
    // 取消
    // this.$bus.$off('sendFile')
    // this.$bus.$off('uploadFileFail')
    // this.$bus.$off('imgLoad')
  }
}
</script>

<style>
   .chat {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
    max-height: 100vh;
  }

  /* pc端表情容器 */
  .chat-emoji-container {
    width: calc(100% - 52px);
    height: 236px;
    max-height: 236px;
    margin: 0 26px;
    border-radius: 6px;
  }

  /* 手机端表情容器 */
  .chat-mobile-emoji-container {
    width: 100%;
    height: 236px;
    max-height: 236px;
  }

  .session-multiple-avatar {
    flex-shrink: 0;
  }

  .session-one-avatar {
    display: inline-block;
    width: 30px;
    height: 30px;
    /* 垂直居中 */
    vertical-align: middle;
    font-size: 0;
  }

  .session-one-avatar img {
    width: 30px;
    height: 30px;
    vertical-align: top;
  }

  /* 多头像容器 */
  /*  .multiple-avatar-container {
    width: 60px;
    height: 30px;
  } */

  /* 会话头部 */
  .session-header-container {
    flex: 1;
    width: calc(100% - 120px);
  }

  /* 会话标题 */
  .session-header-title {
    font-size: 16px;
    font-weight: 600;
    color: currentColor;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 8px;
    width: 100%;
  }

  /* 会话文本 */
  .session-header-text {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: currentColor;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>

<template>
  <div class="chat-history-loading">
    <div class="chat-history-loading-svg">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30px" height="30px" viewBox="0 0 200 200" xml:space="preserve">
        <!--#3B59FE为深色值   #94a4fe为中间值 可以随意修改-->
        <g id="loading-svg-container-scoped">
          <linearGradient id="loading-svg-right-scoped" gradientUnits="userSpaceOnUse" x1="150" y1="20" x2="150" y2="180">
            <stop offset="0" style="stop-color:#3B59FE" />
            <stop offset="1" style="stop-color:#94a4fe" />
            <!--蓝到浅蓝渐变-->
          </linearGradient>
          <path class="loading-svg-right-scoped" d="M100,0v20c44.1,0,80,35.9,80,80c0,44.1-35.9,80-80,80v20c55.2,0,100-44.8,100-100S155.2,0,100,0z" />
          <!--右半圆环-->
          <linearGradient id="loading-svg-left-scoped" gradientUnits="userSpaceOnUse" x1="50" y1="0" x2="50" y2="180">
            <stop offset="0" style="stop-color:#ffffff" />
            <stop offset="1" style="stop-color:#94a4fe" />
            <!--浅蓝到白色渐变-->
          </linearGradient>
          <path class="loading-svg-left-scoped" d="M20,100c0-44.1,35.9-80,80-80V0C44.8,0,0,44.8,0,100s44.8,100,100,100v-20C55.9,180,20,144.1,20,100z" />
          <!--左半圆环-->
          <circle cx="100" cy="10" r="10" fill="#3B59FE" />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HistoryLoading'
}
</script>

<style>
   .chat-history-loading {
    height: 30px;
    margin: 10px 0;
  }

  .chat-history-loading-svg {
    height: 30px;
    animation: loading 0.8s linear infinite;
  }
</style>

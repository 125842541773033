<template>
  <div class="toast">
    <!-- 气泡框遮罩层 -->
    <div class="toast-mask"></div>
    <!-- 气泡框内容 -->
    <div class="toast-main-container" :style="styleText">{{ message }}</div>
  </div>
</template>

<script>
export default {
  name: 'Toast',
  props: {
    message: {
      type: String,
      default: 'Link error'
    },
    timeout: {
      type: Number,
      default: 3000
    }
  },
  data () {
    return {
      timer: null
    }
  },
  mounted () {
    this.go()
  },
  computed: {
    styleText () {
      return this.$store.state.isMobile ? '' : 'min-width: 180px;'
    }
  },
  methods: {
    // 销毁当前toast组件
    go () {
      if (!this.timer) {
        this.timer = setTimeout(function () {
          // 发送事件
          this.$emit('changeState', false)
          // 清除定时器
          clearTimeout(this.timer)
        }.bind(this), this.timeout)
      }
    }
  },
  destroyed () {
    // 发送事件
    this.$emit('changeState', false)
    // 清除定时器
    clearTimeout(this.timer)
  }
}
</script>

<style scoped>
  .toast-mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 9999;
    background: transparent;
  }

  .toast-main-container {
    width: 140px;
    max-width: 400px;
    height: 46px;
    border-radius: 12px;
    position: absolute;
    background: rgba(64, 64, 64, 0.85);
    left: 50%;
    top: 50%;
    z-index: 10000;
    transform: translate(-50%, -50%);
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    line-height: 46px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  }
</style>

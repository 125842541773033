<template>
  <div class="multiple-avatar-box" :class="smallClass" v-if="showType != 1" :style="{ width: showType != 1 && avatarList.length ? (avatarList.length + 1) * 16 + 'px' : 0 }">
    <div class="avatar" :class="['avatar' + key]" v-for="(item, key) in avatarList" :key="key" :style="{ left: key * 16 + 'px', zIndex: key }">
      <img class="img" :src="item.profile" alt="avatar">
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { textToImg } from '@/utils/helper'
export default {
  name: 'MultipleAvatar',
  props: {
    small: {
      type: Boolean,
      default: false
    }
  },
  // 数据
  data () {
    return {
      defaultAvatar: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAInSURBVHgBpVU7b9NQFP7OjeOKKH1Imagq0SldQELKxoRgrqoOeGBpqqgTP4AR/gASTB06EBaGLB3oWiQWtkiVmMiEhICpzcOhEbHjyzl23Lqtr522n3QT5zy+nMc9xwQDWq2WPRhYVZrQMilUNDAvcgJcaO36QGfJ8387L5xhmj+lEfb7xQcFUve1DuaQgwBo7+xstjOJmbQ87BfX4+hmhWRRHnufktHTbUlN5CpW3IZUIL5Du7gupTwj3tvbr2WRakKFiJ7IkecsculPmIGUwO0Xn2cYPyLC1gWZxgdO/avBYTy/5H1Uo561DDNKTPrssnAqK6V6EOxu115TXhCswQSFFQOByFZMbpal7yplF8omA055hBuAAlSUnpibRho/mbyTojrm0zH5SRMVcqAITW7UUUL0nX+/yfOzqAA3K2qOWKLbxXmtTzWyIcOifH9ygtlwOj354CVlKaV+cVHuGY0Uqqx/qKOI75DUl7g0gbnGBa6/tbDgd4aDudqVTRYR1vmEk3ZhW2k8ZcExfzeR0kS/W/gT2stIcxdrCZ04OpgFGi3+PExE267zGg1vxeKi9y1c4HGks5JGqYhtNXqEW5/u5pDYcZyxVuogejtw+tcFoS6++kQdxKKze9xobLiTv6PPiCO/Htx/fumw8XLDPf+vFHDNX/NgvEI+eoHGO8vC2+3tzV5SQSaP97v7q4GNxzwgW2y0iugIfvDVO2L5FyZsXiaM8R+GhtIdlvrrngAAAABJRU5ErkJggg==',
      showType: 2,
      avatarList: []
    }
  },
  computed: {
    ...mapGetters({
      shopSetting: 'shopSetting'
    }),
    smallClass () {
      return { 'multiple-avatar-small': this.small }
    }
  },
  watch: {
    shopSetting: {
      handler (newVal, oldVal) {
        this.initServicerAvatar()
      },
      immediate: true
    }
  },
  created () {
  },
  methods: {
    // 初始化客服头像
    initServicerAvatar () {
      const serviceList = this.shopSetting.settingInfo?.serviceList || []
      const agentAvatarSettings = JSON.parse(this.shopSetting?.pagesetting?.agentAvatarSettings || '{\"showType\":2,\"buserIdList\":[]}')
      const { showType, buserIdList = [] } = agentAvatarSettings
      this.showType = showType
      if (showType === 2) {
        serviceList.forEach(s => {
          if (!s.profile) {
            s.profile = s.name ? textToImg(s.name) : this.defaultAvatar
          }
        })
        this.avatarList = serviceList.slice(0, 3)
      } else if (showType === 3) {
        const avatarList = []
        buserIdList.forEach(item => {
          const service = serviceList.find(s => {
            if (!s.profile) {
              s.profile = s.name ? textToImg(s.name) : this.defaultAvatar
            }
            return s.id === item
          })
          if (service) {
            avatarList.push(service)
          }
        })
        this.avatarList = avatarList.slice(0, 3)
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .multiple-avatar-box {
    position: relative;
    margin-right: 8px;
    height: 32px;
    .avatar {
      width: 32px;
      height: 32px;
      border: 2px solid #fff;
      border-radius: 50%;
      position: absolute;
      background: #fff;
      font-family: 'Inter';
      .img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        line-height: 0;
      }
      .avatar0 {
        left: 0;
      }
      .avatar1 {
        left: 16px;
      }
      .avatar2 {
        left: 32px;
      }
    }
  }
</style>

<template>
  <div class="reconnecting" :style="styleText">
    <div class="reconnecting-icon">
      <svg-icon svg-name="exclamation" width="16px" height="16px"></svg-icon>
    </div>
    <p class="reconnecting-text">
      Reconnecting...
    </p>
  </div>
</template>

<script>
export default {
  name: 'Reconnecting',
  computed: {
    styleText () {
      return this.$store.state.isMobile ? 'top:60px;' : ''
    }
  }
}
</script>

<style>
  .reconnecting {
    width: 100vw;
    position: fixed;
    left: 0;
    top: 80px;
    display: flex;
    justify-content: center;
    background: #f8d5d5;
  }

  .reconnecting-icon {
    width: 16px;
    margin: 12px 0;
  }

  .reconnecting-text {
    color: #FF2222;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    margin-left: 8px;
  }
</style>

<template>
  <simplebar class="page-scroll" data-simplebar-auto-hide="true" data-simplebar-direction="ltl" :style="containerStyle">
    <slot />
  </simplebar>
</template>

<script>
// 页面滚动容器
// 导入simplebar滚动条
import simplebar from 'simplebar-vue'
import 'simplebar/dist/simplebar.min.css'

export default {
  name: 'PageScroll',
  components: {
    simplebar
  },
  computed: {
    // 根据pc和移动端进行不同处理
    containerStyle () {
      // console.log(this.$store.state.boxPadding)
      const top = this.$store.state.isMobile ? '60px' : '80px'
      return 'height:calc(100vh - ' + top + ');top:' + top + ';padding:26px ' + this.$store.state.boxPadding + ';'
    }
  }
}
</script>

<style scoped>
  .page-scroll {
    width: 100%;
    height: calc(100vh - 74px);
    position: fixed;
    top: 74px;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .simplebar-scrollbar::before {
    background: #B9B9B9;
  }

  /* 修复部分浏览器下聚焦异常外边框 */
  .simplebar-content-wrapper:focus {
    outline: none;
  }
</style>

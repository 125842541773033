<template>
  <div class="faq-item">
    <span>{{ itemData.ArticleTitle }}</span>
    <svg-icon svg-name="direction-right" width="20px" height="20px" class="faq-item-svg"></svg-icon>
  </div>
</template>

<script>
export default {
  name: 'FaqItem',
  props: {
    itemData: Object
  },
  methods: {
    // 点击方法
    itemClick () {
      // 因为后期可能会有其他逻辑
      // window.location.href="test";
      // console.log('问题被点击了，做些什么吧 url-->' + this.itemData.url)
      this.$store.commit('setArticleId', this.itemData.ArticleId * 1)
      // this.$store.commit('setPage', 'QuestionDetail')
    }
  }
}
</script>

<style>
.faq-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  padding: 8px 16px;
  text-align: left;
  cursor: pointer;
}
.faq-item span {
  width: 272px;
  text-overflow: ellipsis;
  line-height: 22px;
  overflow: hidden;
  word-break: break-word;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
.faq-item:hover {
  background: var(--backgroundHover);
}
</style>

import Vue from 'vue'
import App from './App.vue'
// import store from '@/store'
import bus from '@/assets/js/Bus'
// 导入SVG
import 'components/common/SvgIcon'
import promise from 'es6-promise'
import store from './store/index'
// moment.js
import moment from 'moment'
// 导入websocket
// import WSClient from '@/network/websocket'
// 导入smoothscroll
import smoothscroll from 'smoothscroll-polyfill'
const { version } = require('./version.js')

// 挂载websocket类
// window.WSClient = WSClient

// promise兼容ie垫片
promise.polyfill()
// kick off the polyfill!
smoothscroll.polyfill()

Vue.config.productionTip = false

// 挂载中央事件总线
Vue.prototype.$bus = bus
Vue.prototype.$moment = moment
Vue.prototype.$version = version
// 允许 vue-devtools 检查代码
Vue.config.devtools = true

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')

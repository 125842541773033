<template>
  <div class="loading">
    <!-- loading遮罩层 -->
    <div class="loading-mask"></div>
    <!-- loading图标 -->
    <div class="loading-icon-container">
      <!-- 此层不能同时使用animation和transform 会导致transform失效  -->
      <div class="loading-icon">
        <!-- 弃用原有svg <svg width="72" height="72">
          <circle class="loading-spinner" cx="36" cy="36" r="18"></circle>
        </svg> -->
        <img src="~assets/img/loading.png" alt="loading">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading'
}
</script>

<style>
  .loading-mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 9999;
    background: rgba(255,255,255);
  }

  .loading-icon-container {
    width: 48px;
    height: 48px;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 10000;
    transform-origin: 50% 50%;
    transform: translate(-50%, -50%);
  }

  .loading-icon {
    width: 38px;
    height: 38px;
    /* loading动画 */
    animation: loading 0.8s linear infinite;
  }

  .loading-icon img {
    width: 38px;
    height: 38px;
  }

  /* .loading-spinner {
  fill: none;
  stroke: #3b59fe;
  stroke-width: 4px;
  stroke-dasharray: 108, 15;
  stroke-dashoffset: 130;
  transform-origin: 50% 50%;
} */
  @keyframes loading {
    from {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .loading-svg-left-scoped {
              fill: url(#loading-svg-left-scoped);
            }

 .loading-svg-right-scoped {
              fill: url(#loading-svg-right-scoped);
 }
</style>

import request from '@/network/request'

// 翻译文案接口
export function postTranslateText (data) {
  return request({
    url: 'v1/translate/transText',
    method: 'post',
    data
  })
}

// 获取固定导航文案的翻译
export function clientFixedTranslate (data) {
  return request({
    url: 'v1/translations/local/navigateTrans',
    method: 'post',
    data
  })
}
// 获取b端可编辑配置项文案翻译
export function clientCustomTransalte (data) {
  return request({
    url: 'v1/translations/local/settingTrans',
    method: 'post',
    data
  })
}

<template>
  <div class="chat-input"  @drop.prevent="dropFile" @dragover.prevent>
    <!-- 滚动容器 -->
    <!-- <scroll-container style-text="top:auto;bottom:0;height:auto;max-height:188px;width:clac(100% - 130px);">
      <div class="chat-input-textarea" contenteditable="true">{{content}}</div>
    </scroll-container> -->

    <div class="chat-input-container">
      <div-textarea :inputContent.sync="inputContent" :inputState.sync="inputState" @sendMessage="sendMessage" @hideEmoji="hideEmoji" ref="divTextarea"></div-textarea>
    </div>

    <div class="chat-input-tool-container" :style="toolWidth">
      <div class="chat-input-tool">
        <div class="chat-emoji" @click="emojiChange">
          <svg-icon svg-name="emoji" width="20px" height="20px"></svg-icon>
        </div>
        <div class="chat-attachment" v-show="contentEmpty" @click="sendAttachment">
          <label for="uploadFile">
            <svg-icon svg-name="chat-attach" width="20px" height="20px"></svg-icon>
          </label>
          <!-- 附件存放容器 -->
          <input type="file" hidden id="uploadFile" @change="uploadFile" />
          <!-- 图片存放容器 -->
          <input type="file" hidden id="uploadImg" @change="uploadFile" accept="image/png,image/gif,image/jpeg,image/bmp" />
        </div>
        <div class="chat-send" @click="sendMessage">
          <div class="chat-icon" v-html="sendIcon"></div>
          <!-- <svg-icon svg-name="chat-send-active" width="20px" height="20px" :style="sendColor"></svg-icon> -->
        </div>
      </div>
      <div class="font-length" v-show="inputContent.length >= 5900">
        <span :style="{...inputLengthStyle}">{{ inputContent.length }}
        </span>
        / 6000
      </div>
    </div>
  </div>
</template>

<script>
// 消息输入框
import DivTextarea from 'components/contents/DivTextarea'
import { mapGetters, mapActions } from 'vuex'
// import { readAndUploadFile } from '@/utils/uploadFile'

export default {
  Name: 'ChatInput',
  components: {
    DivTextarea
  },
  computed: {
    ...mapGetters({
      themeColor: 'themeColor'
    }),
    contentEmpty () {
      return this.inputContent.length === 0
    },
    sendColor () {
      return { color: this.contentEmpty ? '#B4B4B4' : this.$store.state.theme[0] }
    },
    toolWidth () {
      return this.inputContent.length !== 0 ? 'width:110px;' : 'width:140px;'
    },
    inputLengthStyle () {
      return { color: this.inputContent.length > 6000 ? 'red' : 'unset' }
    },
    sendIcon () {
      return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="-2 -2 20 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.45352 0.227788L14.6842 5.13822C16.4386 5.90531 16.4386 8.09469 14.6842 8.86178L3.45352 13.7722C1.45607 14.6456 -0.693181 12.8496 0.213815 11.065L1.85018 7.84539C1.88175 7.78328 1.90967 7.72016 1.93396 7.65625L6 7.65625C6.41421 7.65625 6.75 7.36244 6.75 7C6.75 6.63756 6.41421 6.34375 6 6.34375L1.93396 6.34375C1.90968 6.27984 1.88175 6.21672 1.85018 6.15461L0.213816 2.93497C-0.693181 1.1504 1.45607 -0.645567 3.45352 0.227788Z" fill="${this.themeColor}"/>
          </svg>`
    }
  },
  props: {
    emoji: Boolean
  },
  data () {
    return {
      chatInputHeight: 0,
      // 输入内容
      inputContent: '',
      // 当前输入框状态  true代表focus  false代表blur
      inputState: false
    }
  },
  methods: {
    ...mapActions({
      runSendMessageRule: 'automation/runSendMessageRule'
    }),
    // inputContent:
    emojiChange () {
      this.$emit('emojiChange')
    },
    sendMessage () {
      // console.log('send message')
      // // 如果大于6000个字符就直接return
      // if (this.inputContent.length > 6000) {
      //   console.log('超出字符限制')
      //   return false
      // }
      const content = this.inputContent.trim()
      // // 发送消息 如果消息为空 直接返回即可
      // if (content.length === 0) return false

      const timestamp = new Date().getTime()

      // // 组织消息json 存入本地数组
      const messageData = {
        userName: 'Jack',
        message: content,
        messageState: 1,
        type: 'Customer',
        messageType: 'text',
        timeStamp: timestamp
      }

      this.$emit('pushMessage', messageData)
      this.runSendMessageRule(content)
      // console.log(window._ws)

      // // 调用父组件下的组件方法 下滑
      // this.$parent.$refs.scrollBox.scrollToFunc('ScrollBox', 'auto', 99999999)

      // // TAG 临时 调用父组件触底方法 清除未读消息提醒
      // this.$parent.toBottom()

      // const that = this

      // if (window._ws === null) console.log('出问题了，小老弟')
      // /* if (_ws._ws.readyState !== WebSocket.OPEN){
      //         console.log('正在重连');
      //       } */
      // // 组织发送格式 发往服务器
      // const sendJson = {
      //   format: 'message',
      //   type: 'text',
      //   // 当前顾客id 在顾客登录时需要将id存入vuex
      //   uid: that.$store.state.userInfo.uid,
      //   data: content,
      //   target: 'no', // 不可省略字段
      //   timeStamp: String(timestamp), // 唯一标识:毫秒级时间戳
      //   uname: that.$store.state.userInfo.userName,
      //   facesColor: that.$store.state.userInfo.facesColor
      // }
      // console.log(JSON.stringify(sendJson))
      // // 发送消息 调用websocket实例发送消息
      // window._ws
      //   .send(JSON.stringify(sendJson))
      //   .then(function (data) {
      //     console.log('本地消息发送成功')
      //     // some code...
      //   })
      //   .catch(function (data) {
      //     console.log('本地消息发送失败')
      //     // some code...
      //     // 消息发送失败 更新消息状态 可以不用更新 后面有自动超时机制
      //   })

      // // 如果消息不为空 则发送消息 清空输入框
      this.$refs.divTextarea.clearTextarea()
    },
    changeInputContent (val) {
      this.inputContent = val
    },
    hideEmoji () {
      this.$emit('hideEmoji')
    },
    sendAttachment () {
      console.log('sendAttachment click')
    },
    // 事件监听钩子
    // 放下拖动文件的回调
    dropFile (eve) {
      // const e = eve || window.event
      // const file = e.dataTransfer.files[0]
      // // 上传文件 返回promise
      // file && readAndUploadFile(file, this.$store.state.userInfo)
    },
    // 上传文件的回调
    uploadFile (eve) {
      // const e = eve || window.event
      // const file = e.target.files[0]

      // file && readAndUploadFile(file, this.$store.state.userInfo)

      // // 清空 input的value
      // e.target.value = ''
    }
  }
}
</script>

<style scoped>
  .chat-input {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 56px;
    max-height: 102px;
    height: auto;
    /* background: #fff; */
    box-shadow: 0 -1px 10px 5px rgba(231, 233, 236, 0.4);
    /* border-radius: 0 0 10px 10px; */
  }

  .chat-input-container {
    flex: auto;
    min-height: 56px;
    height: auto;
  }

  .chat-input-tool-container {
    width: 140px;
  }

  .chat-input-tool {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 26px 0 18px;
    width: 100%;
    height: 56px;
  }
  .chat-icon {
    line-height: 0;
  }

  .chat-input-tool div {
    cursor: pointer;
  }

  .font-length {
    font-size: 12px;
    transform: scale(0.9);
  }

  .svg-icon {
    color: #000;
  }
</style>

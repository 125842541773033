<template>
  <simplebar class="scroll-box" :style="{ height: height ? height + 'px' : '' }" @scroll="scroll" ref="simplebar">
    <slot></slot>
  </simplebar>
</template>

<script>
import simplebar from 'simplebar-vue'
import 'simplebar/dist/simplebar.min.css'

import { throttle } from '@/utils/helper'

let throttleFunc = null

export default {
  name: 'ScrollBox',
  components: { simplebar },
  props: {
    // 指定高度
    height: {
      type: [Number, String],
      default: ''
    },

    // 选择触顶还是触底  bottom / top   默认触底
    triggerMode: {
      type: String,
      default: 'bottom'
    },

    // 触发距离 默认 0  单位px
    distance: {
      type: [Number, String],
      default: 0
    },

    // 触发节流延时  单位 ms
    delay: {
      type: [Number, String],
      default: 0
    },

    // 是否禁止
    disabled: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    throttleFunc = throttle(this.delay * 1)
  },
  methods: {
    // 监听钩子的回调
    // 监听滚动
    scroll (eve) {
      // 如果禁止了，就直接return出去
      if (this.disabled) return

      // 兼容ie
      const e = eve || window.event
      if (this.calcDistance(e.currentTarget) <= this.distance * 1) {
        // 符合条件会 emit一个 trigger事件
        throttleFunc(() => {
          this.$emit('trigger')
        })
      }

      // TAG 临时写的方法 触底回调 不用记得删除
      if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop - e.currentTarget.clientHeight === 0) {
        // 符合条件会 emit一个 scrollBottom 触底事件
        this.$emit('scrollBottom')
      }
    },

    // 方法
    // 计算当前位置和顶部或底部的距离
    calcDistance (target) {
      // 判断触发模式
      if (this.triggerMode === 'bottom') {
        return target.scrollHeight - target.scrollTop - target.clientHeight
      } else if (this.triggerMode === 'top') {
        return target.scrollTop
      }
    },

    // 滚动到底部
    // className: 需要滚动的类名
    // behavior: 是否需要滚动动画  auto smooth
    // height: 滚动指定高度, 默认滚动到底部
    scrollToFunc (
      className = 'ScrollBox',
      behavior = 'smooth',
      height = 99999999
    ) {
      const el = this.$refs.simplebar.SimpleBar.getScrollElement(`.${className}`)
      this.$nextTick(() => {
        el.scrollTo({
          behavior,
          top: height + el.scrollTop
        })
      })
    },

    // 加载数据后保留在原来的高度
    // 为了保证程序能够按顺序执行 将数组推入数组的操作放在callback中执行
    async keepOriginHeight (callback) {
      const contentEl = this.$refs.simplebar.SimpleBar.contentEl
      // 加载数据前的高度
      const originHeight = contentEl.offsetHeight

      // 数据更新
      await callback()

      // 数据加载完成后 滚回原来的高度
      this.$nextTick(() => {
        // 加载数据后的高度
        const currentHeight = contentEl.offsetHeight
        this.scrollToFunc('ScrollBox', 'auto', currentHeight - originHeight)
      })
    },

    // 判断是否触底
    // offset: 距离触底的距离 默认200px
    touchBottom (offset = 200) {
      const contentWrapperEl = this.$refs.simplebar.SimpleBar.contentWrapperEl

      const scrollHeight = contentWrapperEl.scrollHeight
      const scrollTop = contentWrapperEl.scrollTop
      const clientHeight = contentWrapperEl.clientHeight

      if (scrollTop + clientHeight + offset >= scrollHeight) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>
  .scroll-box {
    width: 100%;
  }

  /* 修复部分浏览器下聚焦异常外边框 */
  .simplebar-content-wrapper:focus {
    outline: none;
  }
</style>

<template>
  <div class="category-list-page">
    <div v-if="trackManageOrderSwitch == '1'">
      <div class="page-title">{{ buttonTitle }}</div>
      <div class="track-order-btn" @click="mangageOrder">{{ buttonTitle }}</div>
    </div>
    <div class="page-title">{{ allCategory }}</div>
    <div class="category-list">
      <div class="category-item" v-for="(item,index) in getAllCategoryArticle" :key="index" @click="clickCategory(item.id)">
        <div class="category-item-wrappper">
          <div class="cate-left">
            <div v-if="item.categoryIcon" class="cate-icon">
              <img :src="item.categoryIcon" alt="" v-if="item.categoryIcon.startsWith('https')">
              <svg-icon :svg-name="item.categoryIcon" width="20px" height="20px" class="" v-else></svg-icon>
            </div>
            <div class="cate-name">{{ item.categoryName }}</div>
          </div>
          <div class="cate-right">
            <svg-icon svg-name="direction-right" width="20px" height="20px" class="faq-item-svg"></svg-icon>
          </div>
        </div>
        <div class="item-border"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations,mapActions } from 'vuex'
export default {
  name: '',
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      getShopsetting: 'setting/getShopsetting',
      getFaqPageInfo: 'faq/getFaqPageInfo',
      getAllCategoryArticle: 'faq/getAllCategoryArticle',
      getCurrLangFixedTranslate: 'translate/getCurrLangFixedTranslate',
      getCurrLangCustomTranslate: 'translate/getCurrLangCustomTranslate',
    }),
    allCategory () {
      return this.getShopsetting.helpcenterAllcategory || 'All Categories'
    },
    trackManageOrderSwitch () {
      return this.getShopsetting.trackManageOrderSwitch
    },
    buttonTitle () {
      return this.$store.state.language.trackOrder[27] || "Track and manage order "
    }
  },
  methods: {
    ...mapMutations({
      setFaqPageInfo: 'faq/setFaqPageInfo',
      setPage:'setPage',
    }),
    ...mapActions({
      setPageHandler: 'setPage'
    }),
    clickCategory (id) {
      let cateArr = this.getAllCategoryArticle.filter(item => item.id == id)[0]
      if(cateArr.articleList.length > 1){
        this.setFaqPageInfo({
          faqPage: 'ArticleListPage',
          categoryId: id
        })
      }else{
        let article = cateArr.articleList[0]
        this.setFaqPageInfo({
          faqPage: 'articleDetail',
          articleId: article.id,
          faqUrl: article.faqUrl,
          articleTitle: article.title,
          categoryId: id
        })
      }
    },
    mangageOrder(){
      this.setPageHandler({
        page:'OrderList',
        needLogin:true
      })
    }
  },
  mounted () {
    // console.log(this.getAllCategoryArticle)
  }
}
</script>
<style scoped>
.category-list-page{
  padding: 16px;
  word-break:break-word;
}
.category-list-page .page-title{
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 12px;
}
.track-order-btn{
  background-color: #F3F3F6;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 24px;
  cursor: pointer;
}
.cate-icon{
  margin-right:16px;
  width: 20px;
  height: 20px;
  overflow: hidden;
}
.cate-name{
  flex: 1;
}
.cate-icon img{
  width: 100%;
  height: 100%;
}
.cate-icon svg{
  filter: drop-shadow(20px 0 var(--primary-color));
  transform: translateX(-20px);
}
.category-item{
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.category-item-wrappper{
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;
  margin: 0 -16px;
}
.item-border{
  width: 100%;
  height: 1px;
  background-color:#E5E5EB;
}
.category-item-wrappper:hover{
  color: var(--primary-color);
  background-color: var(--backgroundHover);
}
.category-item .cate-left{
  flex: 1;
  display: flex;
  align-items: center;
}
.category-item .cate-right{
  width: 20px;
  height: 20px;
}
</style>

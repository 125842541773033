<template>
  <div class="select-input" @click="optionState">
    <!-- 提示文本 -->
    <div class="select-placeholder" v-if="placeholderShow">
      {{ placeholder }}
    </div>
    <!-- 选择结果 -->
    <div class="select-end" v-if="activeTextShow">{{ activeText }}</div>
    <!-- 图标 -->
    <div class="select-icon">
      <!-- 根据选项展示情况更换图标 -->
      <svg-icon :svg-name="optionsShow ? 'select-up' : 'select-down'" width="12px" height="7px"></svg-icon>
    </div>

    <!-- 选项 -->

    <div class="select-options" v-show="optionsShow">
      <scroll-container styleText="position:relative;height:auto;max-height:168px;width:calc(100%);left:0;top: 100%;">
        <ul class="select-ul">
          <li :class="getClasses(index)" v-for="(item, index) in options" :key="index" @click="select(index)">
            {{ item }}
          </li>
        </ul>
      </scroll-container>
    </div>
  </div>
</template>

<script>
import ScrollContainer from 'components/contents/ScrollContainer'
export default {
  name: 'SelectInput',
  components: {
    ScrollContainer
  },
  props: {
    // 提示文字
    placeholder: String,
    // 选中项索引
    active: {
      type: Number,
      // 没有传入 默认为-1
      default: -1
    },
    // 选中项文本
    activeText: String,
    // 选中项
    options: {
      type: Array,
      // 不允许为空
      required: true
    }
  },
  data () {
    return {
      optionsShow: false
    }
  },
  created () {
    // 如果已经有选中项 则展示选中项文字
    if (this.active >= 0) { this.$emit('updateActiveText', this.options[this.active]) }
  },
  computed: {
    placeholderShow () {
      // 如果传入提示文本 且没有选中项 则展示提示文本
      return this.placeholder && this.active < 0
    },
    activeTextShow () {
      // 如果没有传入选中项
      return this.active >= 0
    }
  },
  methods: {
    optionState () {
      this.optionsShow = !this.optionsShow
    },
    // 获取样式
    getClasses (index) {
      return {
        'select-option': true,
        'select-option-active': index === this.active,
        'theme-solid-text-color': index === this.active
      }
    },
    // 选中
    select (index) {
      // 更新选中项及选中项文本
      this.$emit('updateActive', index)
      this.$emit('updateActiveText', this.options[index])
    }
  }
}
</script>

<style scoped>
  /* 可以根据需求重新更改样式 */
  .select-input {
    position: relative;
    border-radius: 4px;
    border: 1px solid #888;
    /* overflow: hidden; */
  }

  .select-placeholder {
    width: 100%;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    color: #b4b4b4;
    height: calc(100%);
    /* 需要根据实际情况更改 否则文字无法垂直居中 */
    line-height: 40px;
  }

  .select-end {
    width: 100%;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    height: calc(100%);
    line-height: 40px;
    padding-right: 28px;
    /* 超出部分用...代替 */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /* 设置行数为3行*/
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  .select-icon {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 100%;
    right: 16px;
    top: 0;
  }

  .select-options {
    position: absolute;
    width: calc(100% + 2px);
    top: calc(100% + 2px);
    left: -1px;
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    z-index: 2;
  }

  .select-ul {
    width: 100%;
    list-style: none;
    height: auto;
    z-index: 2;
    /* 最多显示4行 */
    /* max-height: 168px; */
    /* overflow-x: hidden; */
    /* overflow-y: hidden; */
  }

  .select-option {
    width: 100%;
    line-height: 22px;
    text-align: left;
    text-indent: 0 !important;
    color: #292929;
    font-weight: 400;
    font-size: 16px;
    padding: 10px 16px;
    min-height: 42px;
  }

  .select-option:hover {
    background: rgb(174, 187, 255, 0.15);
  }

  .select-option-active {
    color: #3b59fe;
  }

  .select-option:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .select-option:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
</style>

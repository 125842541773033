<template>
  <div class="welcome-panel" @click="toChat">
    <div class="chat-with-us-left">
      <div class="multiple-avatar-container">
        <multiple-avatar :small="true" />
      </div>
      <div class="chat-with-us-text text-ellipsis">{{ chatText }}</div>
    </div>
    <svg-icon svg-name="chat-with-us" width="20px" height="20px"></svg-icon>
    <!-- 上下班时间容器 -->
    <!-- <div class="welcome-work-container" >
      <p class="welcome-work-title" v-if="workTitle">{{ workTitle }}</p>
      <p class="welcome-work-text" v-if="workContent">
        <svg-icon svg-name="clock" width="20px" height="20px"></svg-icon>
        &nbsp;
        <span class="content-text">{{ workContent }}</span>
      </p>
    </div> -->
  </div>
</template>

<script>
import MultipleAvatar from 'components/contents/MultipleAvatar'

export default {
  name: 'WelcomePanel',
  components: {
    MultipleAvatar
  },
  data () {
    return {
      content: ''
    }
  },
  props: {
    chatText: {
      type: String,
      default: ''
    }
  },
  computed: {
    textSetting () {
      return JSON.parse(this.$store.state.shopSetting.liveChat['0'])['text-settings']
    },
    // 根据上下班时间获取标题
    workTitle () {
      // 如果处于上班时间
      return this.$store.state.workTime === 0 ? this.workText : this.backText
    },
    backTime () {
      let workTime = new Date().getTime() + this.$store.state.workTime * 60000 + 60000
      if ((this.$store.state.workTime / 60) > 24) {
        return this.$moment(workTime).format('MMM, DD hh:mm A')
      } else {
        let time = this.$store.state.workTime
        /* 如果是夏令时，需要减去一个小时 */
        if (time < 30) {
          if (time === 0) return JSON.parse(this.textSetting.chat_welcome_msg) && JSON.parse(this.textSetting.chat_welcome_msg).three
          return '30 ' + 'minutes'
        } else {
          return (Math.ceil(time / 60) + ' ' + 'hours')
        }
      }
    },
    workContent () {
      if (this.$store.state.workTime === 0) {
        return JSON.parse(this.textSetting.chat_welcome_msg) && JSON.parse(this.textSetting.chat_welcome_msg).three
      } else {
        const waittime = this.$store.state.shopSetting.BusinessHours && this.$store.state.shopSetting.BusinessHours[0].chatNonworkMsgTime
        return waittime ? waittime.replace(/{Waittime}/g, this.backTime) : ''
      }
    },
    workText () {
      return JSON.parse(this.textSetting.chat_welcome_msg) && JSON.parse(this.textSetting.chat_welcome_msg).two
    },
    backText () {
      return JSON.parse(this.textSetting.chat_nonwork_msg) && JSON.parse(this.textSetting.chat_nonwork_msg).two
    }
  },
  methods:{
    toChat(){
      this.$store.commit('setPage', 'chat')
    }
  }
}
</script>

<style scoped>
  .welcome-panel {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
  }
  .chat-with-us-left{
    display: flex;
    flex: 1;
    overflow: hidden;
    align-items: center;
  }
    .chat-with-us-text{
      font-size: 14px;
      text-align: left;
      flex: 1;
    }
  .multiple-avatar-container {
    display: inline-block;
    display: flex;
    height: 32px;
    vertical-align: top;
  }
  .welcome-work-container{
    display: inline-block;
    flex: 1;
    overflow: hidden;
    margin-left: 24px;
    vertical-align: top;
    padding: 4px 0;
  }

  .welcome-work-title{
    font-size: 14px;
    color: #6C6B80;
    font-weight: 400;
    text-align: left;
    line-height: 22px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    text-overflow: -o-ellipsis-lastline;
    word-wrap: break-word;
  }

  .welcome-work-text{
    font-size: 14px;
    color: #000;
    font-weight: 500;
    text-align: left;
    line-height: 22px;
    margin-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
  .content-text{
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>

/**
 * @description 翻译相关的内容
 */
// import { getStorage, setStorage } from '../../utils/common'

const state = ({
  currLang:'', // c端当前选择的语言
  currLangFixedTranslate:{}, // c端当前语言下的导航固定翻译内容
  currLangCustomTranslate:{} // c端当前语言下的可编辑项翻译内容
})

const getters = {
  getCurrLang: state => state.currLang,
  getCurrLangFixedTranslate: state => state.currLangFixedTranslate,
  getCurrLangCustomTranslate: state => state.currLangCustomTranslate,
}

const mutations = {
  setCurrLang: (state, data) => {
    state.currLang = data
    // setStorage('willdesk_currLang',data,'local')
  },
  setCurrLangFixedTranslate: (state, data) => {
    state.currLangFixedTranslate = data
  },
  setCurrLangCustomTranslate: (state, data) => {
    state.currLangCustomTranslate = data
  },
}

const action = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  action
}

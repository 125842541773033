<template>
  <div class="faqCard-wrapper">
    <div class="faq-card theme-border-color" v-if="faqData.state">
      <!-- <div class="card-title">
        {{ faqData.titleText }}
      </div> -->
      <div class="faq-search-input" @click="clickMore">
        <svg-icon class="search-icon" svg-name="search-icon" width="14px" height="14px" style="position:relative;top:1px;"></svg-icon>
        <input :placeholder="faqData.placeholder" type="text" />
      </div>
      <div class="faq-list" style="margin-top: 8px; font-size: 14px">
        <faq-item v-for="item in faqData.list" :key="item.id" :item-data="item"></faq-item>
        <a class="view-more theme-link-text-color" @click.prevent> View more > </a>
      </div>
    </div>
    <!-- <div class="faq-card theme-border-color" v-if="faqData.state">
      <div class="card-title">
        {{ faqData.categoryText || 'Categories' }}
        <a class="view-more theme-link-text-color" @click.prevent> View more > </a>
      </div>
      <div class="faq-list" style="margin-top: 8px; font-size: 14px">
        <category-item v-for="item in faqData.categoryList" :key="item.id" :item-data="item"></category-item>
      </div>
    </div> -->
  </div>

</template>

<script>
import FaqItem from './FaqItem'
// import CategoryItem from './CategoryItem'
export default {
  name: 'FaqCard',
  props: {
    faqData: Object
  },
  components: {
    FaqItem
    // CategoryItem
  },
  methods: {
    // 去向下一个地方
    goNext () {
      // window.location.href = 'http://test.com';
      console.log('FAQ卡片被点击了，这里还需要一些对应的逻辑')
    },
    setPage () {
      this.$store.commit('setPage', 'search-page')
    },
    clickMore () {

    }
  }
}
</script>

<style scoped>
  .faq-card {
    position: relative;
    height: auto;
    padding: 16px 0;
    background: #fff;
    box-shadow: 0px 4px 10px rgba(18, 17, 39, 0.1);
    border-radius: 8px;
    margin-bottom: 16px;
  }

  .card-title {
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    color: #292929;
    line-height: 22px;
    text-align: left;
    padding: 0 16px;
  }
  .faq-search-input {
    position: relative;
    height: 40px;
    margin: 0 16px;
    background: #FFFFFF;
    border-radius: 40px;
    border: 1px solid #d1d1db;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 12px;
    font-weight: 400;
  }

  .faq-search-input:hover,
  .faq-search-input:focus {
    outline: 2px solid var(--input-hover-color);
  }

  input {
    border: none;
    font-size: 14px;
    height: 22px;
    flex: 1;
    color: #6C6B80;
    line-height: 22px;
    margin-left: 4px !important;
  }

  input::placeholder {
    color: #6C6B80;
  }

  input:hover,
  input:focus {
    outline: none;
  }

  .card-welcome {
    text-align: left;
    font-weight: 700;
    color: #292929;
    margin-bottom: 10px;
  }

  .card-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
  }

  .view-more {
    display: block;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: #3b59fe;
    text-align: left;
    text-decoration: none;
    padding-left: 16px;
    cursor: pointer;
  }

  .search-input {
    height: 40px;
    background: #FFFFFF;
    border-radius: 30px;
    border: 1px solid #6C6B80;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 9px 12px;
    position: relative;
    font-size: 14px;
    margin: 0 16px;
  }

  input {
    border: none;
    font-size: 14px;
    height: 100%;
    width: 90%;
    margin-left: 4px;
  }

  input::placeholder {
    color: #6C6B80;
  }

  input:focus {
    outline: none;
  }

  .search-icon {
    cursor: pointer;
  }
</style>

<template>
  <div class="go_top" :style="{'background-color':color}" :class="isShow?'show_btn':isFirstLoad?'':'hide_btn'" @click="goTop">
    <svg-icon svg-name="select-up" width="15px" height="10px"></svg-icon>
  </div>
</template>

<script>
export default {
  name: 'GoTop',
  props: {
    // 是否显示返回顶部的按钮
    isShow: {
      type: Boolean,
      default: false
    },
    // 按钮的颜色
    color: {
      type: String,
      default: '#3b59fe'
    }
  },
  data () {
    return {
      // 是否是第一次加载  第一次加载组件不需要添加hideBtn类
      isFirstLoad: true
    }
  },

  methods: {
    // 点击返回顶部的回调
    goTop () {
      this.$emit('goTop')
    }
  }
}
</script>

<style scoped>
.go_top {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: -50px;
  right: 4px;
  cursor: pointer;
}

.show_btn {
  animation: showBtn 0.7s ease;
  animation-fill-mode: forwards;
}

.hide_btn {
  animation: hideBtn 0.3s ease-in;
  animation-fill-mode: forwards;
}

@keyframes showBtn {
  0% {
    bottom: -50px;
  }
  50% {
    bottom: 40px;
  }
  100% {
    bottom: 26px;
  }
}

@keyframes hideBtn {
  from {
    bottom: 26px;
  }
  to {
    bottom: -50px;
  }
}
</style>

<template>
  <div class="orderDetails">
    <!-- 导航栏部分 -->
    <nav-bar back-page="OrderList">
      <template #left>
        <svg-icon svg-name="left-arrow" width="20px" height="20px"></svg-icon>
      </template>
      <template #center>
        <div class="nav_bar_center_od">
          {{ navTitle }}
        </div>
      </template>
    </nav-bar>
    <!-- <loading v-if="searchLoading"/> -->
    <!-- 订单内容 -->
    <scroll-container :style-text="styleText">
      <div class="content">
        <!-- 订单信息 -->
        <div class="orderInfo">
          <!-- 订单状态 -->
          <div class="orderStatus">
            <div class="orderTitle">
              <p class="info-title-five">{{ orderText }} <span>{{ orderInfo.orderName }}</span></p>
              <div class="financialStatus">{{ trackStatus }}</div>
            </div>
            <p class="info-title-four">{{ orderInfo.createTime}}</p>
          </div>
          <div class="orderDetail">
            <p class="ship-text info-title-four">{{ shipToText }}</p>
            <div class="shipto info-title-five">{{ orderInfo.shipTo }}</div>
          </div>
          <AfterSalesBtn :orderInfo="chatOrderInfo" />
        </div>
        <!-- 物流信息 -->
        <div class="express" v-for="(item, index) in expressList" :key="index">
          <p v-if="expressList.length > 1" class="trackingNumber">{{ orderText }}{{ orderInfo.orderName }}-F{{ index+1 }}</p>
          <express-info :expressInfo='item' :shipTo='orderInfo.shipTo'></express-info>
        </div>

        <!-- 联系客服 -->
        <p class="getHelp" @click="toChat" v-if="chatState || leaveMessageState">{{ getHelpText }} ></p>
      </div>
      <!-- 订单未找到 -->
      <div class="notFound" v-show="showTips">

        <div>
          <svg-icon svg-name="orderNotFound" width="100px" height="98px"></svg-icon>
        </div>
        <p>{{ notFoundText }}</p>
        <div class="notFound-btn">
          <adaptive-button @btnClick="reSearch" btn-theme="blue" btn-height="34px" :btn-text="orderAgain" fontSize="14px" click-methods="search" click-arg="Track"></adaptive-button>
        </div>

      </div>
    </scroll-container>
    <div class="chat-with-us-wrapper" v-if="chatState">
      <div class="chat-with-title text-ellipsis">{{ needHelpText }}?</div>
      <div class="chat-with-btn theme-bg-color" @click="chatWithUs">
        <svg-icon svg-name="send" width="20px" height="20px"></svg-icon>
        <span class="chat-with-text text-ellipsis">{{ contactBtntext }}</span>
      </div>
    </div>

  </div>
</template>
<script>
import NavBar from 'components/contents/NavBar'
import ScrollContainer from 'components/contents/ScrollContainer'
// import ExpressInfo from './children/ExpressInfo'
import AdaptiveButton from 'components/contents/AdaptiveButton'
import AfterSalesBtn from 'components/contents/AfterSalesBtn'
// import { getOrderThird } from '@/api/trackingOrder'
// import Loading from '@/components/common/Loading/Loading'
// import axios from 'axios'
// 导入moment组件
import moment from 'moment'
import proImgError from '@/assets/img/proImgError.png'
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'OrderDetails',
  data () {
    return {
      proImgError,
      expressList: [],
      orderInfo: {
        // moneyFormat: '',
        shipTo: '',
        totalNumber: '',
        price: '',
        totalAmount: '',
        orderNumber: '',
        orderName: '',
        createTime: '',
        orderStatus: 'Order Ready',
        moneyFormat: '',
        financialStatus: '',
        orderItems: {}
      },
      chatOrderInfo:{},
      showTips: false,
      navText: 'Order details',
      notFound: 'Could not find any orders. Please try again.',
      totalNumber: 'Total items',
      totalAmount: 'Total amount',
      shipTo: 'Ship to',
      getHelp: 'Get help',
      order: 'Order:',
      backPage: 'OrderSearch',
      searchLoading: true,
      counts:0,
      tryAgain:'Try again'
    }
  },
  components: {
    NavBar,
    ScrollContainer,
    // ExpressInfo,
    AdaptiveButton,
    // Loading,
    AfterSalesBtn
  },
  computed: {
    ...mapGetters({
      getShopsetting: 'setting/getShopsetting',
      getCurrOrderInfo:'getCurrOrderInfo'
    }),
    scrollTop () {
      return '68px'
    },
    chatWithUsHeight(){
      return this.chatState ? '60px' : '0px'
    },
    iconWidth () {
      return this.$store.state.isMobile ? '10px' : '20px'
    },
    styleText () {
      // console.log(this.$store.state.boxPadding)
      // 移动端pc端
      return (`top:${this.scrollTop};padding:0; background:#F9F9FB; height:calc(100vh - ${this.scrollTop} - ${this.chatWithUsHeight})`)
    },
    navTitle () {
      return this.$store.state.languageState ? this.$store.state.language.trackOrder[9] : this.navText
    },
    notFoundText () {
      return this.$store.state.languageState ? this.$store.state.language.trackOrder[10] : this.notFound
    },
    totalNumberText () {
      return this.$store.state.languageState ? this.$store.state.language.trackOrder[11] : this.totalNumber
    },
    totalAmountText () {
      return this.$store.state.languageState ? this.$store.state.language.trackOrder[12] : this.totalAmount
    },
    shipToText () {
      return this.$store.state.languageState ? this.$store.state.language.trackOrder[13] : this.shipTo
    },
    getHelpText () {
      return this.$store.state.languageState ? this.$store.state.language.trackOrder[14] : this.getHelp
    },
    orderText () {
      return this.$store.state.languageState ? this.$store.state.language.trackOrder[15] : this.order
    },
    orderAgain () {
      return this.$store.state.languageState ? this.$store.state.language.trackOrder[26] : this.tryAgain
    },
    chatState () {
      return Boolean(Number(this.$store.state.shopSetting.WidgetStatus[0].EnableLiveChat))
    },
    leaveMessageState () {
      return Boolean(Number(this.$store.state.shopSetting.WidgetStatus[0].displayContactForm))
    },
    orderDetailImg () {
      return function (path) {
        return path !== '' ? path : require('@/assets/img/proImgError.png')
      }
    },
    trackStatus () {
      let status = ''
      if (this.expressList.length > 0 && this.expressList[0].deliveryStatus) {
        status = this.expressList[0].deliveryStatus
      } else {
        status = this.orderInfo.financialStatus
      }
      status = status.slice(0, 1).toUpperCase() + status.slice(1)
      return status
    },
    contactBtntext () {
      return 'Chat with us'
    },
    needHelpText(){
      return this.$store.state.language.chat[7]
    }
  },
  methods: {
    ...mapMutations({
      setPage:'setPage',
      setAutomationChat: 'automation/setAutomationChat'
    }),
    ...mapActions({
    }),
    // 获取订单详情
    initOrderDetail () {
      this.orderInfo.shipTo = this.getCurrOrderInfo.shipTo
      this.orderInfo.totalNumber = this.getCurrOrderInfo.totalNumber
      this.orderInfo.orderItems = JSON.parse(this.getCurrOrderInfo.orderItems)[0]
      this.orderInfo.price = this.orderInfo.orderItems.price
      this.orderInfo.totalAmount = '$' + this.getCurrOrderInfo.totalAmount
      this.orderInfo.orderNumber = this.getCurrOrderInfo.orderNumber
      this.orderInfo.orderName = this.getCurrOrderInfo.orderName
      this.orderInfo.financialStatus = this.getCurrOrderInfo.financialStatus
      this.orderInfo.createTime = moment(this.getCurrOrderInfo.createTime).format('MMM DD,YYYY')
      this.expressList = this.getCurrOrderInfo.tracking
      this.chatOrderInfo = {
        orderNumber: this.orderInfo.orderName,
        createDate: this.orderInfo.createTime,
        totalNumber: this.orderInfo.totalNumber,
        totalAmount: this.orderInfo.totalAmount,
        shipTo: this.orderInfo.shipTo,
        price: this.orderInfo.orderItems.price,
        financialStatus: this.getCurrOrderInfo.financialStatus,
        img_url: this.orderInfo.orderItems.img_url || this.proImgError,
        returnable:this.getCurrOrderInfo.returnable,
        cancelable:this.getCurrOrderInfo.cancelable,
      }
    },
    // 跳转至聊天页面
    toChat () {
      // if (this.chatState) {
      //   // 首先需要判断是否是登录状态
      //   this.$store.commit('setIsFromOrder', true)
      //   const info = {
      //     orderNumber: this.orderInfo.orderName,
      //     createDate: this.orderInfo.createTime,
      //     totalNumber: this.orderInfo.totalNumber,
      //     totalAmount: this.orderInfo.totalAmount,
      //     shipTo: this.orderInfo.shipTo,
      //     price: this.orderInfo.orderItems.price,
      //     financialStatus: this.trackStatus,
      //     img_url: this.orderInfo.orderItems.img_url || this.proImgError
      //   }
      //   console.log('info:', info)
      //   this.$store.commit('setOrderInfo', info)
      //   this.setPage('login')
      // } else if (this.leaveMessageState) {
      //   this.setPage('leave-message')
      // }
      this.setAutomationChat({
        userName: 'Ai-Bot',
        content:{
          orderNumber:this.chatOrderInfo.orderNumber,
          financialStatus:this.chatOrderInfo.financialStatus,
          img_url:this.chatOrderInfo.img_url,
          totalNumber:this.chatOrderInfo.totalNumber,
          totalAmount:this.chatOrderInfo.totalAmount
        },
        type: 'Customer',
        messageType: 'order',
        profile: ''
      })
      this.setPage('chat')
    },
    // 再次发送搜索请求
    reSearch () {
      this.searchLoading = true
      // 先判断输入的内容是否有误
      this.initOrderDetail()
    },
    chatWithUs(){
      this.setPage('chat')
    }
  },
  created () {
    // 查询订单
    this.initOrderDetail()
  },
  beforeDestroy(){
    this.counts = 15
  }
}
</script>
<style scoped lang="less">
  .content {
    padding: 16px;
  }

  .orderStatus {
    border-bottom: 1px solid #E5E5EB;
    padding: 16px;
  }

  .orderDetails{
    height: 100%;
    padding-bottom: 58px;
  }
  .orderTitle {
    display: flex;
    justify-content: space-between;
  }
  .track-spin{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    /deep/ .ant-spin-text{
      color: var(--primary-color);
    }
    /deep/ .ant-spin-dot-item{
      background-color: var(--primary-color);
    }
  }

  .orderTitle p,
  .orderStatus p,
  .orderDetail p {
    margin: 0;
  }
  .info-title-four{
    text-align: left;
    color: #6C6B80;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    margin-top: 4px;
  }
  .info-title-five{
    max-width: 49%;
    overflow: hidden;
    word-wrap: break-word;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #000;
  }

  .orderInfo {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(18, 17, 39, 0.1);
    border-radius: 8px;
  }

  .orderDetail_new {
    display: flex;
    padding: 20px 16px;
  }

  .orderImg {
    width: 52px;
    height: 52px;
    object-fit: cover;
    border-radius: 4px;
    overflow: hidden;
  }

  .orderBody {
    display: flex;
    flex-direction: column;

  }
  .orderBtns{
    padding: 0 16px 16px 16px;
  }

  .orderDetail {
    padding: 16px;
  }
  .orderDetail .ship-text{
    text-align: left;
  }
  .orderDetail .shipto{
    max-width: 100%;
    text-align: left;
  }

  .orderDetail .title {
    /* C端常规显示12px */
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: #6C6B80;
  }

  .orderDetail span {
    display: inline-block;
    margin-top: 4px;
    color: #000;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }

  .orderDetail .orderImg {
    width: 48px;
    height: 48px;
    border-radius: 4px;
    overflow: hidden;
  }

  .orderDetail .orderImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .orderDetail .totalItem {
    margin-left: 36px;
  }

  .orderDetail .totalAmount {
    margin-left: 48px;
  }

  .infoLeft {
    text-align: left;
    min-width: 135px;
    font-size: 14px;
    font-weight: 400;
    color: #727272;
    line-height: 20px;
  }

  .infoRight {
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    color: #292929;
    line-height: 20px;
  }

  .getHelp {
    font-size: 14px;
    font-weight: bold;
    color: var(--primary-color);
    line-height: 20px;
    margin-top: 16px;
    cursor: pointer;
  }

  .notFound {

    width: 100%;
    height: calc(100vh - 74px);
    padding: 143px 76px 0;
    color: #000;
  }

  .notFound P {
    margin-top: 8px;
    margin-bottom: 0;
  }

  .notFound-btn {
    margin: 16px auto 0;
  }

  .notFound-btn button {
    border: none;
    outline: none;

    text-align: center;
    color: #fff;
    line-height: 22px;

    padding: 6px 20px;
    background: #2C23E5;
    border-radius: 36px;
    cursor: pointer;
    transition: all .3s;

  }

  .trackingNumber {
    font-size: 14px;
    font-weight: bold;
    color: #292929;
    line-height: 14px;
    text-align: left;
    margin-left: 10px;
    margin-top: 16px;
  }

  .financialStatus {
    max-width: 49%;
    overflow: hidden;
    word-wrap: break-word;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: #6C6B80;
  }

  .nav_bar_center_od {
    margin-left: 24px;
  }
  .chat-with-us-wrapper{
    background-color: #E0DFFA;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    padding: 10px 16px;
    justify-content: space-between;
    align-items: center;
    .chat-with-title{
      font-size: 14px;
      flex: 0.5;
      line-height: 22px;
      font-weight: 700;
      color: var(--primary-color);
    }
    .chat-with-btn{
      flex: 0.4;
      background-color: #2C23E5;
      border-radius: 40px;
      display: flex;
      overflow: hidden;
      align-items: center;
      cursor: pointer;
      padding: 8px 24px;
      .chat-with-text{
        flex: 1;
        font-size: 14px;
        line-height: 22px;
        margin-left: 5px;
        color: #fff;
      }
    }
  }
</style>

<template>
  <scroll-box class="textarea-container" data-simplebar-auto-hide="false" ref="scrollBox">
    <!-- 文本框 -->
    <div :contenteditable="true" @keydown="textareaKeydown" @keyup="textareaKeyup" @input="inputing" @paste="pasteContent" @focus="focusTextarea" @blur="blurTextarea" @click.stop="getLastRange" class="input-textarea" :class="placeholderShow ? 'show-placeholder' : ''" :data-placeholder="dataPlaceholder"></div>
  </scroll-box>
</template>

<script>
// import ResizeObserver from "resize-observer-polyfill";
import ScrollBox from './ScrollBox'
// import { readAndUploadFile } from '@/utils/uploadFile.js'

// ResizeObserver 的实例，用于监听 输入框inputTextArea的高度变化
// let ro = null;
// 用于记录光标事件
const selection = getSelection()
// 光标最后的位置
let lastEditRange = null
// DOM元素 (DOM需要在mounted中获取赋值)
let textarea = null

export default {
  name: 'DivTextarea',
  components: {
    ScrollBox
  },
  props: {
    // 输入内容
    inputContent: {
      type: String,
      default: ''
    },
    // 行高
    lineHeight: {
      type: Number,
      default: 22
    },
    // 最大高度
    maxHeight: {
      type: Number,
      default: 158
    },
    // 输入框的打开状态
    inputState: {
      type: Boolean,
      default: false
    },
    // 输入框Placeholder
    dataPlaceholder: {
      type: String,
      default: 'Please describe your question in detail'
    }
  },
  data () {
    return {
      // 是否显示placeholder
      placeholderShow: true
    }
  },
  /* computed: {
          // 根据pc和移动端 显示不同左侧边距
          styleText() {
            return "margin: 17px 0 17px " + this.$store.state.boxPadding;
          },
        }, */
  mounted () {
    textarea = document.querySelector('.input-textarea')
    // 与后台的差异 不这样会到导致光标错位 以及文本节点拆分问题
    // textarea.focus();
    // this.focusFunc();
  },
  methods: {
    // 外部调用的api
    // 插入表情的方法
    insertEmoji (emoji) {
      if (!lastEditRange) textarea.focus()
      // 还原光标位置
      this.reInsertRange(lastEditRange)
      // 插入表情
      this.insertFunc(emoji)
      // 执行输入完成执行的回调
      this.completeInput()
    },

    // 聚焦输入框的方法
    focusFunc () {
      if (lastEditRange) {
        // 如果有上一次光标的位置，还原光标位置
        this.reInsertRange(lastEditRange)
      } else {
        // 如果没有上一次光标的位置，则记录当前光标的位置
        this.getLastRange()
      }
    },
    // 事件监听钩子
    // 监听输入事件
    inputing (eve) {
      const e = eve || window.event

      // 如果输入内容只剩 \n了  则直接清空
      if (e.currentTarget.innerText === '\n') {
        e.currentTarget.innerText = ''
      }

      // 输入完成执行的回调
      this.completeInput()
    },

    // 监听按键操作
    textareaKeydown (eve) {
      const e = eve || window.event
      // ctrl + enter
      if (
        (e.ctrlKey && e.keyCode === 13) ||
          (e.shiftKey && e.ctrlKey && e.keyCode === 13)
      ) {
        // 换行的回调
        this.changeLine()
        // enter
      } else if (e.keyCode === 13) {
        e.preventDefault() // 阻止pc浏览器默认换行操作
        if (!this.$store.state.isMobile) {
          // 执行发送消息逻辑
          this.$emit('sendMessage')
        } else {
          this.changeLine()
        }
        return false
      }
    },

    // 监听输入框keyup
    textareaKeyup (eve) {
      const e = eve || window.event
      const kc = e.keyCode
      // 判断方向键 并记录光标位置
      if (kc >= 37 && kc <= 40) {
        // 记录鼠标位置
        this.getLastRange()
      }
    },

    // 聚焦输入框后需要执行的操作
    focusTextarea () {
      // 如果输入框已经打开 直接return
      // 将参数传递给父组件，执行相应的逻辑操作
      this.$emit('focusEvent', true)
      // console.log('聚焦了message框')
      if (this.inputState) return false
      // 修改父组件的inputState状态 (与本组件无关)
      // this.$emit("update:inputState", true);
    },
    blurTextarea () {
      this.$emit('focusEvent', false)
      // console.log('message失去焦点')
    },

    // 输入框高度发生变化执行的回调
    /* textareaHeightChange(entries) {
            // 拿到监听的高度变化的对象
            let target = entries[0].target;

            // 通知父组件修改样式 (与本组件无关)
            // this.$emit("setTextareaStyle", target);
          }, */

    // 粘贴事件的回调
    pasteContent (e) {
      e.preventDefault()

      // const file = e.clipboardData.files && e.clipboardData.files[0]

      // readAndUploadFile(file)

      this.textFormat(e)
    },

    // 输入完成后的回调
    completeInput () {
      // 将数据保存至inputContent中
      // 这样传输具有一定延迟，所以下面的输入内容长度获取用textarea.innerText 而不是this.inputContent
      this.$emit('update:inputContent', textarea.innerText)
      // 判断是否显示输入框的 placeholder
      this.getPlaceholderShowState()
      // 记录光标的位置
      this.getLastRange()

      this.removeBr()
    },

    // 换行的回调
    changeLine () {
      // 内容为空时禁止换行
      if (textarea.innerText.length === 0) return

      // 保持尾部有一个 \n 以能够正常换行
      this.keeptail(textarea)
      // 插入换行符并重新定位光标位置
      this.insertTextNode('\n', 1)
      // 判断输入框高度，如果大于160px，每次换行就自动滚动20px
      if (textarea.scrollHeight > this.maxHeight) {
        // 回车时不会自动滚动输入框 这里需要调用scrollTextarea滚动输入框
        const scrollBox = this.$refs.scrollBox

        scrollBox.scrollToFunc('ScrollBox', 'auto', this.lineHeight)
      }
    },

    // 方法
    // 判断是否显示输入框中的placeholder
    getPlaceholderShowState () {
      // 如果内容不为空且当前展示状态为true 内容为空且当前展示状态为false
      this.placeholderShow = textarea.innerText.length === 0
    },

    // 重新插入光标
    reInsertRange (range) {
      if (!lastEditRange) return
      // 光标开始和光标结束重叠
      range.collapse(true)
      // 清除选定对象的所有光标对象
      selection.removeAllRanges()
      // 插入新的光标对象
      selection.addRange(range)
    },

    // 记录光标的位置
    getLastRange () {
      lastEditRange = selection.getRangeAt(0)
    },

    // 保持输入框以 \n结尾
    keeptail (dom) {
      if (!/\n$/.test(dom.innerHTML)) dom.firstChild.appendData('\n')
    },

    // 如果存在br就去除br 只会在末尾产生 br, 所以在删除br后 再将光标移至末尾
    removeBr () {
      if (/<br>/g.test(textarea.innerHTML)) {
        textarea.innerHTML = textarea.innerHTML.replace(/<br>/g, '\n')
        var range = window.getSelection() // 创建range
        range.selectAllChildren(textarea) // range 选择obj下所有子内容
        range.collapseToEnd() // 光标移至最后
      }
    },

    // 去除内容样式
    textFormat (e) {
      var text
      var clp = (e.originalEvent || e).clipboardData
      if (clp === undefined || clp === null) {
        text = window.clipboardData.getData('text') || ''
        if (text !== '') {
          if (window.getSelection) {
            var newNode = document.createElement('span')
            newNode.innerHTML = text.slice
            window.getSelection().getRangeAt(0).insertNode(newNode)
          } else {
            document.selection.createRange().pasteHTML(text)
          }
        }
      } else {
        text = clp.getData('text/plain') || ''
        if (text !== '') {
          document.execCommand('insertText', false, text)
        }
      }
    },

    // 插入至节点方法
    insertFunc (node) {
      // console.log(selection);
      // 判断选定对象范围是编辑框还是文本节点
      if (selection.anchorNode.nodeName !== '#text') {
        const nodeText = document.createTextNode(node)
        this.insertBlankLine(nodeText)
      } else {
        // 插入至文本节点
        this.insertTextNode(node)
      }
    },

    // 插入至文本节点
    insertTextNode (insertNode, length) {
      // 如果是文本节点则先获取光标对象
      const range = selection.getRangeAt(0)
      // 获取光标对象的范围界定对象，一般就是textNode对象
      const textNode = range.startContainer
      // 获取光标位置
      const rangeStartOffset = range.startOffset
      // 文本节点在光标位置处插入新的表情内容
      textNode.insertData(rangeStartOffset, insertNode)
      // 光标移动到到原来的位置加上新内容的长度
      range.setStart(
        textNode,
        rangeStartOffset + (length || insertNode.length)
      )
      // 重新插入光标
      this.reInsertRange(range)
    },

    // 插入至空行（非文本节点）
    insertBlankLine (insertNode) {
      // console.log('................1');
      // 如果是编辑框范围。则创建表情文本节点进行插入
      textarea.appendChild(insertNode)
      // 创建新的光标对象
      const range = document.createRange()
      // 光标对象的范围界定为新建的表情节点
      range.selectNodeContents(insertNode)
      // 光标位置定位在表情节点的最大长度
      range.setStart(insertNode, insertNode.length)
      // 重新插入光标
      this.reInsertRange(range)
      // FIXME 第一次插入表情时 将光标移到最后
      /* range = window.getSelection(); //创建range
            range.selectAllChildren(textarea); //range 选择obj下所有子内容
            range.collapseToEnd(); //光标移至最后 */
    },

    // 删除输入框最后一个字符
    removeLastStr () {
      // 如果是文本节点则先获取光标对象
      /* let range = selection.getRangeAt(0);
            // 获取光标对象的范围界定对象，一般就是textNode对象
            let textNode = range.startContainer;
            console.log(textNode);
            // 获取光标位置
            let rangeStartOffset = range.startOffset;
            console.log(rangeStartOffset);
            // console.log(textNode);

            console.log(textNode.length);
            // 文本节点在光标位置处删除内容
            // textNode.insertData(rangeStartOffset, document.createTextNode('dddd'));
            textNode.deleteData(rangeStartOffset - 2, 2); */

      // console.log(textarea.innerText.split(''));

      // let arr = textarea.innerText.split('');
      // arr.pop();

      // textarea.innerText = arr.join('');

      // 重新插入光标
      this.reInsertRange(lastEditRange)
      // 输入完成的回调
      this.completeInput()
    },
    // 清空输入框内容
    clearTextarea () {
      textarea.innerText = ''
      this.completeInput()
    },
    // 聚焦方法
    focusInput () {
      textarea.focus()
    }
  }
}
</script>

<style scoped>
  .textarea-container {
    width: 100%;
    max-height: 107px;
    min-height: 56px;
    text-align: left;
  }

  .input-textarea {
    padding: 0;
    margin-right: 12px;
    resize: none;
    font-weight: 400;
    color: #292929;
    line-height: 20px;
    word-break: break-all;
    white-space: pre-wrap;
    overflow: hidden;
    font-size: 14px;
    -webkit-user-select: text;
  }

  .show-placeholder::before {
    /* content: "Please describe your question in detail"; */
    content: attr(data-placeholder);
    color: #b4b4b4;
    cursor: text;
    font-size: 14px;
    font-weight: 400;
  }

  .input-textarea:focus {
    outline: none;
  }
</style>

<template>
  <div class="help_center_list" :class="$store.state.isMobile?'helpcenter_list_mobile':''">
    <div class="category_title">{{ category.CategoryName }}</div>
    <search-result-item class="search_result_item" v-for="item in category.Article" :key="item.ArticleId" :article="item"></search-result-item>
  </div>
</template>

<script>
import SearchResultItem from './SearchResultItem'

export default {
  components: { SearchResultItem },
  name: 'HelpCenterList',
  props: {
    category: {
      type: Object,
      default () {
        return {
          CategoryName: '',
          Article: []
        }
      },
      require: true
    }
  },
  data () {
    return {}
  }
}
</script>

<style scoped>
  .help_center_list {
  }

  .category_title {
    margin: 26px 26px 8px;
    padding-bottom: 12px;
    font-size: 16px;
    font-weight: 600;
    color: #292929;
    line-height: 24px;
    border-bottom: 1px solid #E7E7E7;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .helpcenter_list_mobile {
    /* font-family: PingFangSC-Regular, PingFang SC; */
  }

  .helpcenter_list_mobile .category_title {
    border-bottom: none;
    font-size: 16px;
    font-weight: 600;
    color: #292929;
    line-height: 19px;
    margin: 32px 0 5px 16px;
    padding-bottom: 0;
  }
</style>

// 助手函数大杂烩
// import { encrypt, decrypt } from './aes'

// 判断是否为移动端 true 是 / false 否
export function isMobile() {
  return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
}

// 格式化时间函数
/**
 * @param Number timestamp 时间戳 毫秒级别
 * @param String format 返回格式 默认YYYY-MM-DD HH:mm:ss 可自定义格式传入  年份如果传入两位YY表示返回年份最后两个尾数
 * 如果是当天时间 直接返回当地24小时制时间 如12:03 或 13:06
 * 如果已经是不是当天 返回format传入的格式
 */
export function formatTime(timestamp, format) {
  var res = format === undefined ? 'YYYY-MM-DD HH:mm' : format
  var target = new Date(Number(timestamp))
  var hours = target.getHours() < 10 ? '0' + target.getHours() : String(target.getHours())
  var minutes = target.getMinutes() < 10 ? '0' + target.getMinutes() : String(target.getMinutes())
  if (target.toDateString() === new Date().toDateString()) return hours + ':' + minutes

  var year = target.getFullYear().toString()
  var month = target.getMonth() + 1
  month = month < 10 ? '0' + month : String(month)
  var date = target.getDate() < 10 ? '0' + target.getDate() : String(target.getDate())

  var seconds = target.getSeconds() < 10 ? '0' + target.getSeconds() : String(target.getSeconds())

  // 判断年份有几位 只有两位就只给后面两位
  var yearLen = res.replace(/[^Y]/g, '').length
  year = yearLen === 2 ? year.substr(2) : year

  // return res.replace(/[y]+/ig,year).replace(/[M]+/g,month).replace(/[d]+/ig,date).replace(/[h]+/ig,hours).replace(/[m]+/g,minutes).replace(/[s]+/ig,seconds);
  // TAG 更换foreach循环更加易读

  // 临时组合为key value
  var temp = {
    Y: year,
    M: month,
    D: date,
    H: hours,
    m: minutes,
    s: seconds
  }

  var reg
  for (var key in temp) {
    reg = new RegExp('[' + key + ']+', 'g')
    // 替换
    res = res.replace(reg, String(temp[key]))
  }

  return res
}

// 传入用户名，生成头像数据
export function createAvatar(userName) {
  const avatarName = createName(userName)
  const avatarColor = createColor()
  return {
    name: avatarName,
    color: avatarColor
  }
}

// 生成头像文字
function createName(userName) {
  if (userName === undefined) return
  // 匹配除了 英文和空格 之外的字符
  const reg0 = /[^a-z|A-Z|\s]/g
  const arr0 = userName.match(reg0) || []
  // length为0,匹配不到其他字符,就是纯英文
  if (arr0.length === 0) {
    // 提取首字母或者 空格后的第一个字母
    // let reg1 = /^\w|(?<=\s)\w/g;
    // let arr1 = userName.match(reg1) || [];
    // if (arr1.length >= 2) {
    //   // 有匹配到空格
    //   return (arr1[0] + arr1[1]).toUpperCase();
    // }
    if (userName.split(' ').length > 2) {
      return (userName.substr(0, 1) + userName.split(' ')[1].substr(0, 1)).toUpperCase()
    } else {
      // 无匹配到空格
      return userName.length > 1 ? userName.substr(0, 2).toUpperCase() : userName.substr(0, 1).toUpperCase()
    }
  } else {
    // 不是纯英文 ,获取第一个开头的字符
    const reg2 = /^./g
    const arr2 = userName.match(reg2) || []
    if (arr2.length !== 0) {
      // 非换行符开头
      return arr2[0]
    } else {
      return 'not'
    }
  }
}

// 生成头像颜色
export function createColor(name) {
  const colorArr = [
    '#FF9393',
    '#FFC179',
    '#EFE26F',
    '#92D091',
    '#80DECD',
    '#89B9E4',
    '#8B87F1'
  ]
  // const randomNum = Math.round(Math.random() * (colorArr.length - 1))
  // return colorArr[randomNum]
  return colorArr[name.charCodeAt(0) % 7]
}

// 防抖
export function debounce(callback, delay = 500) {
  let timer
  return function() {
    clearTimeout(timer)
    timer = setTimeout(() => {
      callback()
    }, delay)
  }
}

// 节流
export function throttle(delay = 0) {
  let timer = null
  return function(callback) {
    if (timer) {
      return
    }
    timer = setTimeout(() => {
      timer = null
    }, delay)
    callback()
  }
}

// localStorage存入
export function setCache(data) {
  // 如果不支持localStorage则返回false
  if (typeof localStorage === 'undefined') return false
  // const enc = encrypt(JSON.stringify(data), 'cQLds/1UHorN40reYgi6ofy77lV3b0zz', 'uTV9m9BwGdJnLEq9')
  // 如果支持 则存入对应信息
  localStorage.setItem('__helpdesk_user_data', data)
  return true
}

// localStorage获取
export function getCache() {
  // 如果不支持localStorage则返回false
  if (typeof localStorage === 'undefined') return false
  // const dec = decrypt(localStorage.getItem('__helpdesk_user_data') ? localStorage.getItem('__helpdesk_user_data') : '', 'cQLds/1UHorN40reYgi6ofy77lV3b0zz', 'uTV9m9BwGdJnLEq9')
  let data = localStorage.getItem('__helpdesk_user_data')
  // 如果支持 则返回对应信息
  return data ? data : null
}

// localStorage删除
export function removeCache() {
  localStorage.removeItem('__helpdesk_user_data')
  return true
}

// hex颜色转rgb颜色
export function hexToRgb(str) {
  var r = /^#?[0-9A-F]{6}$/
  // test方法检查在字符串中是否存在一个模式，如果存在则返回true，否则返回false
  if (!r.test(str)) throw new Error('输入错误的hex')
  // replace替换查找的到的字符串
  str = str.replace('#', '')
  // match得到查询数组
  var hxs = str.match(/../g)
  // alert('bf:'+hxs)
  for (var i = 0; i < 3; i++) hxs[i] = parseInt(hxs[i], 16)
  // alert(parseInt(80, 16))
  // console.log(hxs);
  return hxs
}

// Rgb颜色转Hex颜色
export function rgbToHex(a, b, c) {
  var r = /^\d{1,3}$/
  if (!r.test(a) || !r.test(b) || !r.test(c)) throw new Error('输入错误的rgb颜色值')
  var hex = [a.toString(16), b.toString(16), c.toString(16)]
  for (var i = 0; i < 3; i++)
    if (hex[i].length === 1) hex[i] = '0' + hex[i]
  return '#' + hex.join('')
}

// 得到hex颜色值为color的加深颜色值，level为加深的程度，限0-1之间
export function getDarkColor(color, level) {
  var r = /^#?[0-9A-F]{6}$/
  if (!r.test(color)) throw new Error('输入错误的hex颜色值')
  var rgb = hexToRgb(color)
  // floor 向下取整
  for (var i = 0; i < 3; i++) rgb[i] = Math.floor(rgb[i] * (1 - level))
  return rgbToHex(rgb[0], rgb[1], rgb[2])
}

// 文本转图片
export function textToImg(name, size = 36, fontsize = 16, color) {
  if (name.length === 0) return
  name = createName(name)
  color = color || createColor(name)
  const canvas = document.createElement('canvas')
  // 图像大小
  canvas.width = size
  canvas.height = size
  const context = canvas.getContext('2d')
  // 图像背景颜色
  context.fillStyle = color
  context.fillRect(0, 0, canvas.width, canvas.height)
  // 字体大小
  context.font = fontsize + "px 'Inter'"
  context.textAlign = 'center'
  // 字体颜色
  context.fillStyle = '#FFF'
  // 绘制位置
  context.fillText(name, size / 2, size / 1.5)
  // 显示图像
  return canvas.toDataURL('image/png')
}

// 处理表情的方法
// 获取img中的alt属性
export function getImgAlt(content) {
  let res = null
  var regex = /(.*?)<img.*?alt="(.*?)".*?>/ig

  res = content.replace(regex, '$1$2').replace(/amp;/gi, '')
  return res
}

// 将标签的显示结果转为实体名称
export function transformTag(content) {
  return content.replace(/</gi, '&lt;').replace(/>/gi, '&gt;')
}

// 通过[&index]解析对应的表情
export function getEmojiByAlt(content) {
  // safari不支持断言
  // let reg = /(?<=\[&).*?(?=\])/gi;
  const reg = /\[&(\d+)\]/gi

  return content.replace(reg, (res, $1) => {
    const emoji = `<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUAAACnej3aAAAAAXRSTlMAQObYZgAAAApJREFUCNdjYAAAAAIAAeIhvDMAAAAASUVORK5CYII="  style="background:url(${require('@/assets/emoji/emoji.png')}) 0 0 no-repeat; width:22px; height:22px; background-position:0 ${-22 * $1}px; margin:0 2px; vertical-align: middle;" alt="[&${$1}]">`
    return emoji
  })
}

// 根据src获取size大小
export function getImgSizeFromSrc(src) {
  const reg = /size=(\w+)&*/

  const res = reg.exec(src)
  if (res === null) return false

  const arr = res[1].split('x')
  return { width: arr[0] * 1, height: arr[1] * 1 }
}

// 计算占位图的宽高
export function calcImgSize(width, height, maxWidth = 200, minWidth = 50, maxHeight = 200, minHeight = 50) {
  if (width > maxWidth) {
    height *= maxWidth / width
    width = maxWidth
  }

  if (height > maxHeight) {
    width *= maxHeight / height
    height = maxHeight
  }

  if (width < minWidth) {
    height *= minWidth / width
    width = minWidth
    height = height > maxHeight ? maxHeight : height
  }

  if (height < minHeight) {
    width *= minHeight / height
    height = minHeight
    width = width > maxWidth ? maxWidth : width
  }

  // return `?size=${Math.ceil(width)}x${Math.ceil(height)}`
  return { width: Math.ceil(width), height: Math.ceil(height) }
}

// 从一个给定的数组arr中,随机返回num个不重复项
export function getArrayItems(arr, num) {
  var temp = []
  for (var index in arr) {
    temp.push(arr[index])
  }
  // 取出的数值项,保存在此数组
  var end = []
  for (var i = 0; i < num; i++) {
    // 判断如果数组还有可以取出的元素,以防下标越界
    if (temp.length > 0) {
      // 在数组中产生一个随机索引
      var arrIndex = Math.floor(Math.random() * temp.length)
      // 将此随机索引的对应的数组元素值复制出来
      end[i] = temp[arrIndex]
      // 然后删掉此索引的数组元素,这时候temp变为新的数组
      temp.splice(arrIndex, 1)
    } else {
      // 数组中数据项取完后,退出循环,比如数组本来只有10项,但要求取出20项.
      break
    }
  }
  return end
}

// 随机打乱数组元素
export function shuffle(a) {
  var len = a.length
  for (var i = 0; i < len; i++) {
    var end = len - 1
    var index = (Math.random() * (end + 1)) >> 0
    var t = a[end]
    a[end] = a[index]
    a[index] = t
  }
  return a
}

// 识别超链接的方法
export function idLink(content) {
  const reg = /(http|ftp|https):\/\/[\w]+(.[\w]+)([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])/g

  return content.replace(reg, (res) => {
    return `<a href="${res}" target="_blank">${res}</a>`
  })
}
export function getcolorRgba(sColor, opacity) {
  // 十六进制颜色值的正则表达式
  var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
  // 如果是16进制颜色
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      var sColorNew = '#'
      for (var i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
      }
      sColor = sColorNew
    }
    // 处理六位的颜色值
    var sColorChange = []
    for (var j = 1; j < 7; j += 2) {
      sColorChange.push(parseInt('0x' + sColor.slice(j, j + 2)))
    }
    return 'rgba(' + sColorChange.join(',') + ', ' + opacity + ')'
  }
  return sColor
}

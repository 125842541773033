<template>
  <div class="search_result_item" :class="$store.state.isMobile?'mobile_search_result_item':''">
    <div class="article_title_container">
      <div class="article_title" @click="switchDetail=!switchDetail">
        <span>{{ article.ArticleTitle }}</span>
        <span class="arrow_container">
          <svg-icon v-if="!$store.state.isMobile" class="arrow" :class="switchDetail?'rotate':''" svg-name="select-down" width="14px" height="8px"></svg-icon>
          <svg-icon v-else class="arrow" :class="switchDetail?'rotate':''" svg-name="select-down" width="9px" height="5px"></svg-icon>
        </span>
      </div>
    </div>
    <div class="article_detail">
      <div class="article_content_container" style="height:0;">
        <!-- <div class="loading_container" v-if="!article.ArticleContent"><img src="@/assets/img/loading.png" alt=""></div> -->
        <div class="loading_container" v-if="!article.ArticleContent"><img :src="loadingImg" alt=""></div>
        <div class="article_content" v-html="article.ArticleContent"></div>
        <article-comment class="article_comment" @comment="getContainerHeight" :articleId="article.ArticleId * 1"></article-comment>
      </div>
    </div>
  </div>
</template>

<script>
import ArticleComment from './ArticleComment.vue'

import { postFrameMessage } from '@/utils/iframeMessage'

export default {
  components: { ArticleComment },
  name: 'SearchResultItem',
  props: {
    article: {
      type: Object
    }
  },
  data () {
    return {
      // 展示文章详情的开关
      switchDetail: false,
      loadingImg: `${process.env.VUE_APP_STATIC_URL}/img/loading.png`
    }
  },
  beforeDestroy () {
    this.$bus.$off('searchPageResize')
  },
  created () {
    this.switchDetail = true
  },
  methods: {
    // 图片加载完成的回调  需要在图片加载完成后重新获取高度
    getContainerHeight () {
      // console.log('resize')
      const contentContainer = this.$el.querySelector('.article_content_container')
      const content = this.$el.querySelector('.article_content')
      const comment = this.$el.querySelector('.article_comment')
      this.$nextTick(() => {
        if (this.switchDetail) { contentContainer.style = `height:${content.offsetHeight + comment.offsetHeight}px;` }
      })
    },
    // 查看图片
    viewImg (e) {
      postFrameMessage({
        type: 'img',
        data: {
          src: e.target.currentSrc,
          file: null
        }
      }, document.referrer.substr(0, document.referrer.length - 1))
    }
  },
  watch: {
    // 切换展示item的回调
    switchDetail (current) {
      const contentContainer = this.$el.querySelector('.article_content_container')
      const content = this.$el.querySelector('.article_content')
      const comment = this.$el.querySelector('.article_comment')

      if (current) {
        // 展示文章详情
        if (!this.article.ArticleContent) {
          // 如果没有文章内容，在请求前先获取高度，以展示loading态
          contentContainer.style = `height:${this.$el.querySelector('.loading_container').offsetHeight}px;`
        } else {
          // 已经拿到了文章的内容
          contentContainer.style = `height:${content.offsetHeight + comment.offsetHeight}px;`
        }

        // 打开状态下监听resize事件
        this.$bus.$on('searchPageResize', this.getContainerHeight)
      } else {
        contentContainer.style = 'height:0;'
        this.$bus.$off('searchPageResize')
      }
    }
  }
}
</script>

<style scoped>
  .search_result_item {
  }

  .article_title_container {
    padding: 0px 26px;
  }

  .article_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    font-size: 14px;
    color: #000;
    line-height: 20px;
    cursor: pointer;
  }

  .article_title span {
    word-break: break-word;
  }

  .article_title_container:hover,
  .article_title_container:focus {
    background-color: var(--backgroundHover);
  }

  .arrow_container {
    width: 14px;
    margin-left: 20px;
  }

  .arrow {
    transition: 0.3s transform ease;
    color: #727272 !important;
  }

  .article_detail {
    background-color: #f8f8f8;
  }

  .article_content {
    padding: 12px 26px 14px;
    user-select: text;
    list-style-position: inside;
    word-wrap: break-word;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }

  .article_comment {
    padding: 12px 26px;
  }

  .rotate {
    transform: rotate(-180deg);
  }

  .article_content_container {
    overflow: hidden;
    transition: 0.3s height ease;
  }

  .article_content /deep/ p {
    color: #000;
  }

  .article_content /deep/ img {
    cursor: pointer;
    height: unset;
    width: unset;
  }

  .loading_container {
    animation: 2s loading linear infinite;
    text-align: center;
    padding: 10px;
  }

  @keyframes loading {
    0% {
      transform: scale(0.4) rotate(0deg);
    }

    100% {
      transform: scale(0.4) rotate(360deg);
    }
  }

  .mobile_search_result_item {
    /* font-family: PingFangSC-Regular, PingFang SC; */
  }

  .mobile_search_result_item .article_title_container:hover {
    background-color: unset;
  }

  .mobile_search_result_item .article_title_container {
    padding: 0;
    padding-right: 16px;
  }

  .mobile_search_result_item .article_title {
    border-bottom: 1px solid #D0D0D0;
    font-size: 13px;
    /* font-family: PingFangSC-Semibold, PingFang SC; */
    font-weight: 600;
    color: #292929;
    line-height: 16px;
    margin-left: 16px;
    margin-top: 16px;
    padding: 0;
    padding-bottom: 16px;
  }

  .mobile_search_result_item .article_content {
    padding: 16px;
  }

  .mobile_search_result_item .article_comment {
    padding: 0;
    padding-bottom: 16px;
  }

  ::v-deep .blockquotebox {
    display: block;
    border-left: 8px solid #d0e5f2;
    padding: 5px 10px;
    margin: 10px 0;
    line-height: 1.4;
    font-size: 100%;
    background-color: #f1f1f1;
  }
</style>

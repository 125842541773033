<template>
  <div class="search_input" :class="$store.state.isMobile?'search_input_mobile':''">
    <input v-model="searchContent" maxlength="300" @keydown.enter="search" :placeholder="placeholderText" type="text" @focus="addFoucsClass" @blur="removeFocusClass">
    <svg-icon class="search_icon" svg-name="search-icon" width="16px" height="16px" @click.native="search"></svg-icon>
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  data () {
    return {
      // 搜索的内容
      searchContent: ''
    }
  },
  computed: {
    placeholderText () {
      return this.textSetting.article_search || 'Search for support'
      // this.$store.state.shopSelfText.articleSearch
    },
    textSetting () {
      return JSON.parse(this.$store.state.shopSetting.liveChat['0'])['text-settings']
    }
  },
  methods: {
    // 添加focus的hover态
    addFoucsClass (e) {
      const parent = e.target.parentNode
      const theme = this.$store.state.theme
      parent.style.outline = this.hexToRgba(theme[0], 0.5)
    },
    // 删除focus的hover态
    removeFocusClass (e) {
      const parent = e.target.parentNode
      parent.style.outline = ''
    },
    // 点击搜索的回调
    search () {
      this.$emit('search', this.searchContent)
    },
    // 16进制颜色转rgba格式
    hexToRgba (hex, opacity) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return `2px solid rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)},${parseInt(result[3], 16)}, ${opacity})`
    }
  }
}
</script>

<style scoped>
  .search_input {
    height: 36px;
    background: #FFFFFF;
    border-radius: 18px;
    border: 1px solid #888888;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 26px;
    position: relative;
  }

  .search_input:hover,
  .search_input:focus {
    outline: 2px solid var(--input-hover-color);
  }

  input {
    border: none;
    font-size: 14px;
    height: 100%;
    width: 90%;
  }

  input::placeholder {
    color: #B4B4B4;
    ;
  }

  input:focus {
    outline: none;
  }

  .search_icon {
    cursor: pointer;
  }

  .search_input_mobile {}

  .search_input_mobile input {
    font-size: 13px;
  }
</style>

<template>
  <div class="progress-bar" :style="{width:`${width}px`}">
    <div class="current-progress theme-solid-bg-color" :style="{ width: `${progress}%`}"></div>
    <div class="progress-close" @click.stop="$emit('closeClick')" :style="styleText">
      <svg-icon svg-name="close-circle" width="16px" height="16px" style="margin-right:6px;"></svg-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    width: {
      type: Number,
      default: 100
    },
    // 当前进度(百分比)
    progress: {
      type: Number,
      default: 0
    },
    position: {
      type: String,
      default: 'left'
    }
  },
  computed: {
    styleText () {
      return this.position === 'left' ? '' : 'left:108px;'
    }
  }
}
</script>

<style scoped>
.progress-bar {
  position: absolute;
  top: calc(100% + 6px);
  left: 0;
  background-color: #dadada;
  border-radius: 2px;
  height: 4px;
}

.current-progress {
  position: absolute;
  border-radius: 2px;
  height: 100%;
  transition: 0.3s all ease;
  background-color: #3b59fe;
}

.progress-close{
  position: absolute;
  left: -24px;
  top: -7px;
}
</style>

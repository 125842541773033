<template>
  <div class="message-from">
    <!-- 分区标题 -->
    <div class="from-area-title">
      {{infoAreaTitle}}
    </div>
    <input type="text" class="message-input inputtexts" v-model.trim="fullName" maxlength="200" @focus="checkFullName = false" @blur="checkFullName = fullName.length === 0" :placeholder="infoAreaFullNamePlaceholder" />
    <p class="message-from-" v-show="checkFullName">{{infoAreaFullNameErrorText}}</p>
    <input type="text" class="message-input inputtexts" v-model.trim="email" @focus="checkEmail = false" @blur="emailBlur" :placeholder="infoAreaEmailPlaceholder" maxlength="300" />
    <p class="message-from-" v-show="checkEmail">{{infoAreaEmailErrorText}}</p>
    <div class="from-area-title" v-if="typeOfQuerysState">{{typeAreaTitle}}</div>

    <select-input class="message-input" placeholder="Please select type" :options="typeOfQuerys.options" :active="typeOfQuerys.active" :activeText="typeOfQuerys.activeText" @updateActiveText="updateSelectActiveText" @updateActive="updateSelectActive" v-if="typeOfQuerysState"></select-input>
    <p class="message-from-" v-show="checkTypeQuery">
      {{typeAreaErrorText}}
    </p>
    <div class="from-area-title no-fr-title">{{messageAreaTitle}}</div>
    <div class="message-input detail-input" :class="{ 'focus-border': messageFocus }" id="message" @click="inputFocus">
      <form-div-textarea class="form_div_textarea" :inputContent.sync="message" @focusEvent="divTextareaFocus" ref="divTextarea" :dataPlaceholder='messageAreaPlaceholder'></form-div-textarea>
    </div>
    <p class="message-from-" v-show="checkMessageInfo">{{messageAreaErrorText}}</p>
    <div class="fontLength" v-show="message.length >= 5900">
      <span :style="{
          color: message.length > 6000 ? 'red' : 'unset',
        }">{{ message.length }}
        / 6000
      </span>
    </div>
    <!-- 附件上传区 -->
    <div class="attach-files">
      <div class="attach-files-icon">
        <svg-icon svg-name="attach-files" width="20px" height="20px"></svg-icon>
      </div>
      <div class="attach-files-text">
        {{attachAreaTitle}}
        <input type="file" id="attach" style="display:none;" />
      </div>
    </div>
    <div class="attach-files-error" v-if="checkAttachSrc">
      {{attachAreaErrorText}}
    </div>
    <div class="submit">
      <adaptive-button btn-theme="blue" :btn-text="btnText" btnHeight="38px" fontSize="14px" click-methods="submit" click-arg="submit is click"></adaptive-button>
    </div>
    <loading v-if="loading" />
    <toast v-if="toastState" @changeState="setToastState" />
  </div>
</template>

<script>
import AdaptiveButton from 'components/contents/AdaptiveButton'
import SelectInput from 'components/contents/SelectInput'
import Loading from 'components/common/Loading/Loading'
import FormDivTextarea from 'components/contents/FormDivTextarea'
// import { saveOfflineMessage, getTypeQuery } from '@/api/leaveMessage'
import Toast from 'components/common/Toast/Toast'
export default {
  name: 'MessageFrom',
  data () {
    return {
      fullName: '',
      checkFullName: false,
      email: '',
      checkEmail: false,
      typeOfQuerysState: false,
      attachName: '',
      checkAttachSrc: false,
      typeOfQuerys: {
        // 选中项
        active: -1,
        // 选中项文本
        activeText: '',
        // 选项
        options: []
      },
      message: '',
      // 是否可以进行提交
      flag: false,
      loading: false,
      toastState: false,
      // message框是否聚焦
      messageFocus: false,
      checkMessageInfo: false,
      checkTypeQuery: false,
      // 信息区域（名字、邮箱）
      infoArea: {
        title: 'Information',
        fullName: {
          placeholder: 'Name',
          errorText: 'Name is empty'
        },
        email: {
          placeholder: 'Email',
          errorText: 'Email format is incorrect'
        }
      },
      // type of query区域
      typeArea: {
        title: 'Type of query',
        errorText: 'Please check a type'
      },
      // message区域
      messageArea: {
        title: 'Details',
        placeholder: 'Please describe your question in detail',
        errorText: 'Message is empty'
      },
      // 附件区域
      attachArea: {
        title: 'Attach files',
        errorText: 'Please upload file',
        fileName: ''
      },
      buttonText: 'Submit'
    }
  },
  components: {
    AdaptiveButton,
    SelectInput,
    Loading,
    FormDivTextarea,
    Toast
  },
  computed: {
    infoAreaTitle () {
      // return this.emailUs.information || this.$store.state.languageState ? this.$store.state.language.leaveMessage[2] : this.infoArea.fullName.title
      return this.emailUs.information || this.$store.state.languageState ? this.emailUs.information : this.infoArea.fullName.title
    },
    infoAreaFullNamePlaceholder () {
      // return this.emailUs.name || this.$store.state.languageState ? this.$store.state.language.leaveMessage[3] : this.infoArea.fullName.placeholder
      return this.emailUs.name || this.$store.state.languageState ? this.emailUs.name : this.infoArea.fullName.placeholder
    },
    infoAreaFullNameErrorText () {
      return this.$store.state.languageState ? this.$store.state.language.leaveMessage[4] : this.infoArea.fullName.errorText
    },
    infoAreaEmailPlaceholder () {
      // return this.emailUs.email || this.$store.state.languageState ? this.$store.state.language.leaveMessage[5] : this.infoArea.email.placeholder
      return this.emailUs.email || this.$store.state.languageState ? this.emailUs.email : this.infoArea.email.placeholder
    },
    infoAreaEmailErrorText () {
      return this.$store.state.languageState ? this.$store.state.language.leaveMessage[6] : this.infoArea.email.errorText
    },
    typeAreaTitle () {
      return this.$store.state.languageState ? this.$store.state.language.leaveMessage[7] : this.typeArea.title
    },
    typeAreaErrorText () {
      return this.$store.state.languageState ? this.$store.state.language.leaveMessage[8] : this.typeArea.errorText
    },
    typeOfQuerysPlaceholder () {
      return this.$store.state.languageState ? this.$store.state.language.leaveMessage[9] : this.typeOfQuerys.placeholder
    },
    messageAreaTitle () {
      // return this.emailUs.details || this.$store.state.languageState ? this.$store.state.language.leaveMessage[10] : this.messageArea.title
      return this.emailUs.details || this.$store.state.languageState ? this.emailUs.details : this.messageArea.title
    },
    messageAreaPlaceholder () {
      // return this.emailUs.detailedReminder || this.$store.state.languageState ? this.$store.state.language.leaveMessage[11] : this.messageArea.placeholder
      return this.emailUs.detailedReminder || this.$store.state.languageState ? this.emailUs.detailedReminder : this.messageArea.placeholder
    },
    messageAreaErrorText () {
      return this.$store.state.languageState ? this.$store.state.language.leaveMessage[12] : this.messageArea.errorText
    },
    attachAreaTitle () {
      if (this.attachName.length !== 0) return this.attachName
      if (this.attachName) return this.attachName
      return this.$store.state.languageState ? this.$store.state.language.leaveMessage[13] : this.attachArea.title
    },
    attachAreaErrorText () {
      return this.$store.state.languageState ? this.$store.state.language.leaveMessage[14] : this.attachArea.errorText
    },
    btnText () {
      return this.emailUs.submit || 'Submit'
    },
    textSetting () {
      return JSON.parse(this.$store.state.shopSetting.liveChat['0'])['text-settings']
    },
    emailUs () {
      return JSON.parse(this.textSetting.emailUs)
    }
  },
  methods: {
    // email失焦事件函数
    emailBlur () {
      const reg = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/gi
      this.checkEmail = this.email.length === 0 || !reg.test(this.email)
    },
    // messageDiv输入框的聚焦事件处理函数
    divTextareaFocus (messageFocus) {
      // console.log('我是父组件的函数聚焦事件！' + messageFocus)
      this.messageFocus = messageFocus
      if (messageFocus) {
        this.checkMessageInfo = false
      } else if (this.message.length === 0) {
        this.checkMessageInfo = true
      }
    },
    // back 按钮传回的数据
    submit (back) {
      // 如果没有传入this指向 则默认为this 需要进行处理
      // let _self = self === undefined ? this:self;
      // some code....
      console.log(back)
      // console.log(this.typeOfQuerys);

      if (this.fullName.length === 0) {
        this.checkFullName = true
        return false
      } else {
        this.checkFullName = false
      }
      const reg = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/gi
      if (this.email.length === 0 || !reg.test(this.email)) {
        this.checkEmail = true
        return false
      } else {
        this.checkEmail = false
      }

      if (this.typeOfQuerysState && this.typeOfQuerys.active === -1) {
        // 提示出错文本
        this.checkTypeQuery = true
        return false
        // console.log('未选中，该展示错误了');
      }

      this.checkTypeQuery = false
      // 如果message输入框为空 或者去除空格后长度为0
      if (this.message.trim().length === 0) {
        // 清空输入框
        this.$refs.divTextarea.clearTextarea()
        this.checkMessageInfo = true
        // console.log('message不能为空')
        return false
      }
    },
    // 按钮点击事件
    /**
       * @param string methods 要调用的方法名称
       * @param arg 参数
       */
    btnClick (methods, arg) {
      // 调用方法 传入当前this指向 否则this是指向$options的
      // this.$options.methods[methods](arg,this);

      // 如果大于6000个字符就直接return
      if (this.message.length > 6000) {
        console.log('超出字符限制')
        return
      }
      // 这种写法不需要传入this指向
      this[methods](arg)
    },
    // 选择事件

    // 更新选中项文本
    updateSelectActiveText (val) {
      this.typeOfQuerys.activeText = val
    },
    // 更新选中项
    updateSelectActive (val) {
      this.checkTypeQuery = false
      this.typeOfQuerys.active = val
    },
    // 当点击输入框父元素盒子时 让输入框聚焦
    inputFocus () {
      this.$refs.divTextarea.focusInput()
    },
    // 设置toast气泡框状态
    setToastState (flag) {
      this.toastState = flag
    }
  }
}
</script>

<style>
  .message-from {
    /* 抵消第一个标题多出来的26px */
    font-size: 14px;
  }

  .message-input {
    width: 100%;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    height: 40px;
    margin-top: 8px;
    text-indent: 12px;
  }

  .message-input::placeholder {
    color: #b4b4b4;
  }

  .from-area-title {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
  }

  .no-fr-title {
    margin-top: 24px;
  }

  .attach-files {
    display: flex;
    flex-direction: row;
    line-height: 22px;
    margin-top: 8px;
  }

  .attach-files-icon {
    width: 22px;
    height: 22px;
  }

  .attach-files-text {
    font-size: 12px;
    line-height: 20px;
    color: #6c6b80;
    text-align: left;
    margin-left: 4px;
  }

  .message-from- {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: #f5222d;
    text-align: left;
    margin-top: 4px;
  }

  .detail-input {
    height: 120px;
    padding: 8px 12px;
    text-align: left;
    text-indent: 0;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
  }

  #message /deep/ .input-textarea::before {
    font-size: 14px;
  }

  #message /deep/ .input-textarea {
    word-break: break-word;
  }

  /*messageDiv聚焦样式*/
  /* .focus-border {
    border: 2px solid black;
  } */

  /* .message-from .inputtexts:focus {
    border: 2px solid black;
  } */

  .fontLength {
    margin-top: 4px;
    float: right;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: #f5222d;
  }

  .submit {
    margin-top: 26px;
  }
</style>

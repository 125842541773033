<template>
  <div class="break-welcome" :style="styleText">
    <!-- 欢迎语文本 -->
    <p class="break-welcome-text">
      <!-- Sorry,we aren't online at the moment. if you have any questions, please
      leave a message or try the following methods to solve the problem. -->
      {{breakText}}
    </p>
    <p class="break-back-time" v-if="showTimes && backText">
      <!-- We’ll be back in -->
      {{backText}}
    </p>
    <p class="break-back-time" v-if="showTimes && breakTime">
      <svg-icon svg-name="clock" width="16px" height="16px"></svg-icon>
      <!--      &nbsp;A few minutes-->
      &nbsp;<span>{{ breakTime }}</span>
    </p>
    <!-- 按钮区 -->
    <!-- 聊天按钮 -->
    <adaptive-button @btnClick="btnClick" btn-theme="blue" btn-text="Email us" click-methods="setPage" click-arg="leave-message" style="padding-top" v-if="$store.state.shopSetting.WidgetStatus[0].ShowFaq"></adaptive-button>
    <!-- 离线留言 -->
    <adaptive-button @btnClick="btnClick" btn-theme="white" btn-text="Help center" click-methods="setPage" click-arg="search-page" v-if="$store.state.shopSetting.WidgetStatus[0].DisplayContentForm"></adaptive-button>
  </div>
</template>

<script>
import AdaptiveButton from 'components/contents/AdaptiveButton'
export default {
  name: 'BreakWelcome',
  data () {
    return {}
  },
  components: {
    AdaptiveButton
  },
  props: {
    // 数据
    chatData: {
      type: Object,
      required: true
    }
  },
  computed: {
    textSetting () {
      return JSON.parse(this.$store.state.shopSetting.liveChat['0'])['text-settings']
    },
    styleText () {
      return this.$store.state.boxPadding === '16px' ? 'margin: 26px 16px;' : ''
    },
    breakTime () {
      let str = ''
      let workTime = new Date().getTime() + this.$store.state.workTime * 60000 + 60000
      const waittime = this.$store.state.shopSetting.BusinessHours[0].chatNonworkMsgTime
      if (this.$store.state.workTime === 0) {
        return this.$store.state.shopSetting.BusinessHours && this.$store.state.shopSetting.BusinessHours[0].ReplyTime
      } else if ((this.$store.state.workTime / 60) > 24) {
        str = this.$moment(workTime).format('MMM, DD hh:mm A')
      } else {
        let time = this.$store.state.workTime
        if (time < 30) {
          if (time === 0) return JSON.parse(this.textSetting.chat_welcome_msg) && JSON.parse(this.textSetting.chat_welcome_msg).three
          str = '30 ' + 'minutes'
        } else {
          str = (Math.ceil(time / 60) + ' ' + 'hours')
        }
      }
      return waittime.replace(/{Waittime}/g, str)
    },
    breakText () {
      return JSON.parse(this.textSetting.chat_nonwork_msg) && JSON.parse(this.textSetting.chat_nonwork_msg).one
    },
    backText () {
      return JSON.parse(this.textSetting.chat_nonwork_msg) && JSON.parse(this.textSetting.chat_nonwork_msg).two
    },
    showTimes () {
      return this.$store.state.shopSetting.BusinessHours[0].TimeRange.length > 0
    }
  },
  methods: {
    setPage (page) {
      // console.log(this);
      // 如果没有传入this指向 则默认为this
      // let _self = self === undefined ? this:self;
      this.$store.commit('setPage', page)
    },
    // 按钮点击事件
    /**
       * @param string methods 要调用的方法名称
       * @param arg 参数
       */
    btnClick (methods, arg) {
      // 调用方法 传入当前this指向 否则this是指向$options的
      // this.$options.methods[methods](arg,this);
      this[methods](arg)
    }
  }
}
</script>

<style>
  .break-welcome {
    background: #f5f5f5;
    border-radius: 12px;
    padding: 16px;
    margin: 26px;
  }

  .break-welcome-text {
    font-size: 14px;
    font-weight: 400;
    color: #353535;
    margin-bottom: 12px;
    text-align: left;
    line-height: 20px;
    /* 强制英文单词内断行 */
    /* word-break: break-all; */
  }

  .break-back-time {
    font-weight: 550;
    color: #292929;
    font-size: 14px;
    text-align: left;
    line-height: 22px;
    margin-bottom: 1em;
    word-break: break-word;
  }

  ::v-deep .adaptive-button button {
    height: 36px;
  }
</style>

<template>
  <div :class="$store.state.isMobile?'question_mobile':''">
    <scroll-container class="question_detail" topHook @topHook="(flag)=>{ this.isTop = flag }" ref="scrollcontainer" :scrollHook="titleHeight" @scrollHook="(flag)=>{this.showNavTitle =! flag}">
      <Loading v-if="contentLoading"/>
      <div class="question_detail_box" v-else>
        <div class="title">{{ article.title }}</div>
        <div class="content" v-html="$xss(newArticleContent)" @click="chatContentClickHandler"></div>
        <article-comment :articleId="article.articleId"></article-comment>
        <div class="related-article" v-if="relatedArticles.length > 0">
          <div class="related-title">{{ relatedText }}</div>
          <div class="related-item" v-for="(item,index) in relatedArticles" :key="index" @click="getActicleDetail(item.id)">
            {{ item.title }}
          </div>
        </div>
      </div>
      <!-- <div class="question_serach_result" v-else> -->
        <!-- <search-result :searchList="searchList" :counts="Counts" :searchContent="searchContent"></search-result> -->
        <!-- <component class="component" :is="currentType" :class="index===0?'firstChild':''" v-for="(item,index) in searchList" :key="index" :searchList="searchList" :counts="Counts" :searchContent="searchContent"></component> -->
      <!-- </div> -->
    </scroll-container>
  </div>
</template>

<script>
// import NavBar from 'components/contents/NavBar'
// import SearchInput from '../components/SearchInput'
import ScrollContainer from 'components/contents/ScrollContainer'
import Loading from 'components/common/Loading/Loading'
import { postFrameMessage } from '@/utils/iframeMessage'
// import SearchResult from './components/SearchResult'
import ArticleComment from '../components/ArticleComment'
// import {
//   getFaqArticle,
//   searchFaq
// } from '@/api/helpCenter'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    // NavBar,
    ScrollContainer,
    ArticleComment,
    Loading
    // SearchInput
    // SearchResult
  },
  name: 'QuestionDetail',
  data () {
    return {
      // 判断是否需要显示nav_title
      showNavTitle: false,
      // title底部的高度
      titleHeight: 0,
      // 文章
      article: {},
      // 是否在顶部
      isTop: true,
      currentType: 'question-detail',
      // 搜索到的条数
      Counts: 0,
      // 搜索到的结果
      searchList: [],
      // 搜索内容
      searchContent: '',
      contentLoading: false,
      relatedArticles: [],
      faqUrl: ''
    }
  },
  computed: {
    ...mapGetters({
      getShopsetting: 'setting/getShopsetting',
      getFaqPageInfo: 'faq/getFaqPageInfo',
      getAllCategoryArticle: 'faq/getAllCategoryArticle',
      getIsBigArticle: 'common/getIsBigArticle'
    }),
    themeColor () {
      return this.getShopsetting.primaryColor
    },
    relatedText () {
      return this.getShopsetting.helpcenterRelatedArticles || 'RELATED ARTICLES'
    },
    newArticleContent(){
      return this.article.content.replace(/target="_self"/g, `target="_blank"`)
    }
    // relatedArticles () {
    //   let arr = []
    //   // if (this.article.articleId) {
    //   console.log('---------', this.getAllCategoryArticle, this.article.articleId)
    //   const arr2 = this.getAllCategoryArticle.filter(item => item.id == this.getFaqPageInfo.categoryId)
    //   if (arr2.length > 0 && this.article.articleId) {
    //     arr = arr2[0].articleList.filter(item => item.id != this.article.articleId)
    //   }
    //   // }
    //   console.log('related', arr)
    //   return arr
    // }
  },
  watch: {
    // article: {
    //   handler (newVal) {
    //     console.log('aaa')
    //     if (newVal.articleId) {
    //       console.log('aaa')
    //       const arr2 = this.getAllCategoryArticle.filter(item => item.id == this.getFaqPageInfo.categoryId)
    //       if (arr2.length > 0) {
    //         this.relatedArticles = arr2[0].articleList.filter(item => item.id != newVal.articleId)
    //       }
    //     }
    //   },
    //   immediate: true
    // }
  },
  created () {
    if (this.getFaqPageInfo.faqPage == 'articleDetail' && this.getFaqPageInfo.articleId && this.getFaqPageInfo.articleContent) {
      this.article = {
        title: this.getFaqPageInfo.articleTitle,
        content: this.getFaqPageInfo.articleContent,
        articleId: this.getFaqPageInfo.articleId
      }
      const arr2 = this.getAllCategoryArticle.filter(item => item.id == this.getFaqPageInfo.categoryId)
      if (arr2.length > 0) {
        this.relatedArticles = arr2[0].articleList.filter(item => item.id != this.article.articleId)
      }
    } else {
      this.getActicleDetail(this.getFaqPageInfo.articleId)
    }
    this.handleView()
  },
  mounted () {
    // if (this.getShopsetting.shopInfo && this.getShopsetting.shopInfo.shopId) {
    // getFaqArticle({ // 接口修改
    //   id: this.$store.state.articleId,
    //   shopId: this.getShopsetting.shopInfo.shopId
    // }).then(res => {
    // // 解决偶发的超链接在当前窗口打开的问题
    //   if (res && res.code == 0) {
    //     const data = res.data
    //     if (data.content && /target="_self"/.test(data.content)) {
    //       data.content = data.content.replace(/target="_self"/, "target='_blank'")
    //     }
    //     this.article = data

    //     this.$nextTick(() => {
    //       // 获取title底部的高度
    //       const title = this.$el.querySelector('.title')
    //       this.titleHeight = title.offsetTop + title.scrollHeight
    //     })

    //     this.$nextTick(() => {
    //       // 给内容的所有图片绑定点击查看大图的事件
    //       const imgList = this.$el.querySelector('.content').querySelectorAll('img')

    //       imgList.length && imgList.forEach(item => {
    //         item.addEventListener('click', this.viewImg)
    //       })
    //     })
    //   }
    // })
    // }
  },
  methods: {
    ...mapMutations({
      setFaqPageInfo: 'faq/setFaqPageInfo',
      setAllCategoryArticle: 'faq/setAllCategoryArticle',
      setIsBigArticle: 'common/setIsBigArticle'
    }),
    // 搜索请求
    searchFaq (content) {
      searchFaq({
        search: content,
        shopId: this.getShopsetting.shopInfo.shopId
      }).then(res => {
        if (res && res.code == 0) {
          this.searchList = res.data.list
          this.Counts = res.data.counts
        }
      }).catch(err => {
        console.log(err)
        this.Counts = 0
      })
    },
    search (content) {
      if (content === '') {
        this.currentType = 'question-detail'
      } else {
        this.searchList = []
        this.Counts = -1
        this.searchContent = content
        this.searchFaq(content)
        this.currentType = 'search-result'
      }
    },
    // 查看图片
    viewImg (src) {
      postFrameMessage({
        type: 'img',
        data: {
          src,
          file: null
        }
      }, document.referrer.substr(0, document.referrer.length - 1))
    },
    getActicleDetail (articleId) {
      this.contentLoading = true
      getFaqArticle({ // 接口修改
        id: articleId,
        shopId: this.getShopsetting.shopInfo.shopId
      }).then(res => {
      // 解决偶发的超链接在当前窗口打开的问题
        if (res && res.code == 0) {
          const data = res.data
          if (data.content && /target="_self"/.test(data.content)) {
            data.content = data.content.replace(/target="_self"/, "target='_blank'")
          }
          this.article = data
          this.article.articleId = data.id
          const arr2 = this.getAllCategoryArticle.filter(item => item.id == this.getFaqPageInfo.categoryId)
          if (arr2.length > 0) {
            this.relatedArticles = arr2[0].articleList.filter(item => item.id != articleId)
          }
          // this.$nextTick(() => {
          //   // 获取title底部的高度
          //   const title = this.$el.querySelector('.title')
          //   this.titleHeight = title.offsetTop + title.scrollHeight
          // })

          this.contentLoading = false
          this.$nextTick(() => {
            // 给内容的所有图片绑定点击查看大图的事件
            const imgList = this.$el.querySelector('.content').querySelectorAll('img')
            imgList.length && imgList.forEach(item => {
              item.addEventListener('click', this.viewImg)
            })
          })
        }
      })
    },
    chatContentClickHandler (event) {
      if (event.target.nodeName === 'IMG' && event.target.src) {
        this.viewImg(event.target.src)
      }else if(event.target.nodeName == 'A' && event.target.target != '_blank'){
        event.preventDefault()
        window.open(event.target.href,'_blank')
      }
    },
    handleView () {
      const type = 'bigViewAritle'
      this.setIsBigArticle(true)
      postFrameMessage({
        type: 'articleView',
        data: type
      }, document.referrer.substr(0, document.referrer.length))
      // }
    }
  }
}
</script>

<style scoped>
  .question_detail {
    padding: 16px 26px;
    height: calc(100vh - 68px);
    top: 68px;
    text-align: left;
    word-break:break-word;
  }

  .nav_bar {
    height: 68px;
    transition: 0.3s box-shadow ease;
    background: #fff !important;
  }

  .nav_bar /deep/ .nav-bar-center {
    display: flex;
    align-items: center;
  }
  .loading{
    position: absolute;
    top: 30%;
    left: 50%;
  }
  .nav_title_container {
    width: 100%;
  }

  .nav_title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100vw - 107px);
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #292929;
    line-height: 20px;
    transition: 0.3s transform ease;
    transform: translateY(20px);
  }

  .nav_title_show {
    transform: translateY(0);
  }

  .title {
    font-size: 18px;
    font-weight: 600;
    color: #292929;
    line-height: 28px;
    margin-bottom: 26px;
  }

  .content {
    color: #000;
    margin-bottom: 16px;
    list-style-position: inside;
    word-wrap: break-word;
  }

  .content /deep/ p {
    font-size: 14px;
    color: #292929;
    line-height: 20px;
  }

  .content /deep/ img {
    cursor: pointer;
    margin: 0 3px;
    max-width: 100%;
    height: unset;
  }

  .question_mobile {
    /* font-family: PingFangSC-Regular, PingFang SC; */
  }

  .question_mobile .question_detail {
    height: calc(100vh - 60px);
    top: 60px;
    padding: 10px 16px;
  }

  .question_mobile .nav_bar {
    height: 60px;
  }

  .question_mobile .nav_bar /deep/ .helpdesk-channel-close {
    width: 42px;
  }

  .question_mobile .title {
    font-size: 18px;
    /* font-family: PingFangSC-Semibold, PingFang SC; */
    font-weight: 600;
    color: #292929;
    line-height: 21px;
    margin-bottom: 16px;
  }

  .question_mobile .content {
    margin-bottom: 32px;
  }

  .not_top {
    box-shadow: 0px 2px 6px -2px rgba(0, 0, 0, 0.28);
  }

  ::v-deep .blockquotebox {
    display: block;
    border-left: 8px solid #d0e5f2;
    padding: 5px 10px;
    margin: 10px 0;
    line-height: 1.4;
    font-size: 100%;
    background-color: #f1f1f1;
  }
  .nav_right_qd {
    width: 52px;
  }
  .related-article{
    padding-top: 30px;
    border-top: 1px solid #F3F3F6;
  }
  .related-title{
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
  .related-item{
    font-size: 16px;
    line-height: 150%;
    cursor: pointer;
    text-decoration-line: underline;
    margin-bottom: 20px;
  }
  .related-item:hover{
    color: var(--primary-color);
  }
</style>

// PostMessage封装

// 监听消息 收到父窗口消息的回调事件
export function listenFrameMessage (callback) {
  // 监听postMessage推过来的消息
  window.addEventListener('message', callback, false)
}

// 取消监听
export function removeListenFrame (callback) {
  window.removeEventListener('message', callback, false)
}

// 向父窗口发送消息
export function postFrameMessage (message, src) {
  // 向父窗口发送消息 父窗口的origin 类似于src
  window.parent.postMessage(message, src)
}

<template>
  <div class="small-card theme-border-color">
    <div class="card-title" v-if="cardTitle">{{ cardTitle }}</div>
    <!-- 插槽 -->
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SmallCard',
  props: {
    // 卡片标题
    cardTitle: {
      type: String,
      require: true
    }
  }
}
</script>

<style>
  .small-card {
    position: relative;
    height: auto;
    padding: 16px;
    background: #fff;
    margin-bottom: 16px;
    box-shadow: 0px 4px 10px rgba(18, 17, 39, 0.1);
    border-radius: 8px;
  }

  .card-title {
    color: #000 !important;
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 12px !important;
  }
</style>

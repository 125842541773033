<template>
  <div class="nav-bar theme-bg-color" :style="styleText">
    <div class="nav-bar-left theme-text-color" @click="leftClick" :style="leftWidth">
      <slot name="left" style="" />
    </div>
    <div class="nav-bar-center theme-text-color" :style="centerStyle">
      <slot name="center" />
    </div>
    <div class="nav-bar-right">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
// TODO 未完成 间距细节适配
export default {
  name: 'NavBar',
  props: {
    // 点击返回后将要跳转的页面
    backPage: String
  },
  computed: {
    styleText () {
      // 如果是移动端就设置高度为60
      return this.$store.state.isMobile ? 'height:60px;line-height:60px;' : ''
    },
    // 左侧宽度
    leftWidth () {
      return this.$store.state.isMobile ? 'width:42px;' : ''
    },
    // 中间字体
    centerStyle () {
      return this.$store.state.isMobile ? 'font-size:24px;width: calc(100% - 67px - 42px);' : ''
    }
  },
  methods: {
    // leftClick
    leftClick () {
      // 当返回按钮发生点击时 调整对应vuex currentPage 类似于vue-router
      this.$store.commit('setPage', this.backPage)
    }
  }
}
</script>

<style>
  .nav-bar {
    display: flex;
    align-items: center;
    line-height: 74px;
    /* FIXME 后期可能要对不同手机高度进行适配 让细节更加漂亮 */
    height: 74px;
    background: linear-gradient(180deg, #636eff 0%, #3b59fe 100%);
    color: #fff;
  }

  .nav-bar-left {
    cursor: pointer;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-bar-center {
    width: calc(100% - 134px);
    font-size: 16px;
    font-weight: 400;
    flex: 1;
    text-align: left;
    display: flex;
    align-items: center;
  }
</style>

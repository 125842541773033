<template>
  <div class="emoji">
    <scroll-container style-text="position: relative;max-height: 236px;height:236px;top:0;">
      <div class="emoji-area" :style="styleText">
        <div class="emoji-item-container" v-for="(item, index) in 200" :key="index">
          <div class="emoji-item" @click="clickEmoji(index)" :style="getIndexPosition(index)">
            <!-- {{ item.emoji }} -->
          </div>
        </div>

        <!-- 移动端悬浮删除按钮 v-if="isMobile" -->
        <div class="emoji-delete" v-if="false" @click="deleteEmoji">
          <svg-icon svg-name="delete" width="55px" height="55px"></svg-icon>
        </div>
      </div>
    </scroll-container>
  </div>
</template>

<script>
// 引入表情库
// import emoji from '@/assets/emoji/emoji.json'
import ScrollContainer from './ScrollContainer'
export default {
  name: 'Emoji',
  components: {
    ScrollContainer
  },
  /*  data () {
    return {
      emoji
    }
  }, */
  computed: {
    styleText () {
      return this.$store.state.isMobile ? 'padding: 16px 16px 26px 16px;' : 'padding: 26px;'
    }
  },
  methods: {
    clickEmoji (index) {
      // console.log(index)
      this.$emit('clickEmoji', index)
    },
    deleteEmoji () {
      // console.log('....');
      this.$parent.$refs.chatInput.$refs.divTextarea.removeLastStr()
    },
    getIndexPosition (index) {
      return { 'background-position': `0 ${-22 * index}px` }
    }
  }
}
</script>

<style>
  .emoji {
    background: #f0f0f0;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .08);
    max-height: 236px;
    /* margin: 26px; */
    /* width: calc(100% - 2px); */
  }

  .emoji-area {
    width: 100%;
    display: flex;
    height: auto;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    background: #fff;
    border-radius: 10px;
    /* align-items: center; */
  }

  .emoji-item-container {
    width: 12.5%;
    height: 40px;
  }

  .emoji-item {
    /* FIXME 暂定20*20 */
    width: 22px;
    height: 22px;
    font-size: 24px;
    cursor: pointer;
    font-family: unset;
    background: url("~assets/emoji/emoji.png") 0 0 no-repeat;
  }

  .emoji-delete {
    position: fixed;
    right: 16px;
    bottom: 16px;
    width: 55px;
    height: 55px;
    /* background: #000; */
    color: #fff;
  }
</style>

<template>
  <div>
    <div class="loading_container" v-if="loading">
      <img :src="loadingImg" alt="">
    </div>
    <div v-else>
      <div class="search_result" :class="$store.state.isMobile?'search_result_mobile':''" v-if="counts > 0">
      <div class="search_counts">{{ counts }} “{{ searchContent }}” {{ searchResult }}.</div>
        <search-result-item v-for="item in searchList" :key="item.articleId" :article="item"></search-result-item>
      </div>
      <div class="search_notfound" v-else-if="counts === 0" :class="$store.state.isMobile?'search_result_mobile':''">
        <svg-icon class="search_notfound_icon" svg-name="search-notfound-icon" width="100px" height="98px"></svg-icon>
        <div class="search_content">{{searchContentText}}.</div>
        <div class="search_tips">{{searchTipsText}}.</div>
        <div class="btn_container">
        <adaptive-button class="chat_us_btn" v-if="chatState" @btnClick="btnClick" click-methods="setPage" click-arg="login" btnWidth="160px" btnHeight="38px" iconMarginR="9px" :btnText="chatButtonText" iconWidth="17.5px" iconHeight="17.5px" fontSize="14px" iconName="send"></adaptive-button>
      </div>
      </div>
    </div>
  </div>

</template>

<script>
import SearchResultItem from '../components/SearchResultItem'
import AdaptiveButton from '@/components/contents/AdaptiveButton'
// import { updateCustomer } from '@/api/user'
// import { searchFaq } from '@/api/helpCenter'
import axios from 'axios'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  components: { SearchResultItem, AdaptiveButton },
  name: 'SearchResult',
  data () {
    return {
      searchContentAfter: 'No results for {{----}}',
      searchTips: 'Please try to change other keywords or get help in the following ways',
      loadingImg: `${process.env.VUE_APP_STATIC_URL}/img/loading.png`,
      searchList: [],
      counts: 0,
      searchContent: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      getUserHost: 'loginParams/getUserHost',
      getShopsetting: 'setting/getShopsetting',
      getFaqPageInfo: 'faq/getFaqPageInfo',
      getCurrLangFixedTranslate: 'translate/getCurrLangFixedTranslate',
      getCurrLangCustomTranslate: 'translate/getCurrLangCustomTranslate',
    }),
    searchContentText () {
      return this.$store.state.languageState ? this.$store.state.language.helpCenter[9].replace('{{----}}', '"' + this.searchContent + '"') : this.searchContentAfter.replace('{{----}}', '"' + this.searchContent + '"')
    },
    searchTipsText () {
      return this.$store.state.languageState ? this.$store.state.language.helpCenter[10] : this.searchTips
    },
    chatButtonText () {
      if (this.$store.state.languageState) {
        return this.$store.state.login ? this.$store.state.language.helpCenter[4] : this.$store.state.language.home[3]
      } else {
        return this.$store.state.login ? this.getShopsetting.resumeChatButton : this.getShopsetting.contactUsButton
      }
    },
    emailButtonText () {
      return this.getShopsetting.emailUsButton
    },
    chatState () {
      return Boolean(Number(this.getShopsetting.enableLiveChat))
    },
    leaveMessageState () {
      return Boolean(Number(this.getShopsetting.displayContactForm))
    },
    searchResult () {
      return this.$store.state.language.trackOrder[25] || 'Search Results'
    }
  },
  created () {
    // try {
    //   this.location()
    // } catch (e) {
    //   console.log(e)
    // }

  },
  watch: {
    getFaqPageInfo (newVal) {
      if (newVal.keywords) {
        this.searchContent = newVal.keywords
        this.getSearchFaq(newVal.keywords)
      }
    }
  },
  methods: {
    ...mapActions({
      setPageHandler: 'setPage'
    }),
    ...mapMutations({
      setFaqPageInfo: 'faq/setFaqPageInfo'
    }),
    async location () {
      const result = await axios.get('https://geolocation-db.com/json/').catch(e => {
        console.log(e)
      })
      if (result) {
        const city = result.data.city ? result.data.city : ''
        const state = result.data.state ? result.data.state : ''
        const countryName = result.data.country_name ? result.data.country_name : ''
        var location
        if (countryName && countryName.trim() !== '') {
          location = countryName
        }
        if (state && state.trim() !== '') {
          location = state + ',' + countryName
        }
        if (city && city.trim() !== '') {
          location = city + ',' + countryName
        }
        this.$store.commit('setLocation', location)
      }
    },
    // 按钮点击事件
    /**
       * @param string methods 要调用的方法名称
       * @param arg 参数
       */
    btnClick (methods, arg) {
      // 调用方法 传入当前this指向 否则this是指向$options的
      // this.$options.methods[methods](arg, this);
      this[methods](arg)
    },
    // 跳转页面方法
    setPage (page) {
      this.setPageHandler(page).then(res => {
        this.loading = false
        if (res && this.$store.state.location) {
          updateCustomer({ // 接口修改
            location: this.$store.state.location || ''
          })
        }
      }).catch(() => {
        this.loading = false
        this.toastState = true
      })
    },
    getSearchFaq (content) {
      searchFaq({
        search: content,
        shopId: this.getShopsetting.shopInfo.shopId
      }).then(res => {
        if (res && res.code == 0) {
          this.loading = false
          this.searchList = res.data.list
          this.counts = res.data.counts
        }
      }).catch(() => {
        this.loading = false
        this.counts = 0
      })
    }
  },
  mounted () {
    if (this.getFaqPageInfo.faqPage == 'SearchResult') {
      this.loading = true
      this.searchContent = this.getFaqPageInfo.keywords
      this.getSearchFaq(this.getFaqPageInfo.keywords)
    }
  }
}
</script>

<style scoped>
  .search_result,
  .search_notfound {}

  .search_counts {
    margin: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }

  .search_notfound {
    display: flex;
    flex-direction: column;
    align-items: center;

    /* position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
    text-align: center;
    white-space: nowrap;
  }

  .search_content {
    color: #000;
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    margin-top: 8px;
  }

  .search_tips {
    color: #6C6B80;
    margin-top: 8px;
    white-space: pre-wrap;
    width: 308px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }

  @media (min-width: 520px) {
    .search_notfound {
      transform: translateY(241px);
    }
  }
  @media (max-width: 372px) {
    .search_notfound {
      transform: translateY(116px);
    }
  }

  .btn_container {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }

  .email_us_btn,
  .chat_us_btn {
    margin: 0 8px;
  }

  .email_us_btn /deep/ button,
  .chat_us_btn /deep/ button {
    padding: 0 26px;
    font-weight: 500;
  }

  .loading_container {
    position: absolute;
    top: 40%;
    left: 50%;
    animation: 2s loading linear infinite;
  }

  @keyframes loading {
    0% {
      transform: translate(-50%, -50%) scale(0.5) rotate(0deg);
    }

    100% {
      transform: translate(-50%, -50%) scale(0.5) rotate(360deg);
    }
  }

  .search_result_mobile {
    /* font-family: PingFangSC-Regular, PingFang SC; */
  }

  .search_result_mobile .search_counts {
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    color: #727272;
    line-height: 16px;
    margin: 12px 26px 21px;
  }

  .search_result_mobile .search_content {
    font-size: 13px;
  }

  .search_result_mobile .search_tips {
    font-size: 12px;
  }
</style>

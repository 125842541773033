import axios from 'axios'
import $store from '@/store/index.js'
// TODO 初步封装 待完善------------

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 5000,
  withCredentials: false
})

/* let token = getCache() ? getCache().token:'';
console.log(token); */
// 请求拦截器
service.interceptors.request.use(
  config => {
    config.headers.authorization = $store.state.shopSetting.TOKEN || ''
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(error)
  }

)

export default service

<template>
  <div class="customer-service" :style="styleText">
    <div class="customer-avatar">
      <avatar :user-info="chatData" />
    </div>
    <div class="customer-service-content">
      <p class="customer-service-name">{{ chatData.userName }}</p>

      <!-- <div class="customer-service-message">
        <pre v-html="emojiToImg"></pre>
      </div> -->

      <!-- 文字消息 -->
      <div class="customer-service-message" v-if="chatData.message" v-html="chatData.message"></div>
      <div class="customer-service-message" v-else>
        <div class="chat-answer-content">
          <div class="chat-answer-title theme-text-colors" v-if="chatData.caption" v-html="chatData.caption"></div>
          <div class="chat-answer-description" v-if="chatData.description" v-html="chatData.description.replace(/\n/ig, '<br>')"></div>
          <div class="chat-answer-item" v-for="(item, key) in chatData.answer" :key="'chat' + key">
            <div class="chat-answer" v-if="item.type === 0" v-html="transChat(item.label)"></div>
            <div class="chat-input-box" v-else-if="item.type === 1">
              <input type="text" class="chat-message-input" :placeholder="item.label" v-model="item.value" />
              <div class="chat-input-icon theme-bg-color" v-if="!item.value" @click="goCollect(item, chatData)">
                <svg-icon svg-name="direction-right-white" width="24px" height="24px"></svg-icon>
              </div>
              <div class="chat-input-icon theme-bg-color" v-else>
                <svg-icon svg-name="tick" width="24px" height="24px"></svg-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chat-options-content" v-if="lastChild">
        <div class="chat-option theme-border-colors theme-text-colors" v-for="(item, key) in chatData.options" :key="key" @click="goNext(item)">
          {{ item.label }}
        </div>
      </div>
      <!-- 附件 -->
      <div class="customer-service-attachment" v-if="chatData.messageType === 'attachment'" @click="downloadFile">
        <svg-icon svg-name="attach-files" width="16px" height="16px" style="margin-right:6px;"></svg-icon><span>{{chatData.message}}</span>
        <progress-bar :progress="progress" v-if="isDownloading" @closeClick="cancelDownloadFunc('Cancel download')" position="right"></progress-bar>
        <a @click.stop class="download" :href="downloadURL" :download="chatData.message" target="_blank" style="display:none;"></a>
      </div>

      <!-- 图片 图片未加载时用容器进行占位 -->
      <div class="customer-service-img" v-if="chatData.messageType === 'img'" @click="viewImg(chatData.src)">
        <img :src="chatData.src" alt="image-message" :style="imgSizeStyle" />
      </div>

    </div>
  </div>
</template>

<script>
import Avatar from './Avatar'
import ProgressBar from '@/components/contents/ProgressBar'
// import { getCustomerService } from '@/api/customerService'
import { getEmojiByAlt, getImgSizeFromSrc, calcImgSize, idLink } from '@/utils/helper'
import { mapActions, mapGetters, mapMutations } from 'vuex'
// import { postFrameMessage } from '@/utils/iframeMessage'
// 客服消息组件
export default {
  name: 'CustomerService',
  components: {
    Avatar,
    ProgressBar
  },
  props: {
    // 消息类型 可能是字符串 也可能是图片
    /* messageType: {
            type: String,
            default: 'string'
          },
          message: {
            type: String,
            required: true
          },
          userName: {
            type: String,
            required: true
          } */

    // 聊天记录数据 需要包含userName message
    chatData: {
      type: Object,
      required: true
    },
    lastChild: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    chatData (newVal) {
      console.log(newVal)
    }
  },
  data () {
    return {
      // 文件的本地url下载地址
      downloadURL: null,
      // 当前的下载进度
      progress: 0,
      // 是否正在下载
      isDownloading: false,
      // 取消下载的方法
      cancelDownloadFunc: null,
      // 图片是否在加载中
      imgLoading: false
    }
  },
  created () {
    // 先从vuex获取是否已经存在坐席信息
    // let index = this.getCustomerServiceInfoIndex(Number(this.chatData.customerServiceId));
    // console.log();
    // console.log(this.chatData);
    // console.log(this.$store.state.customerServiceInfo);
    // console.log(index);

    // this.chatData.message = getEmojiByAlt(this.chatData.message)
    const that = this

    // console.log(this.$store.state.customerServiceInfo)
    // console.log(this.chatData)

    const info = this.$store.state.customerServiceInfo[this.chatData.customerServiceId]

    // 等于-1说明未找到 异步请求渲染
    if (!info) {
      // 根据id获取对应

      // getCustomerService({
      //   Shop: this.$store.state.shop,
      //   CustomerServiceId: this.chatData.customerServiceId
      // })
      //   .then((res) => {
      //     console.log(res.data)
      //     if (res.data.Code === 200) {
      //       // that.customerServiceInfo.push();
      //       // console.log(that.customerServiceInfo);
      //       // 将坐席信息存入到vuex
      //       that.$store.dispatch('addCustomerServiceInfo', {
      //         id: res.data.CustomerService[0].CustomerServiceId,
      //         name: res.data.CustomerService[0].CustomerServiceName,
      //         profile: res.data.CustomerService[0].Profile
      //       })
      //       that.$set(
      //         that.chatData,
      //         'userName',
      //         res.data.CustomerService[0].CustomerServiceName
      //       )
      //       that.$set(
      //         that.chatData,
      //         'profile',
      //         res.data.CustomerService[0].Profile
      //       )
      //       // that.chatData.userName = res.data.CustomerService[0].CustomerServiceName;
      //       // that.chatData.faceColor = res.data.CustomerService[0].FacesColor;
      //       console.log(
      //         'api profile' + res.data.CustomerService[0].Profile
      //       )
      //     }
      //   })
      //   .catch((res) => {
      //     console.log(res)
      //   })
    } else {
      // 否则说明找到对应的数据 从vuex取出即可
      // this.chatData.userName = info.userName;
      // this.chatData.faceColor = info.faceColor;
      that.$set(that.chatData, 'userName', info.name)
      that.$set(that.chatData, 'profile', info.profile)
      console.log('info Profile' + info.profile)
    }
  },
  computed: {
    ...mapGetters({
      automationRules: 'automation/automationRules'
    }),
    transChat () {
      return function (content) {
        let contentList = ''
        try {
          contentList = JSON.parse(content).contentList.replace(/\n/ig, '<br>')
        } catch (e) {
          console.log(e)
        }
        return contentList
      }
    },
    styleText () {
      const right = Number(this.$store.state.boxPadding.replace('px', '')) * 2
      return (
        'margin:26px ' + right + 'px 26px ' + this.$store.state.boxPadding + ';'
      )
    },
    emojiToImg () {
      return idLink(getEmojiByAlt(this.chatData.message))
    },
    // 图片占位图
    imgSizeStyle () {
      const src = getImgSizeFromSrc(this.chatData.src)
      const scale = src && calcImgSize(src.width, src.height)
      console.log(src)
      console.log(scale)
      return { width: scale.width ? scale.width + 'px' : '', height: scale.height ? scale.height + 'px' : '' }
    }
  },
  methods: {
    ...mapMutations({
      setAutomationChat: 'automation/setAutomationChat'
    }),
    ...mapActions({
      setAutomationAction: 'automation/setAutomationAction',
      setAutomationActionDemo: 'automation/setAutomationActionDemo'
    }),
    goNext (item) {
      if (item.type !== 3) {
        this.setAutomationChat({
          userName: 'Jack',
          message: item.label,
          messageState: 2,
          type: 'Customer',
          messageType: 'text',
          profile: ''
        })
        if (item.nextRule) {
          this.setAutomationAction(item.cid)
        }
      } else {
        this.setAutomationChat({
          userName: 'Jack',
          message: item.label,
          messageState: 2,
          type: 'Customer',
          messageType: 'text',
          profile: ''
        })
        this.setAutomationActionDemo({ rule: this.automationRules })
      }
    },
    goCollect (item, chatData) {
      console.log(item)
    }
  }
}
</script>

<style>
  .customer-service {
    display: flex;
    flex-direction: row;
  }

  .customer-avatar {
    text-align: left;
    width: 30px;
    height: 30px;
  }

  .customer-service-content {
    margin-left: 6px;
    max-width: calc(100% - 6px - 30px);
  }

  .customer-service-name {
    font-size: 14px;
    font-weight: 400;
    color: #b4b4b4;
    line-height: 14px;
    height: 14px;
    text-align: left;
    margin-bottom: 6px;
  }

  .customer-service-message {
    max-width: 100%;
    /* 兼容性待测试 fit-content */
    width: fit-content;
    /* margin-top: 6px; */
    background: #F3F3F6;
    border-radius: 0px 8px 8px 8px;
    padding: 12px;
    font-size: 14px;
    text-align: left;
    /* 打断单词 */
    /* word-break: break-all; */
    /* line-height: 20px; */
    /* 允许用户选择聊天记录 */
    user-select: text;
  }

  .customer-service-message pre {
    margin: 0;
    white-space: pre-line;
    word-break: break-word;
    /* 句末单词超出后截断 */
    /* word-break: break-all; */
    /* 句末单词超出后完整显示在下一行 */
    /* word-wrap:break-word; */
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    /* 两端对齐 */
    /* text-align: justify; */
    text-justify: inter-ideograph;
    /* text-indent: 2rem; */
    /* overflow: hidden; */
  }

  .customer-service-attachment {
    position: relative;
    width: auto;
    background: #f0f2ff;
    border-radius: 0px 8px 8px 8px;
    padding: 12px;
    font-size: 14px;
    font-weight: 400;
    color: currentColor;
    line-height: 20px;
    text-align: left;
    cursor: pointer;
    word-break: break-all;
  }

  .customer-service-attachment span {
    text-decoration: underline currentColor;
  }

  .customer-service-img {
    max-height: 200px;
    max-width: 200px;
    min-width: 50px;
    min-height: 50px;
  }

  .customer-service-img img {
    line-height: 0;
    max-height: 200px;
    max-width: 200px;
    min-width: 50px;
    min-height: 50px;
    border-radius: 0px 12px 12px 12px;
    border: 1px solid #d3d3d3;
    /* 不兼容ie */
    object-fit: cover;
  }
  .chat-answer-content {
  }
  .chat-answer-title {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 8px;
  }
  .chat-answer-description {
    word-break: break-word;
  }
  .chat-answer {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .chat-answer-item {

  }
  .chat-collect {

  }
  .chat-input-box {
    display: flex;
    margin-top: 8px;
    width: 100%;
    margin-bottom: 8px;
  }
  .chat-message-input {
    flex: 1;
    outline: none;
    padding: 5px 12px;
    border-radius: 4px 0 0 4px;
    border: 1px solid #D9D9D9;
  }
  .chat-input-icon {
    width: 34px;
    height: 34px;
    padding: 5px;
    border-radius: 0 4px 4px 0;
  }
  .chat-options-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 16px;
  }
  .chat-option {
    border-radius: 34px;
    padding: 4px 20px;
    border: 1px solid;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin: 4px;
    max-width: 100%;
    word-break: break-word;
    cursor: pointer;
    text-align: left;
  }
  .chat-option:active {
    box-shadow: 2px 2px 8px inset rgba(#000, 0.1);
  }
</style>

<template>
  <div :class="$store.state.isMobile?'search_mobile':''">
    <!-- nav_bar -->
    <nav-bar class="nav_bar" :class="isTop?'':'not_top'">
      <template #left>
        <div class="faq-left-icon" @click="faqBackClick">
          <svg-icon svg-name="direction-left-blue" width="20px" height="20px" ></svg-icon>
        </div>
      </template>
      <template #center>
        <div class="nav_title_container">
          <!-- <div class="nav_title" v-if="!$store.state.isMobile">{{navTitle}}</div> -->
          <search-input class="search_input" @search="search" ref="faqSearch"></search-input>
        </div>
      </template>
      <template #right>
        <div class="right-faq-icon">
          <div @click.stop="handleView" v-if="!$store.state.isMobile && getFaqPageInfo.faqPage != 'articleDetail'">
            <svg-icon svg-name="放大" width="20px" height="20px" v-if="!getIsBigArticle"></svg-icon>
            <svg-icon svg-name="缩小" width="20px" height="20px" v-else></svg-icon>
          </div>
          <div v-if="!$store.state.isMobile && getFaqPageInfo.faqPage == 'articleDetail'" @click.stop="handleSkip">
            <svg-icon svg-name="skipFaq" width="20px" height="20px"></svg-icon>
          </div>
        </div>
      </template>
    </nav-bar>
    <!-- search_page -->
    <scroll-container class="search_page" :goTop="goTop" topHook @topHook="(flag)=>{ this.isTop = flag }">
      <div v-if="getAllCategoryArticle">
        <div class="no-found" v-if="getAllCategoryArticle && getAllCategoryArticle.length == 0">
          <svg-icon svg-name="failure-invite" width="247px" height="195px"></svg-icon>
          <p>No content yet.</p>
        </div>
        <component class="component" :is="currentPage" v-else></component>
      </div>
      <Loading v-else/>
    </scroll-container>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import NavBar from 'components/contents/NavBar'
import ScrollContainer from 'components/contents/ScrollContainer'
import SearchInput from './components/SearchInput'
import Loading from 'components/common/Loading/Loading'

// import { getHelpCenter } from '@/api/helpCenter'
import { postFrameMessage } from '@/utils/iframeMessage'
// import HelpCenterList from './components/HelpCenterList'

import CategoryListPage from './faqpages/CategoryListPage.vue'
import ArticleListPage from './faqpages/ArticleListPage.vue'
import articleDetail from './faqpages/articleDetail.vue'
import SearchResult from './faqpages/SearchResult'

export default {
  name: 'SearchPage',
  components: {
    NavBar,
    SearchInput,
    ScrollContainer,
    // HelpCenterList,
    SearchResult,
    articleDetail,
    ArticleListPage,
    CategoryListPage,
    Loading
  },
  data () {
    return {
      CategoryList: [],
      // 是否在顶部
      isTop: true,
      debounceResizeFunc: null,
      // 显示返回顶部组件的距离
      goTop: null,
      backPage: ''

    }
  },
  created () {
    // if (!this.getAllCategoryArticle) {
    //   this.getAllcategories()
    // }
    this.debounceResizeFunc = this.debounce(this.resizeCallback)
    window.addEventListener('resize', this.debounceResizeFunc)
    this.goTop = window.innerHeight * 2
  },
  computed: {
    ...mapGetters({
      getShopsetting: 'setting/getShopsetting',
      getFaqPageInfo: 'faq/getFaqPageInfo',
      getAllCategoryArticle: 'faq/getAllCategoryArticle',
      getIsBigArticle: 'common/getIsBigArticle',
      historyPage: 'historyPage'
    }),
    navTitle () {
      return this.getShopsetting.helpCenterTitle
    },
    currentPage () {
      return this.getFaqPageInfo.faqPage
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.debounceResizeFunc)
  },
  watch: {
    getFaqPageInfo (newVal) {
      console.log('变化', newVal)
    }
  },
  methods: {
    ...mapMutations({
      setFaqPageInfo: 'faq/setFaqPageInfo',
      setAllCategoryArticle: 'faq/setAllCategoryArticle',
      setIsBigArticle: 'common/setIsBigArticle',
      setHistory: 'setHistory',
      setBackPage:'setBackPage'
    }),
    // 请求
    // 将获取的数据进行排序处理
    compare (key) {
      return function (a, b) {
        const value1 = a[key]
        const value2 = b[key]
        return value2 - value1
      }
    },
    // 事件
    // 搜索的回调
    search (content) {
      if (this.getAllCategoryArticle && this.getAllCategoryArticle.length == 0) {
        return
      }
      if (this.getFaqPageInfo.faqPage == 'SearchResult' && this.getFaqPageInfo.keywords == content) {
        return
      }
      if (content) {
        let params
        if (this.getFaqPageInfo.faqPage != 'SearchResult') {
          params = {
            faqPage: 'SearchResult',
            keywords: content,
            prevInfo: this.getFaqPageInfo
          }
        } else {
          params = { ...this.getFaqPageInfo, keywords: content }
        }
        this.setFaqPageInfo(params)
      }
    },

    resizeCallback () {
      this.$bus.$emit('searchPageResize')
    },
    // 防抖
    debounce (callback, delay = 500) {
      let timer
      return function () {
        clearTimeout(timer)
        timer = setTimeout(() => {
          callback()
        }, delay)
      }
    },
    // getAllcategories () {
    //   getHelpCenter({ // 接口修改
    //     shopId: this.getShopsetting.shopInfo.shopId
    //   }).then(res => {
    //     if (res && res.code == 0) {
    //       const faqList = res.data.list
    //       faqList.forEach(item => {
    //         item.articleList.sort(this.compare('sort'))
    //       })
    //       this.CategoryList = faqList.sort(this.compare('categorySort'))
    //       this.setAllCategoryArticle(this.CategoryList)
    //     }
    //   })
    // },
    faqBackClick (e) {
      e.stopPropagation()
      const page = this.getFaqPageInfo.faqPage
      if (page == 'articleDetail') {
        if (this.getFaqPageInfo.from && this.getFaqPageInfo.from == 'chat') {
          this.faqBack()
        } else {
          const cateId = this.getFaqPageInfo.categoryId
          this.setFaqPageInfo({
            faqPage: 'ArticleListPage',
            categoryId: cateId
          })
        }
      } else if (page == 'ArticleListPage') {
        this.setFaqPageInfo({
          faqPage: 'CategoryListPage'
        })
      } else if (page == 'CategoryListPage') {
        this.faqBack()
        
      } else if (page == 'SearchResult') {
        const obj = this.getFaqPageInfo.prevInfo
        this.$refs.faqSearch.clearInput()
        this.setFaqPageInfo(obj)
      } else {
        this.faqBack()
      }
    },
    // 需要退出faq路由，调用back返回上一级路由
    faqBack(){
      this.setBackPage()
    },
    handleView () {
      let type = ''
      if (!this.getIsBigArticle) {
        type = 'bigViewAritle'
      } else {
        type = 'smallViewAritle'
      }
      this.setIsBigArticle(!this.getIsBigArticle)
      postFrameMessage({
        type: 'articleView',
        data: type
      }, document.referrer.substr(0, document.referrer.length))
    },
    handleSkip () {
      console.log(this.getFaqPageInfo)
      const url = this.getFaqPageInfo.faqUrl
      if (url) {
        window.open(url, '_blank')
      }
    }
  }
}
</script>

<style scoped lang="less">
  .nav_bar {
    background: #fff !important;
    color: #000;
  }

  .nav_bar /deep/ .nav-bar-right {
    /* width: unset; */
  }

  .nav_bar {
    height: 68px;
    line-height: 68px;
    transition: 0.3s box-shadow ease;
  }
  .search_page {
    text-align: left;
  }
  .loading{
    position: absolute;
    top: 30%;
    left: 50%;
  }
  .search_input {
    /* margin: 0 26px; */
    width: 100%;
  }

  .nav_title_container {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    height: 100%;
  }
  .faq-left-icon{
    width:52px;
    line-height:52px;
    line-height: 0;
    svg{
      width: 20px;
      height: 20px;
      filter: drop-shadow(52px 0 var(--primary-color));
      transform: translateX(-52px);
    }
  }
  .right-faq-icon{
    width:52px;
    line-height:52px;
    line-height: 0;
    svg{
      width: 20px;
      height: 20px;
      filter: drop-shadow(52px 0 var(--primary-color));
      transform: translateX(-52px);
    }
  }

  .nav_title {
    height: 100%;
    color: #000;
  }

  .search_page {
    height: calc(100vh - 68px);
    top: 68px;
  }

  .search_mobile .search_input {
    /* width: calc(100% - 42px); */
    margin: 0;
  }

  .search_mobile .search_page {
    height: calc(100vh - 60px);
    top: 60px;
    padding: 0;
  }

  .not_top {
    box-shadow: 0px 2px 6px -2px rgba(0, 0, 0, 0.28);
  }

  .search_mobile .firstChild /deep/ .category_title {
    margin-top: 21px;
  }
  .no-found{
    text-align: center;
    padding-top: 20%;
    svg{
      max-width: 100%;
    }
  }
</style>
